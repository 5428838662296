<template>
  <BaseModal
    :heading="$t('customers.new_loyalty_points')"
    small
    @close="$emit('close')"
  >
    <BaseInput
      v-model="description"
      :label="$t('global.description')"
      required
      focus
      mb
      v-test="'addLPDescription'"
    />
    <BaseInput
      v-model="amount"
      :label="$t('global.amount')"
      required
      type="number"
      controls
      mb
      v-test="'addLPAmount'"
    />
    <BaseAlert
      color="warning"
      :text="$t('customers.new_loyalty_points_points')"
    />
    <template #footer>
      <BaseButton @click="submit" v-test="'addLPSave'">{{
        $t('global.actions.save')
      }}</BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import useVuelidate from '@vuelidate/core';

import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['submit', 'close'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      customer_id: null,
      description: '',
      amount: 0
    };
  },
  watch: {
    '$route.params.customerId': {
      handler() {
        this.customer_id = Number.parseInt(this.$route.params.customerId);
      },
      immediate: true
    }
  },
  methods: {
    submit() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      const obj = {
        description: this.description,
        amount: this.amount,
        transaction_at: dayjs().format(),
        customer_id: this.customer_id
      };

      this.$emit('submit', obj);
      this.$emit('close');
    }
  }
});
</script>
