import apolloClient from '@/apollo/client';
import dayjs from '@/dayjs';
import calendarInterface from '@/modules/calendar/calendar-interface';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import { useLocationsStore } from '@/stores/locations';
import type { QueryBusinessHoursArgs } from '@/types';
import gql from 'graphql-tag';
import { ref } from 'vue';
import { getDateRangeWeek } from '../helpers';

export const cachedBusinessHours = ref<any[]>([]);

const generateQueryVars = (): QueryBusinessHoursArgs => {
  const { resourceId, resourceType, viewType, date } =
    useCalendarFiltersStore();
  let start;
  let end;

  if (viewType === 'WEEK') {
    const range = getDateRangeWeek(date);
    start = range.start;
    end = range.end;
  } else if (viewType === 'DAY') {
    start = date;
    end = dayjs(date).add(1, 'day').format('YYYY-MM-DD');
  }

  const { locationId, dataScope } = useLocationsStore();

  return {
    locationId: locationId?.toString(),
    start,
    end,
    viewType: resourceType,
    dataScope,
    resourceId: resourceId || null
  };
};

export const fetchBusinessHours = (variables?: QueryBusinessHoursArgs) => {
  if (!variables) {
    variables = generateQueryVars();
  }

  return new Promise<void>((resolve) => {
    apolloClient
      .query({
        query: gql`
          query getBusinessHours(
            $end: DateTime!
            $locationId: ID
            $resourceId: Int
            $start: DateTime!
            $viewType: String
            $dataScope: DataScope
          ) {
            businessHours(
              end: $end
              locationId: $locationId
              resourceId: $resourceId
              start: $start
              viewType: $viewType
              dataScope: $dataScope
            ) {
              daysOfWeek
              endTime
              resource
              startTime
            }
          }
        `,
        variables
      })
      .then(({ data: { businessHours } }) => {
        cachedBusinessHours.value = businessHours;

        if (!businessHours.length) {
          calendarInterface.api?.setOption('businessHours', {
            daysOfWeek: []
          });
        } else {
          calendarInterface.api?.setOption('businessHours', businessHours);
        }
        resolve();
      });
  });
};
