import { useCompanyStore } from '@/stores/company';
import unleash from '@/unleash';
import { useMutation } from '@vue/apollo-composable';
import { storeToRefs } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import CancelSubscription from './CancelSubscription.vue';
import OptionsList from './OptionsList.vue';
import ScheduleDemo from './ScheduleDemo.vue';
import {
  CANCEL_SUBSCRIPTION_MUTATION,
  PAUSE_SUBSCRIPTION_MUTATION,
  UPDATE_SUBSCRIPTION_CANCEL_REASONS_MUTATION
} from './graphql';

export type Action =
  | 'back'
  | 'next'
  | 'schedule_call'
  | 'cancel_subscription'
  | 'confirm_cancel_subscription'
  | 'pause_subscription';
export type ActionButton = {
  label: string;
  value: Action;
};

export const useCancelSubscription = () => {
  const { company } = storeToRefs(useCompanyStore());

  const formKeys = [
    'main_reason',
    'doesnt_work_for_me',
    'dont_need_salonized',
    'i_have_life_business_change',
    'its_too_expensive'
  ] as const;

  const stepKeys = [
    ...formKeys,
    'temporary_pause',
    'we_dont_want_to_see_you_go',
    'schedule_a_demo',
    'password'
  ] as const;

  type FormFields = (typeof formKeys)[number];
  type StepKey = (typeof stepKeys)[number];
  type Steps = Record<StepKey, StepData>;

  type StepData = {
    key?: FormFields;
    next?: StepKey;
    component: any;
    heading: string;
    label?: string;
    description?: string;
    img?: string;
    options?: {
      label: string;
      value: string;
    }[];
    actions?: {
      primary: ActionButton;
      secondary?: ActionButton;
      tertiary?: ActionButton;
    };
  };

  type CancelSubscriptionState = {
    otherSolution: string;
    openFeedback: string;
    password: string;
  };

  const { t } = useI18n();

  const steps: ComputedRef<Steps> = computed(() => ({
    main_reason: {
      key: 'main_reason',
      component: OptionsList,
      heading: t(
        'subscription.cancelation_flow.form_heading.we_don_want_to_see_you_go'
      ),
      label: t('subscription.cancelation_flow.form_label.main_reason'),
      img: '/img/cancel-subscription/person-asking-why.svg',
      options: [
        {
          label: t('subscription.cancelation_flow.options.dont_need_salonized'),
          value: 'dont_need_salonized'
        },
        {
          label: t('subscription.cancelation_flow.options.doesnt_work_for_me'),
          value: 'doesnt_work_for_me'
        },
        {
          label: t(
            'subscription.cancelation_flow.options.i_have_life_business_change'
          ),
          value: 'i_have_life_business_change'
        },
        {
          label: t('subscription.cancelation_flow.options.its_too_expensive'),
          value: 'its_too_expensive'
        }
      ],
      actions: {
        primary: {
          value: 'next',
          label: t('global.actions.next')
        }
      }
    },
    dont_need_salonized: {
      key: 'dont_need_salonized',
      next: 'we_dont_want_to_see_you_go',
      component: OptionsList,
      heading: t(
        'subscription.cancelation_flow.form_heading.we_don_want_to_see_you_go'
      ),
      label: t('subscription.cancelation_flow.form_label.dont_need_salonized'),
      img: '/img/cancel-subscription/person-asking-why.svg',
      options: [
        {
          label: t(
            'subscription.cancelation_flow.options.business_not_big_enough'
          ),
          value: 'business_not_big_enough'
        },
        {
          label: t('subscription.cancelation_flow.options.just_looking_around'),
          value: 'just_looking_around'
        },
        {
          label: t(
            'subscription.cancelation_flow.options.subscribed_by_mistake'
          ),
          value: 'subscribed_by_mistake'
        }
      ],
      actions: {
        primary: {
          value: 'next',
          label: t('global.actions.next')
        },
        secondary: {
          value: 'back',
          label: t('global.back')
        }
      }
    },
    doesnt_work_for_me: {
      key: 'doesnt_work_for_me',
      next: 'we_dont_want_to_see_you_go',
      component: OptionsList,
      heading: t(
        'subscription.cancelation_flow.form_heading.we_don_want_to_see_you_go'
      ),
      label: t('subscription.cancelation_flow.form_label.doesnt_work_for_me'),
      img: '/img/cancel-subscription/person-asking-why.svg',
      options: [
        {
          label: t(
            'subscription.cancelation_flow.options.missing_important_features'
          ),
          value: 'missing_important_features'
        },
        {
          label: t(
            'subscription.cancelation_flow.options.customers_dont_get_it'
          ),
          value: 'customers_dont_get_it'
        },
        {
          label: t(
            'subscription.cancelation_flow.options.setup_is_too_complicated'
          ),
          value: 'setup_is_too_complicated'
        },
        {
          label: t(
            'subscription.cancelation_flow.options.bugs_and_technical_issues'
          ),
          value: 'bugs_and_technical_issues'
        }
      ],
      actions: {
        primary: {
          value: 'next',
          label: t('global.actions.next')
        },
        secondary: {
          value: 'back',
          label: t('global.back')
        }
      }
    },
    i_have_life_business_change: {
      key: 'i_have_life_business_change',
      next: 'we_dont_want_to_see_you_go',
      component: OptionsList,
      heading: t(
        'subscription.cancelation_flow.form_heading.we_don_want_to_see_you_go'
      ),
      label: t(
        'subscription.cancelation_flow.form_label.i_have_life_business_change'
      ),
      img: '/img/cancel-subscription/person-asking-why.svg',
      options: [
        {
          label: t('subscription.cancelation_flow.options.its_permanent'),
          value: 'its_permanent'
        },
        {
          label: t('subscription.cancelation_flow.options.its_temporary'),
          value: 'its_temporary'
        }
      ],
      actions: {
        primary: {
          value: 'next',
          label: t('global.actions.next')
        },
        secondary: {
          value: 'back',
          label: t('global.back')
        }
      }
    },
    its_too_expensive: {
      key: 'its_too_expensive',
      next: 'we_dont_want_to_see_you_go',
      component: OptionsList,
      heading: t(
        'subscription.cancelation_flow.form_heading.we_don_want_to_see_you_go'
      ),
      label: t('subscription.cancelation_flow.form_label.its_too_expensive'),
      img: '/img/cancel-subscription/person-asking-why.svg',
      options: [
        {
          label: t('subscription.cancelation_flow.options.pay_too_much'),
          value: 'pay_too_much'
        },
        {
          label: t(
            'subscription.cancelation_flow.options.needs_to_lower_business_costs'
          ),
          value: 'needs_to_lower_business_costs'
        }
      ],
      actions: {
        primary: {
          value: 'next',
          label: t('global.actions.next')
        },
        secondary: {
          value: 'back',
          label: t('global.back')
        }
      }
    },
    temporary_pause: {
      key: 'i_have_life_business_change',
      component: OptionsList,
      next: 'we_dont_want_to_see_you_go',
      heading: t('subscription.cancelation_flow.form_heading.temporary_pause'),
      label: t('subscription.cancelation_flow.form_label.temporary_pause'),
      description: t(
        'subscription.cancelation_flow.description.temporary_pause'
      ),
      img: '/img/cancel-subscription/person-sitting.svg',
      options: [
        {
          label: t('subscription.cancelation_flow.options.temporary_pause', {
            amount: 3
          }),
          value: '3'
        },
        {
          label: t('subscription.cancelation_flow.options.temporary_pause', {
            amount: 4
          }),
          value: '4'
        },
        {
          label: t('subscription.cancelation_flow.options.temporary_pause', {
            amount: 5
          }),
          value: '5'
        }
      ],
      actions: {
        primary: {
          value: 'pause_subscription',
          label: t('subscription.cancelation_flow.actions.pause')
        },
        secondary: {
          value: 'back',
          label: t('global.back')
        },
        tertiary: {
          value: 'next',
          label: t('subscription.cancelation_flow.actions.cancel_instead')
        }
      }
    },
    we_dont_want_to_see_you_go: {
      component: OptionsList,
      heading: t(
        'subscription.cancelation_flow.form_heading.we_don_want_to_see_you_go'
      ),
      label: t(
        'subscription.cancelation_flow.form_label.we_dont_want_to_see_you_go'
      ),
      img: '/img/cancel-subscription/training-employee.svg',
      actions: {
        primary: {
          value: 'schedule_call',
          label: t('subscription.cancelation_flow.form_heading.schedule_a_demo')
        },
        secondary: {
          value: 'cancel_subscription',
          label: t('global.actions.cancel_my_subscription')
        }
      }
    },
    schedule_a_demo: {
      component: ScheduleDemo,
      heading: t('subscription.cancelation_flow.form_heading.schedule_a_demo'),
      actions: {
        primary: {
          value: 'back',
          label: t('global.back')
        }
      }
    },
    password: {
      component: CancelSubscription,
      heading: t(
        'subscription.cancelation_flow.form_heading.cancel_subscription'
      ),
      label: t('global.password'),
      actions: {
        primary: {
          value: 'confirm_cancel_subscription',
          label: t('global.actions.cancel_my_subscription')
        },
        secondary: {
          value: 'back',
          label: t('global.back')
        }
      }
    }
  }));

  const formFlowData = reactive<Record<FormFields, string>>({
    main_reason: '',
    doesnt_work_for_me: '',
    i_have_life_business_change: '',
    dont_need_salonized: '',
    its_too_expensive: ''
  });

  const cancelationConfirmationData = reactive<CancelSubscriptionState>({
    otherSolution: '',
    openFeedback: '',
    password: ''
  });

  const navTracker = ref<StepKey[]>([]);
  const currentStep = ref<StepKey>('main_reason');
  const currentStepData = computed<StepData>(
    () => steps.value[currentStep.value]
  );

  const moveToPrevStep = () => {
    if (navTracker.value.length > 0) {
      if (currentStepData.value.key) {
        formFlowData[currentStepData.value.key] = '';
      }
      currentStep.value = navTracker.value[navTracker.value.length - 1];
      navTracker.value = navTracker.value.slice(0, -1);
    }
  };

  const moveToNextStep = () => {
    if (currentStepData.value.next) {
      if (
        currentStepData.value.key &&
        formFlowData[currentStepData.value.key] === ''
      ) {
        return;
      }

      moveToStep(currentStepData.value.next);
      return;
    }

    const lastSetValue = formFlowData[currentStep.value as FormFields];

    if (
      lastSetValue === 'i_have_life_business_change' &&
      unleash.isEnabled('PauseSubscription') &&
      company.value.activeSubscription?.billingPeriod === 'monthly'
    ) {
      moveToStep('temporary_pause');
      return;
    }

    if ((stepKeys as ReadonlyArray<string>).includes(lastSetValue)) {
      moveToStep(lastSetValue as StepKey);
      return;
    }
  };

  const moveToStep = (step: StepKey) => {
    navTracker.value = [...navTracker.value, currentStep.value];
    currentStep.value = step;
  };

  const updateSubscriptionCancelReasons = async () => {
    const { mutate } = useMutation(
      UPDATE_SUBSCRIPTION_CANCEL_REASONS_MUTATION,
      {
        variables: {
          input: {
            cancelReasons: formFlowData
          }
        }
      }
    );

    return mutate();
  };

  const cancelSubscription = async () => {
    const { mutate } = useMutation(CANCEL_SUBSCRIPTION_MUTATION, {
      variables: {
        input: {
          explanation: cancelationConfirmationData.openFeedback,
          otherSoftware: cancelationConfirmationData.otherSolution,
          password: cancelationConfirmationData.password
        }
      }
    });

    return mutate();
  };

  const pauseSubscription = async () => {
    const { mutate } = useMutation(PAUSE_SUBSCRIPTION_MUTATION, {
      variables: {
        input: {
          duration: Number.parseInt(formFlowData.i_have_life_business_change)
        }
      }
    });

    return mutate();
  };

  return {
    currentStep,
    currentStepData,
    formFlowData,
    cancelationConfirmationData,
    moveToPrevStep,
    moveToNextStep,
    moveToStep,
    updateSubscriptionCancelReasons,
    cancelSubscription,
    pauseSubscription
  };
};
