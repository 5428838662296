import { defineStore } from 'pinia';
import { computed, nextTick, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { useCreateEventStore } from '@/stores/calendar-create-event';
import { usePreCreateStore } from '@/stores/calendar-pre-create';
import { useCompanyStore } from '@/stores/company';
import { useLocationsStore } from '@/stores/locations';
import { useResourcesStore } from '@/stores/resources';
import { useServicesStore } from '@/stores/services';

import { uniqueArray } from '@/helpers/formatting';

import {
  canCheckAvailability as _canCheckAvailability,
  useAvailabilities
} from './availabilities';
import { useCreateEventSync } from './create-event-sync';
import { useCustomerData } from './customer';
import { useExistingAppointment } from './existing-appointment';
import { useRequirements } from './requirements';
import type { Requirement } from './requirements';
import { useServiceAvailability } from './service-availability';

import type {
  Allocation,
  AppointmentPartAttributes,
  Resource,
  RruleAttributes,
  Service
} from '@/types';

const createAppointmentPart = ({
  resourceId,
  serviceId
}: {
  resourceId?: Allocation['resourceId'] | null;
  serviceId?: AppointmentPartAttributes['serviceId'] | null;
} = {}): AppointmentPartAttributes => {
  // If a serviceId is provided, get the service from the store and add the correct durations to the part
  // Currently it is only possible to set a service by default when creating an appointment from the waiting list

  const { serviceById } = useServicesStore();
  const service = serviceId ? serviceById(serviceId) : null;
  const { duration } = useCreateEventStore();

  // Only set a default resource if the provided id belongs to an employee
  const { resourceById } = useResourcesStore();
  const resource = resourceId ? resourceById(resourceId) : null;
  if (resource?.type !== 'employee') {
    resourceId = null;
  }

  // When no resourceId is passed, and the company only has one employee, set that employee as the resourceId
  const { hasSingleEmployee } = useResourcesStore();
  if (!resourceId && hasSingleEmployee) {
    const { employees } = useResourcesStore();
    resourceId = employees[0]?.id || null;
  }

  return {
    allocationsAttributes: [
      {
        requirementId: null,
        resourceId: resourceId || null
      }
    ],
    duration: service?.duration || duration || 0,
    durationFinish: service?.requiresProcessingTime
      ? service.durationFinish
      : 0,
    durationProcessing: service?.requiresProcessingTime
      ? service.durationProcessing
      : 0,
    durationSetup: service?.requiresProcessingTime ? service.durationSetup : 0,
    serviceId: serviceId || null
  };
};

// A mixture of the CreateAppointmentInput and UpdateAppointmentInput used in the schema.
// But since we are using the same data for both queries, we have to create a new type.

export type FormData = {
  customerId: number | null;
  id?: number;
  locationId: number | null;
  notes: string;
  partsAttributes: AppointmentPartAttributes[];
  rrule: RruleAttributes | null;
  startAt: string;
};

export const useCreateAppointmentStore = defineStore(
  'calendar/createAppointment',
  () => {
    const sendConfirmationEmail = ref(false);
    const isFormSubmitted = ref(false);

    // The data that will be submitted to the backend when saving the appointment.
    // It will be used for both the createAppointment and updateAppointment mutations.

    const formData: FormData = reactive({
      customerId: null,
      locationId: null,
      notes: '',
      partsAttributes: [],
      rrule: null,
      startAt: ''
    });

    useCreateEventSync(formData);

    // Add a part to formData.partsAttributes
    // When adding a new part, if the previous part has a resourceId on the first alocation,
    // we need to set that resource to the first allocation of the new part

    const addPart = () => {
      const partAmount = formData.partsAttributes.length;
      const previousPart = partAmount
        ? formData.partsAttributes[partAmount - 1]
        : null;

      let resourceId = null;

      if (previousPart?.allocationsAttributes?.length) {
        const allocation = previousPart.allocationsAttributes[0];
        if (allocation?.resourceId) {
          resourceId = allocation.resourceId;
        }
      }

      formData.partsAttributes.push(createAppointmentPart({ resourceId }));
    };

    const resetParts = () => {
      const { resourceId } = useCreateEventStore();
      formData.partsAttributes = [createAppointmentPart({ resourceId })];
    };

    // The availability query should only be called when all parts have a serviceId
    // Exposing this as a computed property because it should be usable outside of the store

    const canCheckAvailability = computed<boolean>(() =>
      _canCheckAvailability(formData)
    );

    const {
      partAvailabilities,
      isAppointmentAvailable,
      isFetchingAvailabilities,
      fetchAvailabilities,
      resetAvailabilityData
    } = useAvailabilities();

    // When certain formData attributes change, we need to refetch the availabilities.
    // Because we are watching multiple values, multiple watchers can trigger at the same time.
    // Therefor we added a debounce to make sure the fetch only happens once, as well as prevent fetching as the user is typing the duration.

    const checkAvailability = async (debounce = 0) => {
      // This method will also be called when the user submits the form, in which case we don't want to add a debounce.

      await fetchAvailabilities(formData, debounce);
      return isAppointmentAvailable.value;
    };

    watch(
      () => formData.startAt,
      (c, p) => onAvailabilityDataChange(!!p)
    );
    watch(
      () => formData.locationId,
      (c, p) => onAvailabilityDataChange(!!p)
    );
    watch(
      () => formData.partsAttributes,
      (c, p) => {
        if (formData.partsAttributes.length > 1) {
          // When there are multiple parts, and only one part has a resourceId on the first allocation,
          // we need to set that resourceId to the first allocation of the other parts

          const onlyPartWithResource = formData.partsAttributes.find(
            (part) => !!part.allocationsAttributes?.[0]?.resourceId
          );
          if (onlyPartWithResource) {
            const resourceId =
              onlyPartWithResource.allocationsAttributes?.[0].resourceId;
            if (resourceId) {
              formData.partsAttributes.forEach((part) => {
                const firstAllocation = part.allocationsAttributes?.[0];
                if (firstAllocation && !firstAllocation.resourceId) {
                  firstAllocation.resourceId = resourceId;
                }
              });
            }
          }
        }

        onAvailabilityDataChange(!!p.length);
      },
      { deep: true }
    );

    // When changing data which impacts availability, we need to make sure to check the availability again before saving.
    // When creating a new appointment, this will always be the case, but when editing an appointment the user might only change the customer or note.
    // In that case we should not fetch the availabilities, as to not show the "not available" modal when saving. Will only come up in case of double bookings.
    const availabilityDataChanged = ref(false);

    const onAvailabilityDataChange = (hasPreviousValue: boolean) => {
      const { isFetchingAppointment } = useExistingAppointment();
      if (isFetchingAppointment.value) {
        return;
      }

      // Only set this when the value was already set, so not when setting it for the first time.
      if (!availabilityDataChanged.value && hasPreviousValue) {
        availabilityDataChanged.value = true;
      }

      nextTick(() => {
        if (!canCheckAvailability.value) {
          // The watcher has triggered, but the parts are not valid, so we should not fetch the availabilities.
          return;
        }

        checkAvailability(500);
      });
    };

    // When editing an appointment, we need to fetch the appointment and map the data to the store.

    const {
      isFetchingAppointment,
      existingAppointmentData,
      loadExistingAppointment,
      resetExistingAppointmentData
    } = useExistingAppointment();

    // Because we are using the composition api, we cannot use Pinia's $reset method like you can with the options api.
    // So we need to make sure to reset certain things when running setDefaultState.
    // setDefaultState will be called from the component.
    // It will always be called, not just when editing an existing component but also when creating a new one.
    // When creating a new appointment, some defaults from the preCreateStore will be set.

    const resetFormData = () => {
      // Get default values from other stores, and reset other properties which don't have defaults.

      const { locationId } = useLocationsStore();
      const { dateTimeFrom, resourceId: _resourceId } = useCreateEventStore();
      let resourceId = _resourceId;

      const { customer, serviceIds } = usePreCreateStore();

      formData.customerId = customer?.id || null;
      formData.locationId = locationId;
      formData.startAt = dateTimeFrom;
      formData.notes = '';
      formData.rrule = null;

      if (formData.id) {
        formData.id = undefined;
      }

      if (resourceId) {
        // If the resource doesn't work at the selected location, reset the resource
        // This can happen when selecting a timeslot in dayview when the calendar is on "global"

        const { resourceById } = useResourcesStore();
        const resource = resourceById(resourceId);
        if (
          resource?.locationIds &&
          !resource.locationIds.includes(locationId)
        ) {
          resourceId = null;
        }
      }

      if (serviceIds?.length) {
        // When creating a new appointment via a waiting list entry, the waiting list services will be added to the precreate store.
        // They are turned into parts here.

        formData.partsAttributes = serviceIds.map((serviceId) =>
          createAppointmentPart({ resourceId, serviceId })
        );
      } else {
        formData.partsAttributes = [createAppointmentPart({ resourceId })];
      }
    };

    const setDefaultState = () => {
      // Reset state

      isFormSubmitted.value = false;
      availabilityDataChanged.value = false;
      resetExistingAppointmentData();
      resetAvailabilityData();

      // Check if the route has an appointmentId param, and based on that either fetch the appointment, or set a default state.

      const route = useRoute();
      const appointmentId =
        typeof route.params.appointmentId === 'string'
          ? Number.parseInt(route.params.appointmentId)
          : null;

      if (appointmentId) {
        loadExistingAppointment({ id: appointmentId, formData });
      } else {
        resetFormData();
      }
    };

    const {
      requirements,
      isFetchingRequirements,
      clearRequirements,
      loadAllocations
    } = useRequirements();

    const allRequirements = computed<Requirement[]>(() => {
      // When the user selects a service, we fetch the requirements for that service, and store that in the requirements array.
      // But when editing an existing appointment, we get the requirements for the services in the same query that fetches the appointment.
      // So we combine both arrays here.

      if (existingAppointmentData.requirements?.length) {
        return [...existingAppointmentData.requirements, ...requirements.value];
      } else {
        return requirements.value;
      }
    });

    const reloadAllAllocations = () => {
      // Clear all the requirements, which will also clear the Apollo cache
      // After that, load the allocations for every part, based on the newly fetched requirements

      clearRequirements();
      formData.partsAttributes.forEach((part) => {
        loadAllocations(part);
      });
    };

    const requirementById = (id: number) =>
      allRequirements.value.find((req) => req.id === id);

    const services = computed<Service[]>(() => {
      // When editing an appointment which contains a service that is deleted, the service data won't be available in the services store.
      // So when fetching the appointment, we store the deleted services, and add them to the services array here.

      const { services } = useServicesStore();
      if (existingAppointmentData.deletedServices?.length) {
        return uniqueArray([
          ...existingAppointmentData.deletedServices,
          ...services
        ]);
      } else {
        return services;
      }
    });

    const resources = computed<Resource[]>(() => {
      // The user should only be able to selected resources that are working at the selected location, based on formData.locationId

      const { resources } = useResourcesStore();
      const { multiLocation } = useCompanyStore();
      return multiLocation
        ? resources.filter(
            (resource) =>
              !resource.locationIds ||
              !formData.locationId ||
              resource.locationIds.includes(formData.locationId)
          )
        : resources;
    });

    watch(
      () => formData.locationId,
      (newValue, oldValue) => {
        // When the user selects another location, we need to check if the selected resources for every allocation are working at that location
        // If not, we need to reset the resourceId of the allocation

        if (!oldValue) {
          return;
        }

        formData.partsAttributes.forEach((part) => {
          part.allocationsAttributes?.forEach((allocation) => {
            if (
              allocation.resourceId &&
              !resources.value.find(
                (resource) => resource.id === allocation.resourceId
              )
            ) {
              allocation.resourceId = null;
            }
          });
        });
      }
    );

    const { customerData, setCustomerData } = useCustomerData(formData);

    const showNotificationsToggle = computed(() => {
      const { companySettings } = useCompanyStore();
      return (
        !!formData.customerId &&
        !!customerData?.email &&
        !formData.id &&
        !!companySettings.communication?.appointmentConfirmation
      );
    });

    watch(showNotificationsToggle, (value) => {
      // When the toggle becomes visible, automatically set the value to "true", and to "false" if the toggle gets hidden again
      sendConfirmationEmail.value = value;
    });

    const customerMember = computed(
      () => existingAppointmentData.customerMember
    );

    watch(
      () => existingAppointmentData.customer,
      (customer) => {
        setCustomerData(customer);
      }
    );

    const { unavailableServices } = useServiceAvailability(formData);

    return {
      formData,
      customerData,
      customerMember,
      services,
      canCheckAvailability,
      availabilityDataChanged,
      partAvailabilities,
      isFetchingAvailabilities,
      isFetchingAppointment,
      showNotificationsToggle,
      sendConfirmationEmail,
      isFormSubmitted,
      existingAppointmentData,
      isFetchingRequirements,
      resources,
      allRequirements,
      unavailableServices,
      setCustomerData,
      clearRequirements,
      requirementById,
      addPart,
      resetParts,
      checkAvailability,
      loadAllocations,
      reloadAllAllocations,
      setDefaultState,
      resetExistingAppointmentData,
      resetFormData
    };
  }
);
