import calendarInterface from '@/modules/calendar/calendar-interface';
import { Calendar } from '@fullcalendar/core';
import { ref } from 'vue';
import { calendarOptions as calendarContentOptions } from './calendar-content';
import { useCalendarDefaults } from './calendar-defaults';
import { calendarOptions as calendarEventsOptions } from './calendar-events';
import { getCalendarInteractionOptions } from './calendar-interaction';
import { calendarOptions as calendarResourcesOptions } from './calendar-resources';

export const useCalendarSetup = () => {
  const defaults = useCalendarDefaults();

  type MountedCallback = () => any;
  let mountedCallback: MountedCallback | null = null;

  const isLoading = ref(false);

  const options = {
    ...defaults,
    ...calendarContentOptions,
    ...getCalendarInteractionOptions(),
    ...calendarEventsOptions,
    ...calendarResourcesOptions,
    loading(loading: boolean) {
      isLoading.value = loading;
    },
    viewDidMount: () => {
      if (mountedCallback) {
        mountedCallback();
      }
    }
  };

  const renderCalendar = (el: HTMLElement) => {
    const calendar = new Calendar(el, options as any);
    calendarInterface.setApi(calendar);
    calendar.render();
  };

  return {
    renderCalendar,
    onCalendarMounted: (c: MountedCallback) => (mountedCallback = c),
    isLoading
  };
};
