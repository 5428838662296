<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
    @mouseover="active = true"
    @mouseleave="active = false"
  >
    <div :class="$style.content">
      <div v-if="icon" :class="$style.icons">
        <div
          v-if="canDelete && active && hasFeatureFlag('timeline-items-delete')"
          :class="$style.deleteIcon"
          @click="onDelete"
        >
          <BaseIcon
            name="delete"
            size="s"
            color="error"
            v-test="'timeline-item-delete'"
          />
        </div>
        <BaseIcon v-else :name="icon" size="s" :mr="0.5" />
      </div>
      <div :class="$style.description" v-html="description" />
    </div>
    <div :class="$style.date">
      <BaseText>{{
        filters.dateTime(item.created_at || item.createdAt)
      }}</BaseText>
      <BaseIcon
        :disabled="!item.user"
        :tooltip="tooltipUser"
        name="people"
        size="s"
        :ml="0.5"
      />
    </div>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { useUserStore } from '@/stores/user';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true
    },
    canDelete: {
      type: Boolean,
      default: false
    }
  },
  emits: ['delete'],
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      active: false,
      noMetaWhiteList: [
        'appointment_created',
        'appointment_activated',
        'appointment_created_online',
        'appointment_removed',
        'ipl_treatment_created',
        'ipl_treatment_updated',
        'ipl_treatment_removed'
      ]
    };
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    tooltipUser() {
      return this.item.user
        ? `${this.item.user.fullName || this.item.user.name}${this.item.user.email ? `, ${this.item.user.email}` : ''}`
        : null;
    },
    isEmail() {
      return this.item.meta.gateway === 'email';
    },
    isSms() {
      return this.item.meta.gateway === 'sms';
    },
    hasMeta() {
      return !Object.keys(this.item.meta).length;
    },
    key() {
      return this.item.key ? this.item.key : null;
    },
    latestChanges() {
      const changes = {};
      let change;

      Object.keys(this.item.meta).forEach((key) => {
        change = this.item.meta[key];
        const saveInfo = key === 'number' || key === 'start_at';
        change[1] === null && saveInfo
          ? (changes[key] = change[0])
          : (changes[key] = change[1]);
      });

      if (
        this.item.meta.start_at &&
        typeof this.item.meta.start_at === 'string'
      ) {
        changes.date = this.filters.date(this.item.meta.start_at);
        changes.time = this.filters.time(this.item.meta.start_at);
      } else if (changes.start_at) {
        changes.date = this.filters.date(changes.start_at);
        changes.time = this.filters.time(changes.start_at);
      }

      if (this.item.customer?.firstName) {
        changes.customer = this.item.customer.firstName;
      }

      return changes;
    },
    descriptionData() {
      let data;

      if (this.item.changes?.length) {
        data =
          this.key === 'appointment_updated'
            ? this.item.changes.map((change) =>
                this.$t(`timeline_items.${change}`)
              )
            : this.item.changes;
        data = { changes: `(${data.join(', ')})` };
      } else {
        if (this.item.meta.message_id) {
          data = this.item.meta;
        } else {
          data = this.latestChanges;
        }
      }

      return data;
    },
    description() {
      let key = this.key;

      if (
        Object.keys(this.item.meta).length === 0 &&
        this.noMetaWhiteList.includes(this.key)
      ) {
        key = `${this.key}_no_meta`;
      }

      if (this.descriptionData.customer) {
        switch (key) {
          case 'appointment_created_online':
            key = 'appointment_created_customer';
            break;
          case 'appointment_cancelled_online':
            key = 'appointment_cancelled_customer';
            break;
          case 'appointment_moved_online':
            key = 'appointment_moved_customer';
            break;
          case 'feedback_created':
            key = 'feedback_created_customer';
            break;
          case 'form_submission_created_online':
            key = 'form_submission_created_online_customer';
            break;
        }
      }

      return this.$t(`timeline_items.log.${key}`, this.descriptionData);
    },
    icon() {
      if (this.isEmail) {
        return 'email';
      } else if (this.isSms) {
        return 'phone';
      } else {
        switch (this.key) {
          case 'customer_merged':
            return 'swap';
          case 'customer_created':
          case 'customer_imported':
          case 'customer_created_online':
          case 'customer_updated':
          case 'customer_removed':
          case 'customer_updated_online':
          case 'customer_blocked':
          case 'customer_unblocked':
            return 'person';
          case 'order_created':
          case 'order_updated':
          case 'order_removed':
            return 'shopping-cart';
          case 'appointment_created_online':
          case 'appointment_cancelled_online':
          case 'appointment_moved_online':
          case 'appointment_with_last_minute_discount':
          case 'appointment_with_dynamic_price':
          case 'appointment_rebook_reminder':
            return 'globe';
          case 'appointment_created':
          case 'appointment_activated':
          case 'appointment_updated':
          case 'appointment_moved':
          case 'appointment_cancelled':
          case 'appointment_removed':
          case 'appointment_reminder':
          case 'waiting_list_entry_created':
          case 'waiting_list_entry_deleted':
          case 'waiting_list_entry_email_sent':
          case 'waiting_list_entry_deleted_by_appointment':
            return 'calendar';
          case 'form_submission_created_online':
          case 'form_submission_created':
          case 'form_submission_updated':
          case 'form_submission_removed':
          case 'form_submission_request':
            return 'file';
          case 'appointment_label_changed':
          case 'appointment_label_removed':
            return 'pricetag';
          case 'appointment_no_show_enabled':
          case 'appointment_no_show_disabled':
            return 'delete';
          case 'appointment_duplicated':
            return 'copy';
          case 'ipl_treatment_created':
          case 'ipl_treatment_updated':
          case 'ipl_treatment_removed':
          case 'ipl_treatment_report_created':
          case 'ipl_treatment_report_updated':
          case 'ipl_treatment_report_removed':
            return 'flash';
          case 'appointment_report_created':
          case 'appointment_report_updated':
          case 'appointment_report_removed':
            return 'clipboard';
          case 'custom':
          case 'birthday_message':
            return 'email';
          case 'feedback_created':
          case 'feedback_publish':
          case 'feedback_response':
          case 'feedback_request':
          case 'feedback_publication_allowed':
          case 'feedback_publication_denied':
            return 'message';
          case 'note_created':
          case 'note_updated':
          case 'note_removed':
          case 'intake_updated':
          case 'intake_updated_no_meta':
            return 'edit';
          case 'order_invoice':
          case 'appointment_confirmation':
          case 'recurring_appointment_confirmation':
            return 'email';
          case 'appointment_confirmed':
          case 'appointment_rejected':
          case 'appointment_pending_confirmation_message':
            return 'checkmark-circle';
          case 'assign_customer_tags':
            return 'pricetag';
          case 'appointment_pending_banktransfer':
            return 'clock';
        }
      }
      return null;
    }
  },
  methods: {
    onDelete() {
      this.$emit('delete', this.item.id);
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 12px;
  gap: $spacing * 0.5;

  &:not(:last-child) {
    margin-bottom: $spacing * 0.25;
  }

  &.smallScreen {
    flex-wrap: wrap;
  }
}

.date {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.icons {
  transform: translateY(3px);
}

.content {
  position: relative;
  display: flex;
  align-items: baseline;
  line-height: 18px;
  width: 100%;

  .base:not(.smallScreen) & {
    max-width: 73%;
  }
}

.isDisabled {
  pointer-events: none;
}

.deleteIcon {
  cursor: pointer;
  margin-right: $spacing * 0.5;
}
</style>
