import i18n from '@/i18n';
import { useCompanyStore } from '@/stores/company';
import hexRgb from 'hex-rgb';

export const lightColor = (color: string): string => {
  if (!/^#(?:[0-9a-f]{3}){1,2}$/i.test(color)) {
    return '';
  }

  const { companySettings } = useCompanyStore();
  const darkerColors = companySettings.agenda?.darkerColors;
  const c = hexRgb(color, { format: 'array' });
  return `rgba(${c[0]},${c[1]},${c[2]},0.${darkerColors ? 5 : 3})`;
};

export const underscore = (value: string): string =>
  value.replace(
    /[A-Z]/g,
    (char, index) => (index !== 0 ? '_' : '') + char.toLowerCase()
  );

export function underscoreObject(value: any) {
  const newObject: any = {};
  Object.keys(value).forEach((key) => {
    if (Array.isArray(value[key])) {
      newObject[underscore(key)] = value[key].map(underscoreObject);
    } else {
      newObject[underscore(key)] = value[key];
    }
  });
  return newObject;
}

export const uniqueArray = (arr: any[], key = 'id') => [
  ...new Map(arr.map((item) => [item[key], item])).values()
];

export const uniqueDiscountCodes = (appointments: any[]) =>
  uniqueArray(
    appointments
      .map(
        (appointment) => appointment.discount_code || appointment.discountCode
      )
      .filter((discount) => discount?.code)
  ).map((discount) => ({
    code: discount.code,
    discountPercentage:
      discount.discount_percentage || discount.discountPercentage
  }));

export const groupBy = (data: any[], key: string) => {
  const groupedData: any[] = [];
  data.forEach((item) => {
    const group = groupedData.find((g) => g.name === item[key]);
    if (group) {
      group.items.push(item);
    } else {
      groupedData.push({
        name: item[key],
        items: [item]
      });
    }
  });
  return groupedData;
};

export const formatFilters = (filters: any[]) => {
  let labelIndex = 0;
  // fun mix of graphql and rest
  const mapped = filters.map((filter) => {
    const newObj = { ...filter };
    newObj.labelIndex = labelIndex;
    labelIndex++;
    if (newObj.filterName) {
      newObj.filter_name = newObj.filterName;
      newObj.filterName = undefined;
    }

    return newObj;
  });
  return mapped;
};

export const addLabelIndex = (filters: any[], filter: any) => {
  // labels need an index so that they can be recognised when a user removes one
  if (!filters.length) {
    filter.labelIndex = 0;
  } else if (filter) {
    filter.labelIndex = filters[filters.length - 1].labelIndex + 1;
  }
  return filter;
};

export const resourceIcon = (resourceType: string) => {
  switch (resourceType) {
    case 'employee':
      return 'person';
    case 'room':
      return 'pin';
    case 'equipment':
      return 'monitor';
  }
};

export const formatEnters = (value: string): string => {
  if (!value) {
    return '';
  }

  return value.replace(/(\r\n|\n|\r)/gm, '<br>');
};

export const giftCardCode = (value: string): string =>
  value.replace(/\d{4}(?=.)/g, '$& ');

export const twoDecimals = (value: number): number =>
  Math.round(value * 100) / 100;

export const capitalize = (value: string): string => {
  if (!value) {
    return '';
  }

  return typeof value === 'string'
    ? value.charAt(0).toUpperCase() + value.slice(1)
    : value;
};

export const replacePinapparaat = (message: string, country: string) => {
  let newText = message;

  // if company is belgium, replace pinapparaat with betaalterminal
  if (country === 'be') {
    newText = newText.replaceAll('pinapparaat', 'betaalterminal');
    newText = newText.replaceAll('pinapparaten', 'betaalterminals');
  }

  return newText;
};

export const localeString = (value: number) =>
  value.toLocaleString(i18n.locale.value);
