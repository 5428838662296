import dayjs from '@/dayjs';
import i18n from '@/i18n';
import { useCompanyStore } from '@/stores/company';
import type { Dayjs } from 'dayjs';

type DayjsObj = Dayjs & { $d: Date };

export type DateValue = string | Dayjs;
export type DateOptions = {
  format?: string;
  addYear?: boolean;
  addTime?: boolean;
};

export const formatDate = (
  dateValue: DateValue,
  { format, addYear, addTime }: DateOptions = {
    format: 'numeric',
    addYear: false,
    addTime: false
  }
): string => {
  // When no value is passed, return an empty string
  if (!dateValue) {
    return '';
  }

  // Check if the value consists of just a date instead of a full iso string
  // There's probably a better way to do this..
  const dateOnly = typeof dateValue === 'string' && dateValue.length === 10;

  // If we only have a date without a time value and timezone, we need to add a time of '00:00' and set it to the correct timezone
  // Otherwise we take the iso string and convert it to the correct timezone
  const dayjsDate = (
    dateOnly ? dayjs.tz(`${dateValue} 00:00`).tz() : dayjs(dateValue).tz()
  ) as DayjsObj;

  // When a value is passed that cannot be converted to a dayjs object, return an empty string
  if (!dayjsDate.isValid()) {
    return '';
  }

  let dateResult;
  let timeResult = '';

  const formats = [
    'numeric',
    'long',
    'longYear',
    'monthDay',
    'monthYear',
    'monthShort',
    'monthLong',
    'datePicker',
    'time'
  ];

  // When no valid format is provided, default to 'numeric'
  if (!formats.includes(format)) {
    format = 'numeric';
  }

  // To format time we need to access the company settings to get the right format
  if (format === 'time' || addTime) {
    const { companySettings } = useCompanyStore();
    const clockSetting = companySettings.locale?.clock;

    // When the company locale is set to 'REGULAR', we need to show '1:15 pm', otherwise we show 13:15
    timeResult = dayjsDate.format(
      clockSetting === 'REGULAR' ? 'h:mm a' : 'HH:mm'
    );

    if (format === 'time') {
      return timeResult;
    }
  }

  if (format === 'numeric') {
    // We don't need to use i18n for the numeric date format, because the value will be the same for every locale
    dateResult = dayjsDate.format('DD-MM-YYYY');

    // Don't end the year to the final result, even if it is passed as an argument
    addYear = false;
  } else {
    // For other formats we do need to use i18n, because it will show names of days or months that need to be shown in the correct language
    // For some reason we cannot send a ISO string to i18n, we need to send a Javascript date object, which we get from the $d property in the dayjs object
    // Dayjs also has a toDate method, but this yields a different and incorrect value

    dateResult = i18n.d(dayjsDate.$d, format);
  }

  return (
    dateResult +
    (addYear ? `, ${dayjsDate.format('YYYY')}` : '') +
    (timeResult ? ` - ${timeResult}` : '')
  );
};

export type WeekDayValue = number | string;

export const formatWeekDay = (
  dayOfWeek: WeekDayValue,
  format: string
): string => {
  // This method doesn't format date strings, but rather gets the weekday value ('Monday', 'Tuesday', etc), based on a number value.
  // To do this we need to send an actual date to i18n, so we use a value of fixed date strings.

  if (
    !format ||
    (typeof dayOfWeek !== 'number' && typeof dayOfWeek !== 'string')
  ) {
    return '';
  }

  const weekDays = [
    {
      label: 'MO',
      date: '2021-02-01T00:00:00Z'
    },
    {
      label: 'TU',
      date: '2021-02-02T00:00:00Z'
    },
    {
      label: 'WE',
      date: '2021-02-03T00:00:00Z'
    },
    {
      label: 'TH',
      date: '2021-02-04T00:00:00Z'
    },
    {
      label: 'FR',
      date: '2021-02-05T00:00:00Z'
    },
    {
      label: 'SA',
      date: '2021-02-06T00:00:00Z'
    },
    {
      label: 'SU',
      date: '2021-02-07T00:00:00Z'
    }
  ];

  let date;

  if (typeof dayOfWeek === 'number') {
    date = weekDays[(dayOfWeek || 7) - 1]?.date;
  } else {
    date = weekDays.find((day) => day.label === dayOfWeek)?.date;
  }

  if (!date) {
    return '';
  }

  const dayjsDate = dayjs(date).tz('Etc/UTC') as DayjsObj;
  return i18n.d(dayjsDate.$d, format);
};

export const ordinalDate = (number: number): string => {
  switch (i18n.locale.value.slice(0, 2)) {
    case 'nl':
      return (
        number + (number === 1 || number === 8 || number >= 20 ? 'ste' : 'de')
      );
    case 'en': {
      const b = number % 10;
      const output =
        ~~((number % 100) / 10) === 1
          ? 'th'
          : b === 1
            ? 'st'
            : b === 2
              ? 'nd'
              : b === 3
                ? 'rd'
                : 'th';
      return number + output;
    }
    case 'de':
      return `${number}.`;
    case 'pt':
      return `${number}°`;
    case 'es':
      return `${number}°`;
    case 'fr':
      return number === 1 ? `${number}er` : `${number}ème`;
    default:
      return '';
  }
};

export const fromNow = (value: DateValue): string => {
  const { company } = useCompanyStore();
  return dayjs(value).locale(company.language).fromNow();
};

export const combineDateTime = (date: string, time: string): string => {
  const timeSegments = time.split(':');
  const hours = Number.parseInt(timeSegments[0]);
  const minutes = Number.parseInt(timeSegments[1]);
  const dateObj = dayjs.tz(date).set('hour', hours).set('minute', minutes);
  return dateObj.tz().format();
};
