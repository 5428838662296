<template>
  <BaseModal
    :heading="$t('waiting_list.new_registration')"
    small
    useForm
    :parentRoute="parentRouteName"
    @submit="submit"
  >
    <BaseText mb>
      {{ $t('waiting_list.registration_modal_info') }}
    </BaseText>
    <BaseInputLabel :text="`${$t('global.items.customer', 1)}*`" />
    <CustomerSearch
      showCreate
      :selectedCustomer="customer"
      required
      mb
      @select="customer = $event"
      v-test="'wl-entry-customer'"
    />
    <BaseAlert
      v-show="customer && customer.blocked"
      color="error"
      :text="filters.capitalize($t('customers.blocked_customer'))"
      size="s"
      mb
      v-test="'wl-entry-customer-blocked'"
    />
    <BaseDropdown
      v-model="formData.serviceIds"
      :options="
        services.map((s) => ({
          value: s.id,
          label: s.name
        }))
      "
      :label="$t('global.items.service', 2)"
      searchable
      required
      mb
      v-test="'wl-entry-services'"
    />
    <BaseDropdown
      v-model="formData.resourceId"
      :options="[
        {
          value: null,
          label: $t('waiting_list.no_employee_preference')
        },
        ...employees.map((resource) => ({
          value: resource.id,
          label: resource.name
        }))
      ]"
      :label="$t('global.items.employee')"
      searchable
      resources
      mb
      v-test="'wl-entry-employee'"
    />
    <BaseRadio
      v-if="hasFeatureFlag('waiting-list-pro')"
      v-model="selectDaysOfWeek"
      :label="$t('waiting_list.available_date')"
      :options="[
        {
          value: false,
          label: $t('waiting_list.specific_date')
        },
        {
          value: true,
          label: $t('waiting_list.days_of_week')
        }
      ]"
      :mb="1.5"
      @update:modelValue="onSelectDaysOfWeek"
      v-test="'wl-entry-date-mode'"
    />
    <div v-if="selectDaysOfWeek">
      <BaseButtonList
        v-model="formData.daysOfWeek"
        :options="
          weekDays.map((value, index) => ({
            value,
            label: filters.weekdayShort(index + 1)
          }))
        "
        :label="$t('global.day_of_week')"
        required
        mb
        v-test="'wl-days-of-week'"
      />
      <BaseButtonList
        v-model="formData.partsOfDay"
        :options="[
          {
            value: 'MORNING',
            label: filters.capitalize($t('global.items.morning', 1))
          },
          {
            value: 'AFTERNOON',
            label: filters.capitalize($t('global.items.afternoon', 1))
          },
          {
            value: 'EVENING',
            label: filters.capitalize($t('global.items.evening', 1))
          }
        ]"
        :label="$t('global.part_of_day')"
        :required="selectDaysOfWeek"
        mb
        v-test="'wl-parts-of-day'"
      />
    </div>
    <BaseDatePicker
      v-else
      v-model="formData.date"
      :label="$t('global.date')"
      mb
      v-test="'wl-entry-date'"
    />
    <BaseDropdown
      v-if="multiLocation"
      v-model="formData.locationId"
      :label="$t('global.items.location', 1)"
      :options="
        locations.map((l) => ({
          value: l.id,
          label: l.internalName
        }))
      "
      mb
      v-test="'wl-entry-location'"
    />
    <BaseInput
      v-if="hasFeatureFlag('waiting-list-pro')"
      v-model="formData.notes"
      type="textarea"
      :label="$t('global.items.note', 1)"
      :placeholder="$t('waiting_list.note_placeholder')"
      v-test="'wl-entry-note'"
    />
    <template #footer>
      <BaseButton
        :loading="isLoading"
        :disabled="customer && customer.blocked"
        submitForm
        v-test="'wl-entry-submit'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';
import { required } from '@vuelidate/validators';
import CustomerSearch from '@/components/CustomerSearch.vue';
import { useResourcesStore } from '@/stores/resources';
import { useLocationsStore } from '@/stores/locations';
import { useServicesStore } from '@/stores/services';
import { useCompanyStore } from '@/stores/company';
import { useRouteDate } from '@/helpers/routing';
import { ref, reactive, defineComponent } from 'vue';
import { createEntry } from './helpers';
import { useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';

export default defineComponent({
  components: {
    CustomerSearch
  },
  inject: ['mixpanel'],
  props: {
    filterDate: String,
    parentRouteName: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { date } = useRouteDate();
    const { locationId, locations } = useLocationsStore();
    const { services } = useServicesStore();
    const { multiLocation } = useCompanyStore();
    const { employees } = useResourcesStore();
    const { hasFeatureFlag } = useUserStore();

    const route = useRoute();
    const resourceId = route.query.resourceId
      ? Number.parseInt(route.query.resourceId)
      : null;
    const isResourceIdValid =
      resourceId && !!employees.find((employee) => employee.id === resourceId);

    const dateValue = props.filterDate || date.value;

    const weekDays = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
    const currentDayOfWeek = dayjs(dateValue).day();

    const formData = reactive({
      date: dateValue,
      daysOfWeek: [weekDays[currentDayOfWeek - 1]],
      locationId,
      notes: '',
      partsOfDay: ['MORNING', 'AFTERNOON', 'EVENING'],
      resourceId: isResourceIdValid ? resourceId : null,
      serviceIds: []
    });

    return {
      customer: ref(null),
      formData,
      isLoading: ref(false),
      locations,
      services,
      multiLocation,
      employees,
      selectDaysOfWeek: ref(false),
      hasFeatureFlag,
      weekDays,
      filters
    };
  },
  validations() {
    return {
      formData: {
        customerId: {
          required
        }
      }
    };
  },
  watch: {
    customer(value) {
      this.formData.customerId = value?.id || null;
    }
  },
  methods: {
    onSelectDaysOfWeek(value) {
      let label = 'days_of_week';
      if (!value) {
        label = 'specific_date';
      }
      this.mixpanel.track(`Waiting list option ${label} selected`);
    },
    submit() {
      this.mixpanel.track('Waiting list saved');

      if (this.selectDaysOfWeek) {
        this.formData.date = null;
      } else {
        this.formData.daysOfWeek = [];
        this.formData.partsOfDay = [];
      }

      if (this.formData.partsOfDay.length === 0) {
        this.formData.partsOfDay = ['MORNING', 'AFTERNOON', 'EVENING'];
      }

      this.isLoading = true;

      createEntry(this.formData).then(() => {
        this.$router.push({
          name: this.parentRouteName
        });
      });
    }
  }
});
</script>
