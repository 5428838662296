<template>
  <component
    :is="popover.componentName"
    v-for="(popover, index) in popovers"
    v-show="popover.isVisible"
    :key="`popover-${index}`"
    :ref="popover.refName"
    :isVisible="popover.isVisible"
    :data="popover.data || {}"
    :position="popover.position || {}"
    :class="[
      $style.popover,
      { [$style.popoverCreate]: popover.name === 'create' }
    ]"
    @close="closePopover(popover)"
    @eventClick="showPopover('event', $event)"
  />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import type { Ref, PropType } from 'vue';
import PopoverMore from './PopoverMore.vue';
import PopoverEvent from './popover-event/index.vue';
import PopoverCreate from './PopoverCreate.vue';
import eventBus from '@/event-bus';
import { usePageLayoutStore } from '@/stores/page-layout';
import { useFloatingBannerStore } from '@/stores/floating-banner';
import { mapState } from 'pinia';

type PopoverPosition = {
  bottom?: number;
  left?: number;
  right?: number;
  top?: number;
};

type Popover = {
  componentName: string;
  data: any;
  isClosable: boolean;
  isVisible: boolean;
  name: string;
  position: PopoverPosition | null;
  refName: string;
};

export default defineComponent({
  components: {
    PopoverEvent,
    PopoverMore,
    PopoverCreate
  },
  props: {
    calendarElement: {
      type: HTMLElement as PropType<HTMLElement | null>,
      required: true
    }
  },
  setup() {
    const popovers: Ref<Popover[]> = ref([
      {
        name: 'create',
        isClosable: false,
        isVisible: false,
        componentName: 'PopoverCreate',
        data: null,
        position: null,
        refName: 'popoverCreate'
      },
      {
        name: 'event',
        componentName: 'PopoverEvent',
        isClosable: false,
        isVisible: false,
        data: null,
        position: null,
        refName: 'popoverEvent'
      },
      {
        name: 'more',
        isClosable: false,
        isVisible: false,
        componentName: 'PopoverMore',
        data: null,
        position: null,
        refName: 'popoverMore'
      }
    ]);

    const calendarHeader = ref<HTMLElement | null>(null);
    const calendarTop = ref<HTMLElement | null>(null);

    const scrollTop = ref(0);

    return {
      popovers,
      calendarHeader,
      calendarTop,
      scrollTop
    };
  },
  watch: {
    '$route.params.id'() {
      this.popovers.forEach((popover) => {
        if (popover.isVisible) {
          this.closePopover(popover);
        }
      });
    }
  },
  computed: {
    ...mapState(usePageLayoutStore, ['navbarWidth', 'headerHeight']),
    isTrialbarVisible() {
      const { activeBanner } = useFloatingBannerStore();
      return activeBanner === 'onboarding';
    }
  },
  methods: {
    showPopover(name: Popover['name'], info: any) {
      const popover = this.popovers.find((popover) => popover.name === name);

      if (!popover) {
        return;
      }

      //Stop event bubbling
      popover.isClosable = false;
      setTimeout(() => {
        popover.isClosable = true;
      }, 0);

      popover.isVisible = true;

      if (name === 'create') {
        popover.data = {
          date: info.dateStr
        };
        if (info.jsEvent.pageY && info.jsEvent.pageX) {
          popover.position = {
            top: info.jsEvent.pageY - this.headerHeight - 8,
            left: info.jsEvent.pageX - this.navbarWidth
          };
        } else {
          // For touch devices fullcalendar sends the touchend event, which doesn't have page coordinates
          // So we need to get the position of the target element
          const elementPosition = info.jsEvent.target.getBoundingClientRect();

          popover.position = {
            top:
              elementPosition.y +
              elementPosition.height -
              this.headerHeight -
              8,
            left: elementPosition.x + 30
          };
        }

        if (info.resource?.id) {
          popover.data.resourceId = Number.parseInt(info.resource.id);
        }
      } else if (name === 'more') {
        popover.data = {
          items: info.allSegs
        };

        this.$nextTick(() => {
          this.setPopoverPosition(popover, info.jsEvent.target, false);
        });
      } else if (name === 'event') {
        const eventResources = info.event.getResources();

        popover.data = {
          ...info.event.extendedProps,
          id: info.event.extendedProps.entryId,
          resourceId:
            eventResources?.[0] && !info.event.extendedProps.company
              ? Number.parseInt(eventResources[0].id)
              : null,
          title: info.event.title,
          start: info.event.startStr,
          end: info.event.endStr,
          allDay: info.event.allDay
        };

        this.$nextTick(() => {
          this.setPopoverPosition(popover, info.el, info.event.allDay);
        });
      }
    },
    closePopover(popover: Popover) {
      popover.isVisible = false;
      popover.isClosable = false;
    },
    setPopoverPosition(
      popover: Popover,
      selectedElement: HTMLElement,
      allDay: boolean
    ) {
      if (!selectedElement || this.$screen === 's') {
        return;
      }

      const popoverElement = this.$refs[popover.refName][0].$el;

      const navbarHeight = this.headerHeight;
      const navbarWidth = this.navbarWidth;
      const width = popoverElement.offsetWidth;
      const height = popoverElement.offsetHeight;
      const elementPosition = selectedElement.getBoundingClientRect();
      const calendarPositionTop = this.calendarElement?.offsetTop || 0;

      const position: PopoverPosition = {};

      if (popover.name === 'event') {
        const marginX = 20;
        const marginY = 10;
        const left =
          elementPosition.left +
          selectedElement.offsetWidth -
          marginX -
          navbarWidth;
        const right = window.innerWidth - elementPosition.left - marginX;
        let top = elementPosition.top + marginY - navbarHeight;
        const bottom = marginY;

        if (!allDay) {
          const minTop =
            calendarPositionTop +
            (this.calendarHeader?.offsetHeight || 0) +
            (this.calendarTop?.offsetHeight || 0);

          if (top < minTop) {
            top = minTop + marginY;
          }
        }

        const offsetY = height + top + navbarHeight + marginY;
        const trialbarMargin = this.isTrialbarVisible ? 150 : 0;
        const maxOffsetY = window.innerHeight - trialbarMargin;

        if (offsetY > maxOffsetY) {
          position.bottom = bottom + trialbarMargin;
        } else {
          position.top = top;
        }

        if (width + left + navbarWidth + marginX > window.innerWidth) {
          if (right + width <= window.innerWidth) {
            position.right = right;
          } else {
            position.left = elementPosition.left + marginX;
          }
        } else {
          position.left = left;
        }
      }

      if (popover.name === 'more') {
        const top = elementPosition.top - 80;
        const left = Math.max(
          elementPosition.left -
            (width - selectedElement.offsetWidth) / 2 +
            navbarWidth,
          0
        );

        position.top = top;
        position.left = left;
      }

      popover.position = position;
    },
    onDocumentClick(e) {
      this.popovers.forEach((popover) => {
        if (
          popover.isClosable &&
          !this.$refs[popover.refName][0].$el.contains(e.target)
        ) {
          this.closePopover(popover);
        }
      });
    },
    onShowPopover({ name, data }: { name: Popover['name']; data: any }) {
      this.showPopover(name, data);
    },
    addScrollHandler() {
      if (this.calendarElement) {
        this.calendarHeader = this.calendarElement.querySelector(
          '.fc-scrollgrid-section-header'
        );
        this.calendarTop = this.calendarElement.querySelector(
          '.fc-scrollgrid-section-body:first-child'
        );
        const scroller = this.calendarElement.querySelector(
          '.fc-scroller.fc-scroller-liquid-absolute'
        );

        if (scroller) {
          this.scrollTop = scroller.scrollTop;
          scroller.addEventListener('scroll', () => {
            const eventPopover = this.popovers.find(
              (popover) => popover.name === 'event'
            );

            if (
              eventPopover?.isVisible &&
              Math.abs(scroller.scrollTop - this.scrollTop) > 50
            ) {
              this.closePopover(eventPopover);
              this.scrollTop = scroller.scrollTop;
            }
          });
        }
      }
    }
  },
  mounted() {
    eventBus.$on('showPopover', this.onShowPopover);
    document.addEventListener('click', this.onDocumentClick);
    this.addScrollHandler();
  },
  beforeUnmount() {
    eventBus.$off('showPopover', this.onShowPopover);
    document.removeEventListener('click', this.onDocumentClick);
  }
});
</script>

<style lang="scss" module>
.popover {
  &:not(.popoverCreate) {
    .base.smallScreen & {
      right: 0;
      left: 0;
      top: 0;
      margin: auto;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
