<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <div
      ref="scrollContainer"
      :class="$style.inner"
      v-test="'app-scrollcontainer'"
    >
      <div :class="$style.header">
        <a :href="$t('global.corporate_site_url')">
          <SalonizedLogo />
        </a>
        <BaseDropdown
          v-model="activeLanguage"
          :options="languageOptions"
          @update:modelValue="switchLanguage"
          v-test="'language-selector'"
        />
      </div>
      <div :class="$style.content">
        <div :class="$style.page">
          <router-view />
        </div>
      </div>
    </div>
    <Background />
  </div>
</template>

<script setup lang="ts">
import Background from './Background.vue';
import SalonizedLogo from './SalonizedLogo.vue';

import { ref, computed } from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import config from '@/config';
import { useCompanyStore } from '@/stores/company';
import { usePageScroll } from '@/helpers/scroll';

const scrollContainer = ref();
usePageScroll(scrollContainer);

const { locale } = useI18n();

const removedLanguages = ['hr', 'pt'];
const languageOptions = computed(() =>
  config.locales
    .filter((lang) => !removedLanguages.includes(lang))
    .map((lang) => ({ label: lang.toUpperCase(), value: lang }))
);

const route = useRoute();
const activeLanguage = ref(locale.value.slice(0, 2));

const switchLanguage = () => {
  router.replace({
    name: route.name,
    params: { locale: activeLanguage.value }
  });
};

watchEffect(() => {
  const { treatwellSignup } = useCompanyStore();
  document.title = treatwellSignup ? 'Treatwell' : 'Salonized';
});

provide('utmAttributes', {
  campaign: route.query.utm_campaign || '',
  content: route.query.utm_content || '',
  medium: route.query.utm_medium || '',
  source: route.query.utm_source || '',
  term: route.query.utm_term || ''
});

const { treatwellSignup } = useCompanyStore();
const variant = ref('control');
if (treatwellSignup) {
  variant.value = 'treatwell-pilot';
} else if (Math.random() < 0.5) {
  variant.value = 'experiment';
}

provide('variant', variant.value);
</script>

<script lang="ts">
export default {
  name: 'LoggedOut'
};
</script>

<style lang="scss" module>
$headerHeight: 52px;

.base {
  height: 100%;
  &.smallScreen {
    margin: 0;
  }
}

.inner {
  position: relative;
  z-index: 1;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing * 2 $spacing * 2 0;
  margin-bottom: $spacing * 2;

  .base.smallScreen & {
    padding: $spacing * 2 $spacing 0;
  }
}

.content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  .base:not(.base.smallScreen) & {
    padding-bottom: 75px;
  }

  .base.smallScreen & {
    width: 100%;
    padding: $spacing * 2 $spacing;
  }
}

.page {
  width: 520px;
  max-width: 100%;
  padding: $spacing * 2;
  background-color: white;
  border-radius: $radius;
  box-shadow: $shadow;

  .base.smallScreen & {
    max-width: 90vw;
    padding: $spacing * 2 $spacing;
  }
}
</style>
