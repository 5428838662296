<template>
  <vue-tel-input
    v-model="value"
    :class="[
      $style.inputWrap,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
    :allCountries="countries"
    mode="international"
    :defaultCountry="defaultCountryCode"
    :dropdownOptions="{
      showSearchBox: true,
      showDialCodeInList: true,
      showFlags: true
    }"
    :inputOptions="{
      placeholder: ''
    }"
  />
</template>

<script setup lang="ts">
import { useCompanyStore } from '@/stores/company';
import 'vue-tel-input/vue-tel-input.css';
import phonePrefixes from '@/phone-prefixes';
import i18n from '@/i18n';

const props = defineProps<{
  modelValue: string;
  defaultCountry?: string;
}>();

const emit = defineEmits(['update:modelValue']);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  }
});

const regionNames = new Intl.DisplayNames([i18n.locale.value], {
  type: 'region'
});

const countries = phonePrefixes.map((item) => ({
  name: regionNames.of(item.code.toUpperCase()),
  iso2: item.code.toUpperCase(),
  dialCode: item.prefix.toString()
}));

const defaultCountryCode = computed(() => {
  const { company } = useCompanyStore();
  return company?.country
    ? company.country.toUpperCase()
    : props.defaultCountry?.toUpperCase() || 'NL';
});
</script>

<style lang="scss" module>
.inputWrap {
  @include input;
  padding-left: 0;
  padding-right: 0;
  box-shadow: none !important;

  &:focus-within {
    border-color: $color-primary !important; // Important needed to overwrite styles in vue-tel-input
  }

  & > * {
    border-radius: inherit;
    background-color: transparent;
  }

  :global(.vti__dropdown-arrow) {
    width: 8px;
  }

  :global(.vti__dropdown-list) {
    border-radius: $radius;
    border-color: $color-border-input;

    input {
      @include input;
      margin: $spacing * 0.5;
      width: calc(100% - #{$spacing});
    }
  }

  &.smallScreen {
    :global(.vti__dropdown-list) {
      max-width: 310px;
    }
  }
}
</style>
