import dayjs from '@/dayjs';
import { modal } from '@/helpers/ui';
import {
  type Order,
  useRegisterOrderStore
} from '@/modules/register/stores/order';
import { useRegisterStore } from '@/modules/register/stores/register';
import { useLocationsStore } from '@/stores/locations';
import type { Appointment, OrderItem, Transaction } from '@/types';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { CREATE_ORDER, GET_ORDER, UPDATE_ORDER } from './graphql';
import { setCustomer, setSelectedResource, setTwTransaction } from './shared';

export const setOrderData = () => {
  const loading = ref(false);
  const route = useRoute();
  const router = useRouter();

  const loadData = () =>
    new Promise<void>((resolve) => {
      const action = (route.query.action as string) || 'checkout';
      const { onResult } = useQuery(GET_ORDER, {
        id: Number(route.query.orderId)
      });
      const { order, reset: resetStore } = useRegisterOrderStore();
      const { t } = useI18n();
      const { orderAppointments } = storeToRefs(useRegisterOrderStore());
      const { locationId } = useLocationsStore();

      loading.value = true;

      onResult((result) => {
        const existingOrder = result.data.order;

        const setRouteAndStoreData = (newRoute?: string) => {
          if (
            existingOrder.locationId &&
            locationId !== existingOrder.locationId
          ) {
            router
              .replace({
                name: newRoute || (route.name as string),
                params: {
                  locationId: existingOrder.locationId
                }
              })
              .then(() => {
                setStoreData();
              });
          } else if (newRoute) {
            router.replace({ name: newRoute }).then(() => {
              setStoreData();
            });
          } else {
            setStoreData();
          }
        };

        const setStoreData = () => {
          Object.assign(order, {
            note: existingOrder.comments,
            items: [...order.items],
            transactions: [...order.transactions],
            locationId: existingOrder.locationId,
            permissions: existingOrder.permissions,
            draft: existingOrder.draft,
            fiscalizationFinished: existingOrder.fiscalizationFinished,
            pending: existingOrder.pending,
            ...(action === 'credit'
              ? {
                  originalOrderId: existingOrder.id
                }
              : {
                  id: existingOrder.id,
                  invoicedAt: existingOrder.invoicedAt,
                  state: existingOrder.state
                }),
            cashupId: existingOrder.cashupId
          });
          setSelectedResource(
            order.items
              .map((item: any) => item.employeeId)
              .concat(existingOrder.items.map((item: any) => item.employeeId))
          );
          setCustomer(existingOrder.customer);
          existingOrder.appointments.forEach((appointment: Appointment) => {
            orderAppointments.value.push(appointment);
          });
          setItems(existingOrder.items, { action });
          if (action !== 'credit' && existingOrder.transactions) {
            setTransactions(existingOrder.transactions);
          }

          router.replace({ query: {} });
          loading.value = false;

          resolve();
        };

        if (
          action === 'pay' ||
          (action !== 'credit' &&
            (!existingOrder.permissions?.editOrderItems ||
              existingOrder.state === 'PAID'))
        ) {
          // paying invoice should always reset the register and redirect to register payment
          // editing (through invoice modal or appointment checkout) existing invoice which cannot be edited, should always reset the register and redirect to register payment
          // editing (through invoice modal or appointment checkout) existing invoice which is already fully paid, should always reset the register and redirect to register payment
          resetStore();
          setRouteAndStoreData('register-payment');
        } else if (action === 'credit' || action === 'edit') {
          // crediting an editing (only through the invoice modal) an existing invoice, should always reset register
          resetStore();
          setRouteAndStoreData();
        } else if (action === 'checkout') {
          if (
            (order.id && existingOrder.id) ||
            (order.id && !order.permissions?.editOrderItems)
          ) {
            // when checking out an appointment's invoice, if the order that is already in the register cannot be edited, reset register
            // also don't mix two existing invoices together
            resetStore();
            setRouteAndStoreData();
          } else if (order.items.length) {
            // when checking out an appointment's invoice, if there are already items in the register, show pop up modal asking if those items should be added to the invoice
            modal('confirmation', {
              message: t('appointment.add_to_existing_invoice_confirmation'),
              catch: true
            })
              .then(() => {
                // if adding to existing order, don't change location
                setStoreData();
              })
              .catch(() => {
                resetStore();
                setRouteAndStoreData();
              });
          } else {
            setStoreData();
          }
        }
      });
    });

  return {
    loadData,
    loading
  };
};

export const setItems = (
  items: OrderItem[],
  { action }: { action: string }
) => {
  const { addItem } = useRegisterOrderStore();

  let lastAppointmentId: number | undefined;

  items.forEach((item: any) => {
    lastAppointmentId =
      item.appointmentPart?.appointmentId || lastAppointmentId;
    addItem({
      name: item.description,
      price: item.price,
      originalPrice: item.originalPrice || 0,
      serviceId: item.serviceId,
      productId: item.productId,
      appointmentPartId: item.appointmentPart?.id,
      appointmentId: item.transactionCost
        ? lastAppointmentId
        : item.appointmentPart?.appointmentId,
      discount: item.discountCode?.discountPercentage || 0,
      discountCodeId: item.discountCode?.id,
      giftcard: item.giftcard
        ? {
            id: item.giftcard.id,
            code: item.giftcard.code
          }
        : undefined,
      prepaidCard: item.soldPrepaidCard
        ? {
            id: item.soldPrepaidCard.id,
            description: item.soldPrepaidCard.description,
            visits: item.soldPrepaidCard.quantity
          }
        : undefined,
      usedPrepaidCardId: item.usedPrepaidCardId,
      medical: item.medical,
      medicalReferenceNumber: item.medicalReferenceNumber,
      employeeId: item.employeeId,
      loyaltyPointsAmount: item.loyaltyPointsAmount,
      transactionCost: item.transactionCost,
      ...(action === 'credit'
        ? {
            quantity: -item.quantity
          }
        : { id: item.id, quantity: item.quantity })
    });
  });
};

export const setTransactions = (transactions: Transaction[]) => {
  const { addTransaction } = useRegisterOrderStore();

  transactions.forEach((transaction: Transaction) => {
    addTransaction({
      id: transaction.id,
      type: transaction.type,
      amount: transaction.amount,
      giftcardId: transaction.giftcard?.id,
      prepaidCardId: transaction.prepaidCard?.id,
      name: transaction.giftcard?.code || transaction.prepaidCard?.description,
      deletable: transaction.deletable as boolean,
      transactionAt: transaction.transactionAt
    });
  });
  const existingOrderTwTransaction = transactions.find(
    (transaction: Transaction) =>
      transaction.type === 'TREATWELL_ONLINE_PAYMENT'
  );
  if (existingOrderTwTransaction) {
    setTwTransaction(existingOrderTwTransaction.id);
  }
};

export const mutateOrder = () => {
  const response = ref();

  const call = (
    action: string,
    { withCustomerResponse } = { withCustomerResponse: false }
  ) => {
    const { order } = useRegisterOrderStore();
    const { mutate } = useMutation(
      action === 'create' ? CREATE_ORDER : UPDATE_ORDER
    );

    mutate({
      input: {
        ...formatMutationPayload(order, action)
      },
      withCustomer: withCustomerResponse
    }).then((data) => {
      response.value = data?.data;
    });
  };

  return {
    call,
    response
  };
};

const formatMutationPayload = (order: Order, action: string) => {
  const { deletedItems, deletedTransactions, orderAppointments } =
    useRegisterOrderStore();
  const { cashupEnabled } = storeToRefs(useRegisterStore());

  return {
    ...(action === 'create'
      ? {
          originalOrderId: order.originalOrderId,
          invoicedAt: formatInvoicedAt(order.invoicedAt)
        }
      : {
          id: order.id,
          invoicedAt: order.invoicedAt
        }),
    locationId: order.locationId,
    note: order.note,
    customerId: order.customerId,
    appointmentIds: orderAppointments.map(
      (appointment: Appointment) => appointment.id
    ),
    draft: order.draft,
    pending: order.pending,
    items: deletedItems.concat(order.items).map((item: any) => ({
      medical: item.medical,
      name: item.name,
      price: item.price,
      productId: item.productId,
      quantity: item.quantity,
      serviceId: item.serviceId,
      appointmentPartId: item.appointmentPartId,
      discountCodeId: item.discountCodeId,
      originalPrice: item.originalPrice,
      loyaltyPointsAmount: item.loyaltyPointsAmount,
      employeeId: item.employeeId,
      medicalReferenceNumber: item.medicalReferenceNumber,
      destroy: item.destroy,
      ...(item.giftcard ? giftcardAttributes(item.giftcard) : {}),
      ...(item.prepaidCard ? soldPrepaidCardAttributes(item.prepaidCard) : {}),
      usedPrepaidCardId: Number.parseInt(item.usedPrepaidCardId) || null,
      ...(action !== 'create' ? { id: item.id } : {})
    })),
    transactions: deletedTransactions
      .concat(order.transactions)
      .map((transaction: any) => ({
        type: transaction.type,
        amount: transaction.amount,
        giftcardId: transaction.giftcardId,
        prepaidCardId: transaction.prepaidCardId,
        locationId: transaction.locationId,
        destroy: transaction.destroy,
        ...(action === 'create'
          ? {}
          : {
              id: transaction.id
            }),
        // when cashup enabled OR pos payment, transactionAt should be set in the backend
        ...(cashupEnabled.value || transaction.type === 'POS'
          ? {}
          : {
              transactionAt: order.invoicedAt
            })
      }))
  };
};

const giftcardAttributes = (giftcard: any) => ({
  ...(giftcard.id
    ? {
        giftcardId: giftcard.id
      }
    : {
        giftcard: {
          code: giftcard.code,
          totalAmount: giftcard.totalAmount,
          expiresAt: giftcard.expiresAt
        }
      })
});

const soldPrepaidCardAttributes = (prepaidCard: any) => ({
  ...(prepaidCard.id
    ? {
        soldPrepaidCardId: prepaidCard.id
      }
    : {
        prepaidCard: {
          description: prepaidCard.description,
          price: prepaidCard.price,
          visits: prepaidCard.visits,
          vatRateId: prepaidCard.vatRateId
        }
      })
});

const formatInvoicedAt = (invoicedAt: string | undefined) => {
  if (invoicedAt) {
    const timeNow = dayjs().tz().format('HH:mm:ss');
    return dayjs(`${invoicedAt} ${timeNow}`).format();
  } else {
    return dayjs().tz().format();
  }
};
