import { flash, modal } from '@/helpers/ui';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { DELETE_ABSENCE_MUTATION, GET_ABSENCES_QUERY } from './graphql';

export const useAbsence = () => {
  const { t } = useI18n();

  const { result, refetch, loading } = useQuery(GET_ABSENCES_QUERY);
  const { mutate } = useMutation(DELETE_ABSENCE_MUTATION);

  const absencesData = computed(() => result.value?.absences || []);

  const deleteAbsence = (id: string, { holiday }: { holiday: boolean }) =>
    modal('confirmation', {
      type: 'delete',
      item: holiday ? t('global.items.holiday') : t('global.items.absence')
    }).then(() => {
      mutate({
        input: {
          id
        }
      }).then(() => {
        flash(t('global.flash.absence_deleted'));
        refetch();
      });
    });
  return {
    absencesData,
    deleteAbsence,
    loading
  };
};
