import gql from 'graphql-tag';
import { storeToRefs } from 'pinia';
import { nextTick, ref, watch, watchEffect } from 'vue';

import apolloClient from '@/apollo/client';
import dayjs from '@/dayjs';
import {
  calendarAbsenceFragment,
  calendarAppointmentFragment,
  calendarNoteFragment,
  calendarWaitingListFragment
} from '@/graphql-fragments';
import { capitalize } from '@/helpers/formatting';
import { hideTooltip, showTooltip } from '@/helpers/ui';
import i18n from '@/i18n';
import { device } from '@/user-context';
import { useRoute } from 'vue-router';

import { useCreateAppointmentStore } from '@/stores/calendar-create-appointment';
import { ViewType, useCalendarFiltersStore } from '@/stores/calendar-filters';
import { useCalendarPreviewStore } from '@/stores/calendar-preview';
import { useCompanyStore } from '@/stores/company';
import { useLocationsStore } from '@/stores/locations';
import { useUserStore } from '@/stores/user';

import calendarInterface from '@/modules/calendar/calendar-interface';
import {
  formatEvent,
  getFullCalendarViewType,
  rruleFrequency
} from '@/modules/calendar/helpers';
import { isCalendarSaving } from '../calendar-state';
import { fetchBusinessHours } from './calendar-business-hours';

import type {
  CalendarAppointment,
  QueryCalendarAbsencesArgs,
  QueryCalendarAppointmentsArgs,
  QueryCalendarDynamicPricesArgs,
  QueryCalendarNotesArgs,
  QueryCalendarWaitingListsArgs
} from '@/types';

const fetchAppointments = (variables: QueryCalendarAppointmentsArgs) =>
  apolloClient.query({
    query: gql`
      query calendarAppointments(
        $end: DateTime!
        $locationId: ID
        $resourceId: Int
        $start: DateTime!
        $viewType: String
        $dataScope: DataScope
      ) {
        calendarAppointments(
          end: $end
          locationId: $locationId
          resourceId: $resourceId
          start: $start
          viewType: $viewType
          dataScope: $dataScope
        ) {
          ...calendarAppointment
        }
      }
      ${calendarAppointmentFragment}
    `,
    variables
  });

const fetchAbsences = (variables: QueryCalendarAbsencesArgs) =>
  apolloClient.query({
    query: gql`
      query calendarAbsences(
        $end: DateTime!
        $locationId: ID
        $resourceId: Int
        $start: DateTime!
        $viewType: String
        $dataScope: DataScope
      ) {
        calendarAbsences(
          end: $end
          locationId: $locationId
          resourceId: $resourceId
          start: $start
          viewType: $viewType
          dataScope: $dataScope
        ) {
          ...calendarAbsence
        }
      }
      ${calendarAbsenceFragment}
    `,
    variables
  });

const fetchNotes = (variables: QueryCalendarNotesArgs) =>
  apolloClient.query({
    query: gql`
      query calendarNotes(
        $end: DateTime!
        $resourceId: Int
        $start: DateTime!
        $viewType: String
        $dataScope: DataScope
        $locationId: ID
      ) {
        calendarNotes(
          end: $end
          resourceId: $resourceId
          start: $start
          viewType: $viewType
          locationId: $locationId
          dataScope: $dataScope
        ) {
          ...calendarNote
        }
      }
      ${calendarNoteFragment}
    `,
    variables
  });

const fetchDynamicPricing = (variables: QueryCalendarDynamicPricesArgs) =>
  apolloClient.query({
    query: gql`
      query calendarDynamicPrices(
        $end: DateTime!
        $resourceId: Int
        $start: DateTime!
      ) {
        calendarDynamicPrices(
          end: $end
          resourceId: $resourceId
          start: $start
        ) {
          end
          id
          start
          title
          exdate
          duration
          durationEditable
          resourceEditable
          startEditable
          rrule
          extendedProps {
            eventDuration
            entryId
            type
          }
        }
      }
    `,
    variables
  });

export const cachedAppointments = ref<CalendarAppointment[]>([]);

let existingPreviewEvents: any[] = [];

const fetchWaitingList = (variables: QueryCalendarWaitingListsArgs) =>
  apolloClient.query({
    query: gql`
      query calendarWaitingLists(
        $end: DateTime!
        $locationId: ID
        $start: DateTime!
        $dataScope: DataScope
      ) {
        calendarWaitingLists(
          end: $end
          locationId: $locationId
          start: $start
          dataScope: $dataScope
        ) {
          ...calendarWaitingList
        }
      }
      ${calendarWaitingListFragment}
    `,
    variables
  });

const fetchEvents = async ({ start, end }: { start: string; end: string }) => {
  const { locationId, dataScope } = useLocationsStore();
  const { resourceId, resourceType } = useCalendarFiltersStore();

  const variables: QueryCalendarAppointmentsArgs = {
    viewType: resourceType,
    start,
    end,
    dataScope,
    resourceId
  };

  if (locationId) {
    variables.locationId = locationId.toString();
  }

  const { dynamicPricingMode } = useCalendarFiltersStore();

  const queries = dynamicPricingMode
    ? [fetchDynamicPricing(variables)]
    : [
        fetchAppointments(variables),
        fetchAbsences(variables),
        fetchNotes(variables)
      ];

  const { companySettings } = useCompanyStore();
  const { hasFeatureFlag } = useUserStore();
  const addWaitingList =
    !dynamicPricingMode &&
    hasFeatureFlag('waiting-list') &&
    companySettings.bookings.waitingListEnabled;

  if (addWaitingList) {
    queries.push(fetchWaitingList(variables));
  }

  const responseArray = await Promise.all(queries);
  let events: any[] = [];
  responseArray.forEach(({ data }) => {
    if (data.calendarAppointments) {
      cachedAppointments.value = data.calendarAppointments;
    }
    events = events.concat(data[Object.keys(data)[0]]);
  });

  await fetchBusinessHours(variables);

  return events.map((event) => formatEvent(event));
};

export const calendarOptions = {
  eventSources: [
    {
      id: 'default',
      events: (
        info: { startStr: string; endStr: string },
        successCallback: any,
        failureCallback: any
      ) => {
        fetchEvents({
          start: info.startStr,
          end: info.endStr
        })
          .then((events) => {
            const { previewModeActive } = useCalendarPreviewStore();
            if (previewModeActive) {
              const { formData } = useCreateAppointmentStore();
              if (formData.id) {
                existingPreviewEvents = events.filter(
                  (event) => event.extendedProps.entryId === formData.id
                );
                events = events.filter(
                  (event) => event.extendedProps.entryId !== formData.id
                );
              }
            }

            successCallback(events);
          })
          .catch((error) => {
            failureCallback(error);
          });
      }
    },
    {
      id: 'preview',
      events: (info: any, successCallback: any) => {
        const { previewModeActive } = useCalendarPreviewStore();
        const { isFetchingAppointment } = useCreateAppointmentStore();

        if (previewModeActive && !isFetchingAppointment) {
          const { previewEvents } = useCalendarPreviewStore();
          successCallback(previewEvents);
        } else {
          successCallback([]);
        }
      },
      classNames: ['fc-event-preview']
    }
  ],
  eventDidMount: ({ el, event }: { el: HTMLElement; event: any }) => {
    if (!device.touch) {
      el.addEventListener('mousemove', (e) => {
        const target = e.target as Element;
        const iconEl: HTMLElement | null = target?.closest('.js-calendar-icon');
        if (iconEl) {
          const { tooltipName, tooltipText } = iconEl.dataset;
          if (tooltipText) {
            showTooltip(tooltipText, iconEl);
          } else if (event.allDay) {
            showTooltip(
              capitalize(i18n.t(`global.items.${event.extendedProps.type}`, 1)),
              iconEl
            );
          } else if (tooltipName) {
            showTooltip(
              i18n.t(
                `appointment.features.${tooltipName}`,
                tooltipName === 'recurring_with_frequency'
                  ? { frequency: rruleFrequency(event.extendedProps.rrule) }
                  : {}
              ),
              iconEl
            );
          }
        } else {
          hideTooltip();
        }
      });
      el.addEventListener('mouseleave', hideTooltip);
    }
  }
};

export const useWatchers = () => {
  const { dynamicPricingMode, resourceId, resourceType, viewType, date } =
    storeToRefs(useCalendarFiltersStore());
  const { companySettings } = useCompanyStore();
  const hideClosedResources = companySettings.agenda.hideClosedResources;

  const route = useRoute();

  watch(
    () => dynamicPricingMode.value,
    () => refetchEvents()
  );
  watch(
    () => resourceId.value,
    () => refetchEvents()
  );
  watch(
    () => resourceType.value,
    () => refetchEvents()
  );

  watch(
    () => date.value,
    (newDate, prevDate) => {
      // Only fetch new events when switching to a new date in week view, or a new day in day view
      if (
        (viewType.value === ViewType.Week &&
          !dayjs(newDate).isSame(prevDate, 'isoWeek')) ||
        viewType.value === ViewType.Day
      ) {
        refetchEvents();
      }
    }
  );

  watch(
    () => viewType.value,
    () => {
      if (calendarInterface.api) {
        calendarInterface.api.changeView(
          getFullCalendarViewType(viewType.value)
        );

        if (hideClosedResources && viewType.value === ViewType.Day) {
          refetchEvents();
          calendarInterface.api.refetchResources();
        }
      }
    }
  );

  const { locationId, dataScope } = storeToRefs(useLocationsStore());

  watch(
    () => locationId.value,
    () => refetchEvents()
  );
  watch(
    () => dataScope.value,
    () => refetchEvents()
  );

  const refetchEvents = async () => {
    if (route.name === 'logged-out') {
      return;
    }

    // NextTick is added because certain properties react when other properties change
    // For example, when changing the resourceType, the resourceId will reset if it's not of that type
    // We need to make sure this happens before refetching
    await nextTick();

    if (calendarInterface.api) {
      calendarInterface.api.refetchEvents();
    }
  };

  watchEffect(() => {
    // When an event gets edited using the preview mode, we need to remove the existing event for the calendar
    // This will be saved in the store so that it can be re-added later

    if (!calendarInterface.api) {
      return;
    }

    const { previewModeActive } = useCalendarPreviewStore();
    const { formData } = useCreateAppointmentStore();

    if (previewModeActive) {
      const events = calendarInterface.api
        .getEvents()
        .filter((event) => event.extendedProps.entryId === formData.id);

      if (events?.length) {
        existingPreviewEvents = events;

        events.forEach((event) => {
          event.remove();
        });
      }
    } else if (existingPreviewEvents.length) {
      existingPreviewEvents.forEach((event) => {
        if (calendarInterface?.api) {
          calendarInterface.api.addEvent(event);
        }
      });

      existingPreviewEvents = [];
    }
  });

  watch(isCalendarSaving, (isCalendarSaving) => {
    // Remove the existing event when the calendar starts saving, rather than when saving is complete
    // This prevents flickering

    if (isCalendarSaving) {
      existingPreviewEvents = [];
    }
  });
};
