<template>
  <div :class="$style.base">
    <CreateCustomer
      v-if="showCreateModal"
      :modalData="modalData"
      @close="showCreateModal = false"
      @submit="selectCustomer"
    />
    <BaseSearch
      v-model="localValue"
      :placeholder="
        $t('global.actions.search_item', {
          item: $t('global.items.customer', 1)
        })
      "
      :small="small"
      :focus="focus"
      :required="required"
      :error="
        showError
          ? filters.capitalize(
              $t('global.validations.required', 1, {
                value: $t('global.items.customer')
              })
            )
          : null
      "
    />
    <div
      v-show="isExpanded"
      ref="dropdown"
      :class="$style.floating"
      v-test="'_customer-search-list'"
    >
      <div :class="$style.scrollarea">
        <div v-if="isTyping || $apollo.loading" :class="$style.spinner">
          <BaseSpinner inline />
        </div>
        <div v-else>
          <div
            v-for="(item, index) in customers"
            :key="index"
            :class="$style.item"
            tabindex="0"
            @click="selectCustomer(item)"
            @keyup.enter="selectCustomer(item)"
            v-test="'customer-search-result'"
          >
            <BaseAvatar :name="item.firstName" :url="item.picture" :mr="0.5" />
            <div :class="$style.info">
              <div :class="$style.nameHolder">
                <BaseText
                  oneLine
                  :tooltip="{ text: [item.firstName, item.lastName].join(' ') }"
                >
                  {{ item.firstName }} {{ item.lastName }}
                </BaseText>
                <BaseText
                  v-if="item.blocked"
                  inline
                  iconBefore="slash"
                  color="error"
                  size="s"
                  :ml="0.25"
                  :class="$style.blockedLabel"
                  v-test="'blocked-label'"
                >
                  {{ $t('global.blocked') }}
                </BaseText>
              </div>
              <div :class="[$style.customerDetails, $style.smallText]">
                <span v-if="item.address">{{ item.address }}</span>
                <span v-if="item.zipcode">{{ item.zipcode }}</span>
                <span v-if="item.city">{{ item.city }}</span>
                <span v-if="item.phone">{{ item.phone }}</span>
                <span v-if="item.mobile_phone">{{ item.mobilePhone }}</span>
                <div v-if="item.email">{{ item.email }}</div>
              </div>
              <div :class="$style.activity">
                <div :class="[$style.activityIcon, $style[item.activity]]" />
                <div :class="[$style.smallText, $style.italic]">
                  {{ $t(`customer.activity.${item.activity}`) }}
                </div>
              </div>
            </div>
            <div :class="$style.right">
              <div v-if="item.alert_notes" :class="$style.smallText">
                <div class="icon-alert" />
              </div>
            </div>
          </div>
          <div
            v-show="!$apollo.loading && !customers.length"
            :class="$style.notFound"
          >
            {{ $t('global.no_results') }}
          </div>
        </div>
        <div
          v-if="showCreate"
          :class="[$style.item, $style.create]"
          @click="showCreateModal = true"
          v-test="'customerSearchCreateCustomer'"
        >
          <BaseIcon name="plus" color="success" size="s" />
          {{ $t('global.actions.create_item', { item: localValue }) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { customerFragment } from '@/graphql-fragments';
import gql from 'graphql-tag';
import CreateCustomer from '@/modals/CreateCustomer.vue';

import { defineComponent } from 'vue';
import type { PropType } from 'vue';

export default defineComponent({
  components: {
    CreateCustomer
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    showError: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    selectedCustomer: {
      type: Object as PropType<{ [key: string]: any } | null>
    },
    focus: {
      type: Boolean,
      default: false
    },
    showCreate: {
      type: Boolean,
      default: false
    },
    excludedCustomerId: {
      type: Number,
      required: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'select'],
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      localValue: '',
      isExpanded: false,
      isTyping: false,
      showCreateModal: false,
      customers: []
    };
  },
  apollo: {
    customers: {
      query: gql`
        query getCustomers(
          $search: String
          $pagination: PaginationAttributes
          $excludedId: Int
        ) {
          customers(
            search: $search
            pagination: $pagination
            excludedId: $excludedId
          ) {
            ...customer
          }
        }
        ${customerFragment}
      `,
      debounce: 500,
      variables() {
        return {
          search: this.localValue,
          excludedId: Number.parseInt(this.excludedCustomerId)
        };
      },
      skip() {
        return !this.localValue || this.isCustomerSelected;
      },
      watchLoading() {
        this.isTyping = false;
      }
    }
  },
  watch: {
    showCreateModal(newValue, oldValue) {
      this.isExpanded = false;

      if (oldValue && !newValue) {
        this.localValue = '';
      }
    },
    selectedCustomerName: {
      handler() {
        this.localValue = this.selectedCustomerName;
      },
      immediate: true
    },
    localValue() {
      if (!this.isCustomerSelected) {
        this.isExpanded = !!this.localValue;
        this.isTyping = true;
      }

      this.$emit('update:modelValue', this.localValue);
    },
    modelValue: {
      handler(value) {
        if (!this.selectedCustomer) {
          this.localValue = value;
        }
      },
      immediate: true
    }
  },
  computed: {
    selectedCustomerName() {
      if (!this.selectedCustomer) {
        return '';
      }
      return (
        this.selectedCustomer.fullName ||
        `${this.selectedCustomer.first_name} ${this.selectedCustomer.last_name}`
      );
    },
    modalData() {
      const values = this.localValue.split(' ');
      const firstName = values[0];
      values.shift();
      const lastName = values.join(' ');

      return { firstName, lastName };
    },
    isCustomerSelected() {
      return (
        this.selectedCustomerName &&
        this.localValue === this.selectedCustomerName
      );
    }
  },
  methods: {
    selectCustomer(customer) {
      this.isExpanded = false;
      this.showCreateModal = false;
      this.$emit('select', customer);
    },
    onDocumentClick(e) {
      if (!this.$refs.dropdown?.contains(e.target) && this.isExpanded) {
        this.isExpanded = false;
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.onDocumentClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.onDocumentClick);
  }
});
</script>

<style lang="scss" module>
.base {
  flex: 1;
  position: relative;
}

.nameHolder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.blockedLabel {
  flex-shrink: 0;
}
.icon {
  position: absolute;
  left: 12px;
  top: 50%;
  width: 20px;
  opacity: 0.5;
  margin-top: -10px;
  pointer-events: none;
  z-index: 1;
}

.smallText {
  font-size: 10px;
  margin-bottom: 2px;
  opacity: 0.7;
}

.floating {
  position: absolute;
  top: calc(100% + #{$spacing * 0.5});
  left: 0;
  min-width: 180px;
  width: 100%;
  border-radius: $radius;
  background: $white;
  box-shadow: $shadow;
  z-index: 10;
}

.info {
  overflow-x: hidden;
  margin: 4px 0;
  flex: 1;
}

.item {
  cursor: pointer;

  @include hover {
    background: $color-highlight;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $color-border;
  }
}

.spinner {
  margin: 10px 0;
}

.item,
.notFound {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: ($spacing * 0.25) $spacing;
}

.item {
  &:focus {
    outline: none;
    background: $color-highlight;
  }

  @include hover {
    background: $color-highlight;
  }
}

.create {
  font-weight: bold;
  padding: $spacing;

  & > *:first-child {
    margin-right: $spacing * 0.25;
  }
}

.right {
  display: flex;
  align-items: center;
  margin-left: auto;

  > div {
    margin-left: 2px;
  }
}

.italic {
  font-style: italic;
}

.activity {
  display: flex;
  align-items: center;
}

.activityIcon {
  margin-right: $spacing * 0.25;
  width: $spacing * 0.25;
  height: $spacing * 0.25;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: #8c9a9f;

  &.high {
    background-color: #03cfa5;
  }

  &.medium {
    background-color: #fd832d;
  }

  &.none {
    background-color: #ff6161;
  }
}

.scrollarea {
  max-height: 180px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.customerDetails {
  & > * {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  & > *:not(:last-child) {
    &:after {
      content: ' - ';
    }
  }
}
</style>
