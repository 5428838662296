<template>
  <div :class="$style.base">
    <BaseButton
      color="inverted"
      :options="popoverOptions"
      icon="settings"
      @select="handleListItemClick"
      v-test="'manage-subscription-link'"
    />
    <CancelationFlow
      v-if="hasCancelModal"
      @close="(currentStep: string) => hideCancelModal(currentStep)"
    />
  </div>
</template>

<script lang="ts" setup>
import type { IconName } from '@/components/base-icon/types';
import { SubscriptionPlan } from '@/types';
import { useI18n } from 'vue-i18n';
import { useStripe } from '@/helpers/stripe';

import CancelationFlow from './cancel-subscription/index.vue';
import { useCompanyStore } from '@/stores/company';
import getCompanySubscription from './get-company-subscription';
import { useUserStore } from '@/stores/user';
import onUpgradeButtonClick from '@/modules/_shared/subscription/on-upgrade-button-click';
import { useCurrentPlanQuote } from './store';
import { storeToRefs } from 'pinia';

type PopoverOption = {
  label: string;
  value: string;
  icon: IconName;
};

const { t } = useI18n();
const { goToStripePortal } = useStripe();
const { isSubscribed } = useCompanyStore();
const { activeSubscription } = getCompanySubscription();
const { quote } = storeToRefs(useCurrentPlanQuote());
const { user, hasFeatureFlag } = useUserStore();
const { handleUpgradeButtonClick } = onUpgradeButtonClick();
const hasCancelModal = ref(false);
const mixpanel = inject<any>('mixpanel');

const canUpgradeSubscription = computed(
  () =>
    quote.value &&
    quote.value.plan !== SubscriptionPlan.Pro &&
    !activeSubscription.value?.cancelled
);

const canCancelSubscription = computed(
  () =>
    isSubscribed &&
    user?.owner &&
    activeSubscription.value &&
    !activeSubscription.value.cancelled &&
    !hasFeatureFlag('subscription-frozen')
);

const popoverOptions: ComputedRef<PopoverOption[]> = computed(() => {
  const result = [];

  if (canUpgradeSubscription.value) {
    const managePlanOption: PopoverOption = {
      label: t('admin.cards.plan.manage_plan'),
      value: 'manage_plan',
      icon: 'trending'
    };

    result.push(managePlanOption);
  }

  const editOption: PopoverOption = {
    label: t('admin.cards.plan.edit_payment_method'),
    value: 'edit_payment_method',
    icon: 'edit'
  };

  result.push(editOption);

  if (canCancelSubscription.value) {
    const cancelOption: PopoverOption = {
      label: t('admin.cards.plan.cancel_subscription'),
      value: 'cancel_subscription',
      icon: 'close'
    };

    result.push(cancelOption);
  }

  return result;
});

const handleListItemClick = (option: string) => {
  switch (option) {
    case 'manage_plan':
      mixpanel.track('Manage subscription - select manage plan');
      handleUpgradeButtonClick();
      break;
    case 'edit_payment_method':
      mixpanel.track('Billing page: edit payment method');
      goToStripePortal();
      break;
    case 'cancel_subscription':
      mixpanel.track('Cancelation flow: cancelation flow initialized');
      hasCancelModal.value = true;
      break;
    default:
      break;
  }
};

const hideCancelModal = (currentStep: string) => {
  mixpanel.track('Cancelation flow: cancelation flow close button clicked', {
    step: currentStep
  });
  hasCancelModal.value = false;
};
</script>

<style lang="scss" module>
.base {
  position: relative;
}
</style>
