<template>
  <div :class="[$style.base, { [$style.withImagePreview]: withImagePreview }]">
    <div v-if="withImagePreview">
      <BaseImage
        v-if="image"
        :cloudinaryId="image"
        :width="120"
        :height="120"
      />
      <div v-else :class="$style.placeholder">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="100"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            fill="#231F20"
            fill-rule="evenodd"
            d="M8 10a1.5 1.5 0 1 0-.001-3.001A1.5 1.5 0 0 0 8 10Zm10 9H6.561l7.005-5.845c.246-.209.692-.208.933-.001L19 16.994V18a1 1 0 0 1-1 1ZM6 5h12a1 1 0 0 1 1 1v8.364l-3.203-2.732c-.99-.842-2.539-.842-3.52-.006L5 17.698V6a1 1 0 0 1 1-1Zm12-2H6C4.346 3 3 4.346 3 6v12c0 1.654 1.346 3 3 3h12c1.654 0 3-1.346 3-3V6c0-1.654-1.346-3-3-3Z"
            clip-rule="evenodd"
          />
          <mask
            id="a"
            width="18"
            height="18"
            x="3"
            y="3"
            maskUnits="userSpaceOnUse"
            style="mask-type: luminance"
          >
            <path
              fill="#fff"
              fill-rule="evenodd"
              d="M8 10a1.5 1.5 0 1 0-.001-3.001A1.5 1.5 0 0 0 8 10Zm10 9H6.561l7.005-5.845c.246-.209.692-.208.933-.001L19 16.994V18a1 1 0 0 1-1 1ZM6 5h12a1 1 0 0 1 1 1v8.364l-3.203-2.732c-.99-.842-2.539-.842-3.52-.006L5 17.698V6a1 1 0 0 1 1-1Zm12-2H6C4.346 3 3 4.346 3 6v12c0 1.654 1.346 3 3 3h12c1.654 0 3-1.346 3-3V6c0-1.654-1.346-3-3-3Z"
              clip-rule="evenodd"
            />
          </mask>
          <g mask="url(#a)"><path d="M0 0h24v24H0z" /></g>
        </svg>
      </div>
    </div>
    <div :class="$style.buttons">
      <BaseButton
        :loading="isLoading"
        color="inverted"
        @click="clickUpload"
        v-test="'_base-upload-upload'"
      >
        <div :class="$style.inner">
          <BaseIcon size="s" name="cloud-upload" :mr="0.5" />
          <BaseText v-test="'uploadText'">
            {{ innerText }}
          </BaseText>
        </div>
      </BaseButton>
      <BaseButton
        v-if="withDelete && existingImage"
        color="inverted"
        @click="$emit('delete')"
        v-test="'_base-upload-delete'"
      >
        {{ $t('global.actions.delete') }}
      </BaseButton>
    </div>
    <input
      id="base-upload-file"
      ref="upload"
      :class="$style.fileInput"
      type="file"
      accept="image/*,application/pdf"
      :multiple="multiupload ? 'multiple' : null"
      @change="onChange"
      v-test="'_base-upload-input'"
    />
    <Dropfield
      v-if="dropfield"
      mt
      :loading="isLoading"
      @dropped="onDrop"
      v-test="'dropfield'"
    />
    <BaseText v-if="showSupport" mt size="s" v-test="'showSupport'">
      {{ $t('global.image_support') }}
    </BaseText>
    <BaseText v-if="showFileSizeError" color="error">
      {{ $t('global.file_too_large', { size: '10mb' }) }}
    </BaseText>
  </div>
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import axios from 'axios';
import gql from 'graphql-tag';
import Dropfield from './Dropfield.vue';
import config from '@/config';

import { defineComponent } from 'vue';

export type CloudinaryResponse = {
  access_mode: string;
  asset_id: string;
  bytes: number;
  created_at: string;
  etag: string;
  filename: string;
  folder: string;
  format: string;
  height: number;
  placeholder: boolean;
  public_id: string;
  resource_type: string;
  secure_url: string;
  signature: string;
  tags: string[];
  type: string;
  url: string;
  version: number;
  version_id: string;
  width: number;
};

export default defineComponent({
  components: {
    Dropfield
  },
  inheritAttrs: false,
  props: {
    hasImage: {
      type: Boolean,
      default: false
    },
    preset: {
      type: String,
      required: true
    },
    showSupport: {
      type: Boolean,
      default: false
    },
    multiupload: {
      type: Boolean,
      default: false
    },
    dropfield: {
      type: Boolean,
      default: false
    },
    text: {
      type: String
    },
    useNewBackend: {
      type: Boolean,
      default: false
    },
    withImagePreview: {
      type: Boolean,
      default: false
    },
    withDelete: {
      type: Boolean,
      default: false
    },
    image: {
      type: String
    }
  },
  emits: ['uploadResults', 'error', 'imageUpload', 'delete'],
  data() {
    return {
      loadingAmount: 0,
      results: null,
      files: null,
      filesSelected: 0,
      fileContents: null,
      formData: null,
      showFileSizeError: false
    };
  },
  computed: {
    isLoading() {
      return this.loadingAmount > 0;
    },
    innerText() {
      if (this.text) {
        return this.text;
      }

      return this.existingImage
        ? this.$t('global.actions.edit')
        : this.$t('global.actions.attach_file');
    },
    existingImage() {
      return this.hasImage || this.image;
    }
  },
  methods: {
    clickUpload() {
      this.$refs.upload.click();
    },
    onDrop(event) {
      this.$emit('imageUpload', 'drop');
      this.files = event;
      this.filesSelected = event.length;
      this.uploadToCloudinary();
    },
    onChange(event) {
      this.$emit('imageUpload', 'click');
      this.files = event.target.files;
      this.filesSelected = this.multiupload ? event.target.files.length : 1;
      this.uploadToCloudinary();
    },
    async uploadToCloudinary() {
      this.showFileSizeError = false;

      for (let i = 0; i < this.filesSelected; i++) {
        const file = this.files[i];

        if (file.size > 10000000) {
          this.showFileSizeError = true;
          return;
        }

        if (this.useNewBackend) {
          this.loadingAmount++;

          // eslint-disable-next-line no-useless-escape
          const checkFile = file.name.match(/[\/.](gif|jpg|jpeg|png)$/i);
          if (!checkFile) {
            this.$emit('error');
            this.loadingAmount--;
            return;
          }

          const convertBlobToBase64 = (blob) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onerror = reject;
              reader.onload = () => {
                resolve(reader.result);
              };
              reader.readAsDataURL(blob);
            });

          const base64ToBackend = (blob) =>
            convertBlobToBase64(blob).then((text) =>
              text.slice(text.indexOf(',')).substring(1)
            );

          const backendReadyFile = await base64ToBackend(file);

          this.$apollo
            .mutate({
              mutation: gql`
                mutation uploadImage($input: UploadImageInput!) {
                  uploadImage(input: $input) {
                    clientMutationId
                    errors {
                      attribute
                      message
                      type
                    }
                    image {
                      bytes
                      filename
                      id
                      lastModified
                      mimeType
                      originalUrl
                      publicId
                      transformedUrl
                      usage
                    }
                  }
                }
              `,
              variables: {
                input: {
                  file: backendReadyFile,
                  lastModified: dayjs(),
                  mimeType: file.type,
                  filename: file.name,
                  usage: 'NEWSLETTER_CONTENT'
                }
              }
            })
            .then((response) => {
              this.results = response.data;
              this.results.filename = file.name;
              this.$emit('uploadResults', this.results);
            })
            .catch(() => {
              this.$emit('error', 'Wrong filetype.');
            })
            .finally(() => {
              this.loadingAmount--;
              if (this.$refs.upload?.value) {
                this.$refs.upload.value = '';
              }
            });

          return;
        }

        const reader = new FileReader();
        reader.addEventListener('load', async () => {
          this.fileContents = reader.result;
          this.formData = {
            upload_preset: this.preset,
            file: this.fileContents
          };
          const cloudinaryUploadUrl = `https://api.cloudinary.com/v1_1/${config.cloudinaryCloudName}/auto/upload`;

          const req = axios.create({
            withCredentials: false
          });

          this.loadingAmount++;

          req
            .post(cloudinaryUploadUrl, this.formData)
            .then((response) => {
              this.results = response.data;
              this.results.filename = file.name;
              this.$emit('uploadResults', this.results);
            })
            .catch(() => {
              this.$emit('error');
            })
            .finally(() => {
              this.loadingAmount--;
              if (this.$refs.upload?.value) {
                this.$refs.upload.value = '';
              }
            });
        });
        if (file?.name) {
          reader.readAsDataURL(file);
        }
      }
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing;

  &.withImagePreview {
    align-items: flex-end;
  }

  &:not(.withImagePreview) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.inner {
  display: flex;
  align-items: center;
}

.fileInput {
  opacity: 0;
  z-index: -9001;
  position: absolute;
  bottom: 0;
  height: 0;
}

.placeholder {
  border: 1px solid $color-border-input;
  height: 128px;
  width: 128px;
  border-radius: $radius;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons {
  display: flex;
  gap: $spacing;
  flex-wrap: wrap;
}
</style>
