<template>
  <div :class="$style.base">
    <BaseGrid container>
      <BaseGrid :size="6">
        <BaseCard>
          <div v-if="productCalculations">
            <BaseHeading size="s" :mb="0.5">
              {{ $t('global.total_items') }}
            </BaseHeading>
            <BaseHeading v-test="'inventoryStatsTotalProducts'">
              {{ productCalculations.totalProducts }}
            </BaseHeading>
          </div>
          <BaseSpinner
            v-show="!productCalculations && $apollo.loading"
            inline
          />
        </BaseCard>
      </BaseGrid>
      <BaseGrid :size="6">
        <BaseCard>
          <div v-if="productCalculations">
            <BaseHeading size="s" :mb="0.5">
              {{ $t('global.total_inventory') }}
            </BaseHeading>
            <BaseHeading v-test="'inventoryStatsTotalValue'">
              {{ filters.currency(productCalculations.totalValue) }}
            </BaseHeading>
          </div>
          <BaseSpinner
            v-show="!productCalculations && $apollo.loading"
            inline
          />
        </BaseCard>
      </BaseGrid>
    </BaseGrid>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import gql from 'graphql-tag';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    categoryIds: {
      type: Array,
      required: true
    },
    supplierIds: {
      type: Array,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      productCalculations: null
    };
  },
  computed: {
    ...mapState(useLocationsStore, ['locationId'])
  },
  apollo: {
    productCalculations: {
      query: gql`
        query getProductCalculations(
          $locationId: ID
          $categoryIds: [Int!]
          $supplierIds: [Int!]
        ) {
          productCalculations(
            locationId: $locationId
            categoryIds: $categoryIds
            supplierIds: $supplierIds
          ) {
            totalProducts
            totalValue
          }
        }
      `,
      variables() {
        return {
          locationId: Number.parseInt(this.locationId),
          categoryIds: this.categoryIds,
          supplierIds: this.supplierIds
        };
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  }
});
</script>

<style lang="scss" module>
.base {
  margin-top: -$spacing * 2;
}
</style>
