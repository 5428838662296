<template>
  <EditReplyModal
    v-if="showEditReplyModal"
    @close="showEditReplyModal = false"
    @submit="mutate"
  />
  <BaseModalSmall v-else testId="publish-location" @close="$emit('close')">
    <BaseText size="l">
      {{ $t('feedback.publish_options.heading') }}
    </BaseText>
    <template #center>
      <BaseRadio
        v-model="publicationSetting"
        :label="$t('feedback.publish_options.website')"
        mb
        :options="[
          {
            label: $t('feedback.publish_options.reviews_replies'),
            value: PublicationSetting.ReviewsAndReplies
          },
          {
            label: $t('feedback.publish_options.reviews_only'),
            value: PublicationSetting.ReviewsOnly
          },
          {
            label: $t('feedback.publish_options.dont_show'),
            value: PublicationSetting.Nothing
          }
        ]"
        v-test="'radio-publication-setting'"
      />
      <div v-if="treatwellStatus.hasVenue" v-test="'treatwell-alerts'">
        <BaseAlert
          v-if="!!selected.twPublishedAt"
          :text="$t('feedback.treatwell.published')"
          v-test="'treatwell-published-alert'"
        />
        <BaseAlert
          v-else
          :text="$t('feedback.treatwell.not_published')"
          :primaryAction="{
            text: $t('feedback.treatwell.link_to_batch_publish'),
            routerLink: { name: 'feedback-publish' }
          }"
          v-test="'treatwell-not-published-alert'"
        />
      </div>
    </template>
    <template #footer>
      <BaseButton
        color="inverted"
        @click="$emit('close')"
        v-test="'btn-publish-cancel'"
      >
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton
        :loading="loading"
        @click="submit"
        v-test="'btn-publish-submit'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModalSmall>
</template>

<script setup lang="ts">
import { inject, ref } from 'vue';
import { UPDATE_FEEDBACK } from '../../graphql';
import { useMutation } from '@vue/apollo-composable';
import { flash, modal } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import { useTreatwellStore } from '@/stores/treatwell';
import EditReplyModal from './EditReplyModal.vue';
import { containsHTML } from '@/modules/feedback/helpers';

const emit = defineEmits(['close']);

const { t } = useI18n();

const selected = inject<any>('selected');
const showEditReplyModal = ref(false);

enum PublicationSetting {
  ReviewsAndReplies = 'reviews_replies',
  ReviewsOnly = 'reviews_only',
  Nothing = 'nothing'
}

const { treatwellStatus } = useTreatwellStore();

const publicationSetting = ref(PublicationSetting.Nothing);

if (selected.value.state === 'published' && selected.value.messagesPublished) {
  publicationSetting.value = PublicationSetting.ReviewsAndReplies;
} else if (selected.value.state === 'published') {
  publicationSetting.value = PublicationSetting.ReviewsOnly;
}

const { mutate, loading, onDone } = useMutation(UPDATE_FEEDBACK, () => {
  const input: any = {
    id: selected.value.id,
    state:
      publicationSetting.value === PublicationSetting.Nothing
        ? 'ALLOWED'
        : 'PUBLISHED',
    messagesPublished:
      publicationSetting.value === PublicationSetting.ReviewsAndReplies
  };

  return {
    variables: {
      input
    }
  };
});

onDone(
  ({
    data: {
      updateFeedback: { feedback }
    }
  }) => {
    flash(t('global.flash.feedback_updated'));
    selected.value.state = feedback.state;
    selected.value.twPublishedAt = feedback.twPublishedAt;
    selected.value.messagesPublished = feedback.messagesPublished;

    emit('close');
  }
);

const submit = async () => {
  if (
    publicationSetting.value === PublicationSetting.ReviewsAndReplies &&
    selected.value.messages.length > 1
  ) {
    await modal('confirmation', {
      message: t('feedback.reply.multi_reply_warning')
    });
  }

  const message = selected.value.messages[0];

  if (
    treatwellStatus.hasVenue &&
    publicationSetting.value === PublicationSetting.ReviewsAndReplies &&
    message?.message &&
    containsHTML(message.message)
  ) {
    showEditReplyModal.value = true;
  } else {
    mutate();
  }
};
</script>
