<template>
  <div
    ref="el"
    :class="[
      $style.base,
      $style[size],
      $style[color],
      {
        [$style.smallScreen]: $screen === 's',
        [$style.closable]: closable,
        [$style.inline]: inline,
        [$style.alignVertical]:
          closable || (primaryAction && secondaryAction) || width < 330
      }
    ]"
  >
    <div :class="$style.inner" v-test="'_base-alert'">
      <div :class="$style.content">
        <div :class="$style.contentLeft">
          <BaseIcon
            v-if="icon"
            :name="icon"
            :mr="0.5"
            :color="color"
            :size="size"
          />
          {{ text }}
        </div>
        <div :class="$style.contentRight">
          <component
            :is="secondaryAction ? 'BaseButton' : 'BaseText'"
            v-if="
              primaryAction &&
              (typeof primaryAction === 'string' ||
                (primaryAction.text &&
                  (primaryAction.routerLink || primaryAction.href)))
            "
            :href="primaryAction.href || undefined"
            :routerLink="primaryAction.routerLink || undefined"
            :color="secondaryAction ? 'inverted' : color"
            link
            @click="$emit('primaryAction')"
            v-test="'_base-alert-link-primary'"
          >
            {{
              typeof primaryAction === 'string'
                ? primaryAction
                : primaryAction.text
            }}
          </component>
          <BaseText
            v-if="
              secondaryAction &&
              (typeof secondaryAction === 'string' ||
                (secondaryAction.text &&
                  (secondaryAction.routerLink || secondaryAction.href)))
            "
            :href="secondaryAction.href || undefined"
            :routerLink="secondaryAction.routerLink || undefined"
            :color="color"
            link
            @click="$emit('secondaryAction')"
            v-test="'_base-alert-link-secondary'"
          >
            {{
              typeof secondaryAction === 'string'
                ? secondaryAction
                : secondaryAction.text
            }}
          </BaseText>
        </div>
      </div>
      <div v-if="closable" :class="$style.close">
        <BaseIcon name="close" size="s" />
      </div>
    </div>
    <div :class="$style.background" />
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false
};
</script>

<script setup lang="ts">
import type { IconName } from '@/components/base-icon/types';
import type { LocationQueryRaw } from 'vue-router';
import { useElementSize } from '@vueuse/core';
import { ref } from 'vue';

type Link =
  | string
  | {
      href?: string;
      routerLink?: LocationQueryRaw;
      text: string;
    };

withDefaults(
  defineProps<{
    closable?: boolean;
    color?: 'primary' | 'success' | 'warning' | 'error';
    icon?: IconName;
    inline?: boolean;
    primaryAction?: Link;
    secondaryAction?: Link;
    size?: 's' | 'm';
    text: string;
  }>(),
  {
    closable: false,
    color: 'primary',
    inline: false,
    size: 'm'
  }
);

defineEmits(['primaryAction', 'secondaryAction']);

const el = ref(null);
const { width } = useElementSize(el);
</script>

<style lang="scss" module>
$closeButtonSizeS: 28px;
$closeButtonSizeM: 34px;

.base {
  position: relative;
  border-radius: $radius;
  background-color: white;
  border: 1px solid;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  color: $color-text;

  &.inline {
    display: inline-block;
  }

  &.primary {
    border-color: $color-primary;
  }

  &.success {
    border-color: $color-success;
  }

  &.warning {
    border-color: $color-warning;
  }

  &.error {
    border-color: $color-error;
  }
}

.inner {
  position: relative;
  z-index: 1;

  .base.s & {
    font-size: 12px;
    padding: 5px $spacing * 0.5 6px;
  }

  .base.s.closable & {
    padding-right: $closeButtonSizeS;
  }

  .base.m & {
    padding: 13px;
  }

  .base.m.closable & {
    padding-right: $closeButtonSizeM;
  }
}

.background {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  opacity: 0.4;

  .base.primary & {
    opacity: 0.25;
    background: linear-gradient(to right, $color-primary-light, $color-primary);
  }

  .base.success & {
    background: linear-gradient(to right, $color-success-light, $color-success);
  }

  .base.warning & {
    background: linear-gradient(to right, $color-warning-light, $color-warning);
  }

  .base.error & {
    background: linear-gradient(to right, $color-error-light, $color-error);
  }
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .base.s & {
    width: $closeButtonSizeS;
    height: $closeButtonSizeS;
  }

  .base.m & {
    width: $closeButtonSizeM;
    height: $closeButtonSizeM;
  }
}

.content {
  .base:not(.alignVertical) & {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.contentLeft {
  display: flex;
  align-items: center;
}

.contentRight {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: $spacing * 0.5 $spacing;

  &:empty {
    display: none;
  }

  .base:not(.alignVertical) & {
    flex-shrink: 0;
    margin-left: $spacing;
  }

  .base.alignVertical.s & {
    margin-top: $spacing * 0.5;
  }

  .base.alignVertical:not(.s) & {
    margin-top: $spacing;
  }
}
</style>
