import apolloClient from '@/apollo/client';
import { useCompanyStore } from '@/stores/company';
import type { Location } from '@/types';
import { DataScope } from '@/types';
import gql from 'graphql-tag';
import { defineStore } from 'pinia';

interface State {
  routeLocationId: string;
  locations: Location[];
  dataScope: DataScope;
}

export const useLocationsStore = defineStore('locations', {
  state: (): State => ({
    routeLocationId: '',
    locations: [],
    dataScope: DataScope.Local
  }),
  getters: {
    locationId(): Location['id'] {
      const { company } = useCompanyStore();
      const primaryLocationId = company.primaryLocationId;
      const routeLocationId = Number.parseInt(this.routeLocationId);
      const isValid =
        routeLocationId &&
        !!this.locations.find((location) => location.id === routeLocationId);

      return isValid ? routeLocationId : primaryLocationId;
    },
    location(): Location | undefined {
      return this.locations.find((location) => location.id === this.locationId);
    },
    locationIds(): Location['id'][] {
      if (!this.locationId) {
        return [];
      }

      const { multiLocation } = useCompanyStore();
      return multiLocation
        ? this.locations.map((location) => location.id)
        : [this.locationId];
    },
    locationById:
      (state) =>
      (id: Location['id']): Location | undefined =>
        state.locations.find((location) => location.id === id)
  },
  actions: {
    getLocations() {
      return new Promise<void>((resolve) => {
        apolloClient
          .query({
            query: gql`
              query getLocations {
                locations {
                  address
                  bankAccount
                  bookable
                  fullAddress
                  id
                  internalName
                  name
                  sortOrder
                  city
                  postalcode
                }
              }
            `
          })
          .then(({ data: { locations } }) => {
            this.locations = locations;
            resolve();
          });
      });
    }
  }
});
