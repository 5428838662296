import config from '@/config';
import { createI18n } from 'vue-i18n';
import en from './translations/en.json';

const defaultLocale = 'en';

const dateTimeFormat = {
  numeric: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  },
  long: {
    day: 'numeric',
    weekday: 'long',
    month: 'long'
  },
  longYear: {
    day: 'numeric',
    weekday: 'long',
    month: 'long',
    year: 'numeric'
  },
  monthYear: {
    month: 'long',
    year: 'numeric'
  },
  monthDay: {
    month: 'short',
    day: 'numeric'
  },
  datePicker: {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  },
  monthLong: {
    month: 'long'
  },
  monthShort: {
    month: 'short'
  },
  weekdayShort: {
    weekday: 'short'
  },
  weekdayLong: {
    weekday: 'long'
  },
  weekdayNarrow: {
    weekday: 'narrow'
  }
};

const messages = {};
const datetimeFormats = {};

if (config.isTest) {
  ['en', 'nl'].forEach((locale) => {
    messages[locale] = en;
    datetimeFormats[locale] = dateTimeFormat;
  });
} else {
  const modules = import.meta.globEager('./translations/*.json');
  const locales = [
    ...config.locales,
    ...config.locales.map((locale) => `${locale}-tw`)
  ];
  locales.forEach((locale) => {
    const mod = modules[`./translations/${locale}.json`];
    if (mod) {
      messages[locale] = mod;
      datetimeFormats[locale] = dateTimeFormat;
    }
  });
}

export const i18n = createI18n({
  locale: defaultLocale,
  messages,
  datetimeFormats,
  silentTranslationWarn: config.isDevelopment,
  legacy: false
});

export default i18n.global;
