import type {
  IncomingCalendar,
  SyncronizationMode,
  UseGoogleCalendar
} from './types';

import { useResourcesStore } from '@/stores/resources';
import { useMutation, useQuery } from '@vue/apollo-composable';
import {
  CREATE_INCOMING_GOOGLE_CALENDAR_MUTATION,
  GET_GOOGLE_CALENDAR,
  GET_INCOMING_GOOGLE_CALENDARS,
  UPDATE_GOOGLE_CALENDAR_APP_MUTATION
} from './graphql';

export const useGoogleCalendar: () => UseGoogleCalendar = () => {
  const { employees } = useResourcesStore();

  const appId = ref();
  const salonizedToGoogleSyncMode = ref<SyncronizationMode>('simple');
  const companyCalendar = ref<string | null>(null);

  const calendars = ref(
    employees.map((employee) => ({
      calendarId: null,
      id: employee.id
    }))
  );

  const initialIncomingCalendars: IncomingCalendar[] = employees.map(
    (employee) => ({
      calendarId: null,
      resourceId: employee.id
    })
  );

  const incomingCalendars = ref<IncomingCalendar[]>([
    {
      calendarId: null,
      resourceId: null
    },
    ...initialIncomingCalendars
  ]);

  const {
    result,
    onResult: onGoogleCalendarsResult,
    loading
  } = useQuery(GET_GOOGLE_CALENDAR);

  onGoogleCalendarsResult(
    ({
      data: {
        googleCalendar: { app }
      }
    }) => {
      appId.value = app.id;
      salonizedToGoogleSyncMode.value = app.settings.mode;
      companyCalendar.value = app.settings.companyCalendar;

      if (app.settings.calendars) {
        Object.keys(app.settings.calendars).forEach((key: string) => {
          const existing = calendars.value.find(
            (cal) => cal.id === Number.parseInt(key)
          );
          if (existing) {
            existing.calendarId = app.settings.calendars[key];
          }
        });
      }
    }
  );

  const { onResult: onIncomingCalendarsResult } = useQuery(
    GET_INCOMING_GOOGLE_CALENDARS
  );

  onIncomingCalendarsResult(({ data: { incomingGoogleCalendars } }) => {
    incomingCalendars.value = incomingCalendars.value.map(
      (incomingCalendar) => {
        const found = incomingGoogleCalendars.find(
          (calendar: any) => calendar.resourceId === incomingCalendar.resourceId
        );
        return found
          ? {
              resourceId: found.resourceId,
              calendarId: found.calendarId
            }
          : incomingCalendar;
      }
    );
  });

  const writableLists = computed(() => {
    if (!result.value?.googleCalendar?.writableLists) {
      return [];
    }

    return result.value.googleCalendar.writableLists.map((list: any) => ({
      value: list.id,
      label: list.summary
    }));
  });

  const unwritableLists = computed(
    () => result.value?.googleCalendar?.unwritableLists || []
  );

  const { mutate: updateGoogleCalendar, loading: savingGoogleCalendar } =
    useMutation(UPDATE_GOOGLE_CALENDAR_APP_MUTATION);
  const {
    mutate: createIncomingGoogleCalendar,
    loading: savingIncomingGoogleCalendar
  } = useMutation(CREATE_INCOMING_GOOGLE_CALENDAR_MUTATION);

  const saving = computed(
    () => savingGoogleCalendar.value || savingIncomingGoogleCalendar.value
  );

  return {
    loading,
    appId,
    salonizedToGoogleSyncMode,
    companyCalendar,
    calendars,
    incomingCalendars,
    writableLists,
    unwritableLists,
    updateGoogleCalendar,
    createIncomingGoogleCalendar,
    saving
  };
};
