import { redirectRoute } from '@/router/helpers';
import { useCompanyStore } from '@/stores/company';

const Admin = () => import('@/modules/admin/index.vue');
const Calendar = () => import('@/modules/admin/calendar/index.vue');
const Company = () => import('@/modules/admin/company/index.vue');
const CompanyCommunication = () =>
  import('@/modules/admin/company-communication/index.vue');
const EmailVerification = () =>
  import('@/modules/admin/company-communication/EmailVerification.vue');
const Customers = () => import('@/modules/admin/customers/index.vue');
const VatRates = () => import('@/modules/admin/vat-rates/index.vue');
const Export = () => import('@/modules/admin/export/index.vue');
const Invoices = () => import('@/modules/admin/invoices/index.vue');
const LoyaltyPoints = () => import('@/modules/admin/loyalty-points/index.vue');
const Referrals = () => import('@/modules/admin/referrals/index.vue');
const OnlineBookings = () =>
  import('@/modules/admin/online-bookings/index.vue');
const GeneralSettings = () =>
  import('@/modules/admin/general-settings/index.vue');
const AdminDashboard = () => import('@/modules/admin/dashboard/index.vue');
const CustomWidget = () =>
  import('@/modules/admin/online-bookings/custom-widget/index.vue');
const Absences = () => import('@/modules/admin/absences/index.vue');
const AdminBilling = () => import('@/modules/admin/billing/index.vue');
const FeedbackWidget = () =>
  import('@/modules/admin/feedback-widget/index.vue');
const PricingWidget = () => import('@/modules/admin/pricing-widget/index.vue');
const MicroSite = () => import('@/modules/admin/micro-site/index.vue');
const GiftcardWidget = () =>
  import('@/modules/admin/giftcard-widget/index.vue');
const Roster = () => import('@/modules/admin/roster/index.vue');
const Employee = () => import('@/modules/admin/resources/modals/Employee.vue');
const Resource = () => import('@/modules/admin/resources/modals/Resource.vue');

import forms from './forms';
import integrations from './integrations';
import locations from './locations';
import resources from './resources';
import services from './services';
import userAccounts from './user-accounts';

export default {
  path: 'admin',
  name: 'admin',
  redirect: {
    name: 'admin-dashboard'
  },
  meta: {
    featureFlag: 'module-admin',
    moduleName: 'admin',
    role: 'manager'
  },
  component: Admin,
  children: [
    {
      path: 'dashboard',
      name: 'admin-dashboard',
      component: AdminDashboard
    },
    {
      path: 'general',
      name: 'admin-general',
      component: GeneralSettings,
      meta: {
        featureFlag: 'admin-settings-general'
      }
    },
    {
      path: 'export',
      name: 'admin-export',
      component: Export,
      meta: {
        featureFlag: 'admin-export'
      }
    },
    {
      path: 'company',
      name: 'admin-company',
      component: Company,
      meta: {
        featureFlag: 'admin-company-info'
      }
    },
    {
      path: 'company-communication',
      name: 'admin-company-communication',
      component: CompanyCommunication,
      meta: {
        featureFlag: 'admin-communication'
      }
    },
    {
      path: 'custom-email-verification',
      name: 'email-verification',
      component: EmailVerification,
      meta: {
        unleash: 'AmazonSES-CustomDomain'
      }
    },
    {
      path: 'absences',
      name: 'admin-absences',
      component: Absences,
      meta: {
        featureFlag: 'admin-absence'
      }
    },
    {
      path: 'communication',
      name: 'admin-communication',
      redirect: {
        name: 'message-templates'
      }
    },
    {
      path: 'roster',
      name: 'admin-roster',
      component: Roster,
      meta: {
        featureFlag: 'admin-roster'
      },
      children: [
        {
          path: 'employee/:id',
          name: 'admin-roster-employee',
          component: Employee,
          props: {
            parentRouteName: 'admin-roster'
          }
        },
        {
          path: 'resource/:id',
          name: 'admin-roster-resource',
          component: Resource,
          props: {
            parentRouteName: 'admin-roster'
          }
        }
      ]
    },
    {
      path: 'calendar',
      name: 'admin-calendar',
      component: Calendar,
      meta: {
        featureFlag: 'admin-settings-appointments'
      }
    },
    {
      path: 'customers',
      name: 'admin-customers',
      component: Customers,
      meta: {
        featureFlag: 'admin-settings-customers'
      }
    },
    {
      path: 'invoices',
      name: 'admin-invoices',
      component: Invoices,
      meta: {
        featureFlag: 'admin-settings-invoice'
      }
    },
    {
      path: 'vat-rates',
      name: 'admin-vat-rates',
      component: VatRates,
      meta: {
        featureFlag: 'admin-taxes'
      },
      children: [
        {
          path: 'new',
          name: 'admin-vat-rates-new',
          component: {
            template: '<template />'
          }
        }
      ]
    },
    {
      path: 'loyalty-points',
      name: 'admin-loyalty-points',
      component: LoyaltyPoints,
      meta: {
        featureFlag: 'admin-settings-loyalty-points'
      }
    },
    {
      path: 'billing',
      name: 'admin-billing',
      component: AdminBilling,
      beforeEnter: (to) => {
        const { isTreatwellUser } = useCompanyStore();

        if (isTreatwellUser) {
          return redirectRoute({
            name: 'dashboard',
            query: to.query
          });
        }
      }
    },
    {
      path: 'referrals',
      name: 'admin-referrals',
      component: Referrals,

      beforeEnter: (to) => {
        const { isMarketLaunchCountry } = useCompanyStore();

        if (isMarketLaunchCountry) {
          return redirectRoute({
            name: 'dashboard',
            query: to.query
          });
        }
      }
    },
    {
      path: 'sms-credits',
      name: 'admin-sms-credits',
      redirect: {
        name: 'messages-sms'
      },
      meta: {
        featureFlag: 'module-admin'
      }
    },
    {
      path: 'online-bookings',
      name: 'admin-online-bookings',
      component: OnlineBookings,
      meta: {
        hideIntercomButton: true,
        mixpanelName: 'bookingWidgetStyle',
        featureFlag: 'admin-settings-bookings'
      },
      children: [
        {
          path: 'custom-widget',
          name: 'create-custom-widget',
          component: CustomWidget,
          props: {
            parentRouteName: 'admin-online-bookings'
          }
        }
      ]
    },
    {
      path: 'feedback-widget',
      name: 'admin-feedback-widget',
      component: FeedbackWidget,
      meta: {
        featureFlag: 'module-admin'
      }
    },
    {
      path: 'micro-site',
      name: 'admin-micro-site',
      component: MicroSite,
      meta: {
        featureFlag: 'module-admin'
      }
    },
    {
      path: 'pricing-widget',
      name: 'admin-pricing-widget',
      component: PricingWidget,
      meta: {
        featureFlag: 'module-admin'
      }
    },
    {
      path: 'voucher-widget',
      name: 'admin-giftcard-widget',
      component: GiftcardWidget,
      meta: {
        featureFlag: 'module-register'
      }
    },
    ...userAccounts,
    ...services,
    ...resources,
    ...forms,
    ...locations,
    integrations
  ]
};
