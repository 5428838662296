import { AdyenAppVerificationStatus } from '@/types';
import type { AdyenAppState, PosTerminalStatus } from '@/types';
import { useQuery, useSubscription } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { defineStore } from 'pinia';
import { reactive } from 'vue';
import { useLocationsStore } from './locations';

export const useAdyenStore = defineStore('adyen', () => {
  type ExtendedAdyenAppState = Omit<AdyenAppState, 'id'> & { loading: boolean };

  const adyenAppState = reactive<ExtendedAdyenAppState>({
    adyenLegalEntityId: '',
    createdAt: '',
    industryCode: '',
    loading: false,
    terminalModel: undefined,
    verificationModalDismissedAt: '',
    verificationStatus: AdyenAppVerificationStatus.Pending
  });

  const terminals = ref<
    {
      id: string;
      location: {
        id: number;
      };
      name: string | null;
      status: PosTerminalStatus;
      terminalUid: string;
    }[]
  >([]);

  const locationTerminals = computed(() => {
    const { locationId } = useLocationsStore();
    const connectedTerminals = terminals.value.filter(
      (terminal) => terminal.status === 'CONNECTED'
    );
    return connectedTerminals.filter(
      (terminal) => terminal.location?.id === locationId
    );
  });

  const setData = (data: AdyenAppState) => {
    Object.assign(adyenAppState, data);
  };

  const adyenAppStateLoaded = ref(false);

  const { onResult, refetch: refetchAdyenAppState } = useQuery(gql`
    query getAdyenAppState {
      adyenAppState {
        adyenLegalEntityId
        createdAt
        industryCode
        terminalModel
        verificationModalDismissedAt
        verificationStatus
      }
    }
  `);

  onResult(({ data: { adyenAppState } }) => {
    setData(adyenAppState);

    if (
      adyenAppState?.verificationStatus === 'VALID' ||
      adyenAppState?.verificationStatus === 'INVALID'
    ) {
      const { onResult: onTerminalsResult } = useQuery(
        gql`
          query getPosTerminals {
            posTerminals {
              id
              location {
                id
              }
              name
              status
              terminalUid
            }
          }
        `,
        null,
        {
          fetchPolicy: 'cache-first'
        }
      );

      onTerminalsResult(({ data: { posTerminals } }) => {
        terminals.value = posTerminals;
      });
    }

    adyenAppStateLoaded.value = true;
  });

  const startAdyenVerificationSubscription = () => {
    const { onResult } = useSubscription(gql`
      subscription adyenVerificationStatus {
        adyenVerificationStatus {
          verificationStatus
          terminalModel
          adyenLegalEntityId
        }
      }
    `);

    onResult(({ data: { adyenVerificationStatus } }) => {
      if (adyenVerificationStatus) {
        setData(adyenVerificationStatus);
      }
    });
  };

  return {
    startAdyenVerificationSubscription,
    refetchAdyenAppState,
    adyenAppState,
    terminals,
    locationTerminals,
    adyenAppStateLoaded
  };
});
