<template>
  <div :class="$style.base">
    <router-view />

    <BaseTableRow head>
      <BaseTableCell :width="40">
        {{ $t('global.name') }}
      </BaseTableCell>
      <BaseTableCell :width="60">
        {{ $t('marketing.filter_groups.filters_used') }}
      </BaseTableCell>
      <div :class="$style.showMore">
        <BaseTableCell />
      </div>
    </BaseTableRow>
    <BaseTableRow
      v-for="(item, index) in items"
      :key="`row-${index}`"
      :options="options"
      zebra
      @optionClick="onOptionClick(item, $event)"
      v-test="'baseTableRow'"
    >
      <BaseTableCell wordBreak :width="40">
        {{ item.name }}
      </BaseTableCell>
      <BaseTableCell :width="60">
        {{ item.filters.length }}
      </BaseTableCell>
    </BaseTableRow>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { modal, flash } from '@/helpers/ui';
import { formatFilters } from '@/helpers/formatting';
import gql from 'graphql-tag';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    BaseTableRow,
    BaseTableCell
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  },
  computed: {
    options() {
      const options = [
        {
          name: 'edit',
          icon: 'edit',
          label: this.$t('global.actions.edit')
        },
        {
          name: 'email',
          icon: 'email',
          label: this.$t('global.actions.email')
        },
        {
          name: 'delete',
          icon: 'delete',
          label: this.$t('global.actions.delete')
        }
      ];

      return options;
    }
  },
  methods: {
    deleteFilterGroup(id) {
      const input = { id };
      const text = this.$t('global.flash.filter_group_deleted');

      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteCustomerFilterGroup(
              $input: DeleteCustomerFilterGroupInput!
            ) {
              deleteCustomerFilterGroup(input: $input) {
                customerFilterGroup {
                  id
                }
              }
            }
          `,
          variables: {
            input
          },
          update(cache) {
            const cacheId = `CustomerFilterGroup:${id}`;
            cache.evict({ id: cacheId, broadcast: false });
            cache.gc();
          }
        })
        .finally(() => {
          flash(text);
        });
    },
    onOptionClick(item, option) {
      if (option === 'edit') {
        this.$router.push({
          name: 'edit-filter-group',
          params: { filterGroupId: item.id }
        });
      }

      if (option === 'email') {
        this.$store.commit(
          'marketing/SET_ALL_FILTERS',
          formatFilters(item.filters)
        );
        this.$router.push({ name: 'newsletter-template' });
      }

      if (option === 'delete') {
        modal('confirmation', {
          type: 'delete',
          item: this.$t('global.items.filter_group')
        }).then(() => {
          this.deleteFilterGroup(Number.parseInt(item.id));
        });
      }
    }
  }
});
</script>

<style lang="scss" module>
.base {
  overflow-x: auto;
  min-height: 70vh;
}

.showMore {
  width: 52px !important;
  flex: none !important;
}
</style>
