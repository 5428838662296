import { ref } from 'vue';

import dayjs from '@/dayjs';
import { modal } from '@/helpers/ui';
import i18n from '@/i18n';

import { updateAbsence } from '../../actions/absences';
import {
  rescheduleAppointment,
  resizeAppointment
} from '../../actions/appointments';
import { checkAvailability } from '../../actions/availabilities';
import { updateNote } from '../../actions/notes';

import { useCreateEventStore } from '@/stores/calendar-create-event';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import { useCompanyStore } from '@/stores/company';
import { useResourcesStore } from '@/stores/resources';

import {
  setRruleBymonthday,
  setRruleMonthlyByday
} from '@/modules/calendar/helpers';

const isDragging = ref(false);
const isResizing = ref(false);

export const useDragResize = () => ({
  isDragging,
  isResizing
});

const isResourceAllowed = (event: any, calendarResource: any) => {
  const { multiLocation } = useCompanyStore();
  if (!multiLocation || event.extendedProps.type !== 'appointment') {
    return true;
  }

  const { resourceById } = useResourcesStore();
  const resource = resourceById(Number.parseInt(calendarResource.id));

  if (!resource) {
    return true;
  }

  return resource.locationIds.includes(event.extendedProps.locationId);
};

const calendarEventToAppointmentParams = (event: any): any => ({
  // You can only use a single part for these operations
  appointmentId: event.extendedProps.entryId,
  startAt: event.startStr,
  parts: [
    {
      id: event.extendedProps.partId,
      duration:
        event.startStr && event.endStr
          ? dayjs(event.endStr).diff(event.startStr, 'minute')
          : event.extendedProps.totalDuration,
      resourceIds: event.extendedProps.resourceIds
    }
  ]
});

const updateRrule = (event: any, oldEvent: any) => {
  const rrule = { ...event.extendedProps.rrule };

  if (rrule.freq === 'weekly') {
    const dayIndex = rrule.byday.indexOf(dayjs(oldEvent.startStr).tz().day());
    rrule.byday[dayIndex] = dayjs(event.startStr).tz().day();
  } else if (rrule.freq === 'monthly') {
    if (rrule.bymonthday) {
      rrule.bymonthday = setRruleBymonthday(event.startStr);
    } else if (rrule.byday?.length) {
      rrule.byday = setRruleMonthlyByday(event.startStr);
    }
  }

  return rrule;
};

const checkEventForNewResource = (info: any, view: any) => {
  let newResourceId;
  const extendedProps = { ...info.event.extendedProps };
  const infoClone = {
    event: {
      extendedProps,
      startStr: info.event.startStr,
      endStr: info.event.endStr
    }
  };

  if (info.newResource && view === 'DAY') {
    newResourceId = Number.parseInt(info.newResource.id);
    infoClone.event.extendedProps.resourceIds = newResourceId;
  }

  return infoClone;
};

const checkAvailabilityForEvent = (event: any, action: any) => {
  if (event.extendedProps.type === 'appointment') {
    const appointment = calendarEventToAppointmentParams(event);
    appointment.action = action;
    return checkAvailability(appointment);
  } else {
    return new Promise((resolve) => {
      resolve(true);
    });
  }
};

const updateEvent = ({
  event,
  newResource,
  oldResource,
  oldEvent,
  action
}: any): Promise<any> => {
  let storeAction: string | undefined;

  const payload = {
    input: {
      id: event.extendedProps.entryId
    }
  } as any;

  switch (event.extendedProps.type) {
    case 'absence':
      storeAction = 'updateAbsence';
      payload.input.startAt = event.startStr;
      payload.input.allDay = event.allDay;
      if (event.allDay && event.end) {
        // When the absence is allDay, Fullcalendar returns the next day as the endStr
        payload.input.endAt = dayjs
          .tz(event.end)
          .subtract(1, 'day')
          .format('YYYY-MM-DD');
      } else if (event.endStr) {
        payload.input.endAt = event.endStr;
      } else {
        // For some reason Fullcalendar doesn't give end values to recurring absences
        payload.input.endAt = dayjs(event.startStr)
          .add(event.extendedProps.totalDuration, 'minute')
          .tz()
          .format();
      }
      if (newResource) {
        payload.input.resourceId = Number.parseInt(newResource.id);
      }
      payload.isChore = event.extendedProps.chore;
      break;
    case 'note':
      storeAction = 'updateNote';
      payload.input.date = event.startStr;
      if (newResource) {
        payload.input.resourceId = Number.parseInt(newResource.id);
      }

      break;
    case 'appointment':
      payload.input.startAt = event.startStr;

      if (oldEvent) {
        payload.previousTime = oldEvent.startStr;
      }

      if (action === 'resize') {
        storeAction = 'resizeAppointment';
        payload.input.endAt = event.endStr;
      } else if (action === 'dragdrop') {
        storeAction = 'rescheduleAppointment';
        if (oldResource && newResource) {
          payload.input.oldResourceId = Number.parseInt(oldResource.id);
          payload.input.newResourceId = Number.parseInt(newResource.id);
        }
      }
      break;
  }

  if (event.extendedProps.rrule) {
    payload.original = dayjs(oldEvent.startStr)
      .tz()
      .isSame(event.extendedProps.originalStartAt, 'day');
    payload.input.recurrenceStartAt = oldEvent.startStr;
    if (
      storeAction === 'resizeAppointment' ||
      storeAction === 'rescheduleAppointment'
    ) {
      payload.rrule = updateRrule(event, oldEvent);
    } else {
      payload.input.rrule = updateRrule(event, oldEvent);
    }
  }

  const actions: any = {
    updateAbsence,
    updateNote,
    resizeAppointment,
    rescheduleAppointment
  };

  return new Promise((resolve, reject) => {
    if (storeAction === 'updateNote') {
      updateNote(payload.input);
    } else if (storeAction) {
      actions[storeAction](payload)
        .then((appointment: any) => {
          resolve(appointment);
        })
        .catch((errors: any) => reject(errors));
    }
  });
};

export const calendarOptions = {
  eventDragStart: () => {
    isDragging.value = true;
  },
  eventDragStop: () => {
    isDragging.value = false;
  },
  eventResizeStart: () => {
    isResizing.value = true;
  },
  eventResizeStop: () => {
    isResizing.value = false;
  },
  eventDrop: (info: any) => {
    const { event, oldEvent, newResource, oldResource } = info;

    if (event.extendedProps.preview) {
      const { setEventData } = useCreateEventStore();
      setEventData(
        {
          startDate: event.startStr,
          endDate: event.endStr,
          resourceId: newResource ? Number.parseInt(newResource.id) : undefined,
          isAbsence: event.extendedProps.type === 'absence'
        },
        {
          action: 'drag'
        }
      );
      return;
    }

    if (newResource && !isResourceAllowed(oldEvent, newResource)) {
      // Resource is not allowed to be assigned to the event
      // For example when they don't work at the location of the event

      modal('warning', {
        message: i18n.t('calendar.resource_notallowed.location')
      });
      info.revert();
      return;
    }

    const action = 'dragdrop';
    const { viewType } = useCalendarFiltersStore();
    const checkedInfo = checkEventForNewResource(info, viewType);

    checkAvailabilityForEvent(checkedInfo.event, action).then(
      (continueWithUpdate) => {
        if (continueWithUpdate) {
          updateEvent({
            event,
            newResource,
            oldResource,
            oldEvent,
            action
          }).catch(() => {
            info.revert();
          });
        } else {
          info.revert();
        }
      }
    );
  },

  eventResize: ({ event, oldEvent, revert }: any) => {
    if (event.extendedProps.preview) {
      const { setEventData } = useCreateEventStore();
      setEventData(
        {
          startDate: event.startStr,
          endDate: event.endStr,
          isAbsence: event.extendedProps.type === 'absence'
        },
        {
          action: 'resize'
        }
      );
      return;
    }

    const action = 'resize';
    checkAvailabilityForEvent(event, action).then((continueWithUpdate) => {
      if (continueWithUpdate) {
        updateEvent({
          event,
          oldEvent,
          action
        })
          .then((appointment) => {
            if (appointment?.duration) {
              event.setExtendedProp('totalDuration', appointment.duration);
            }
          })
          .catch((errors) => {
            if (errors?.duration) {
              modal('warning', {
                message: i18n.t('calendar.appointment_resize_error_duration')
              });
            }
            revert();
          });
      } else {
        revert();
      }
    });
  }
};
