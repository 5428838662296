<template>
  <div :class="$style.base" v-test="'stripe-checkout-flow-modal'">
    <div :class="$style.header">
      <div :class="$style.headerTop">
        <BaseHeading>{{
          $t(
            canUpgradeSubscription
              ? 'subscription.steps.stripe_upgrade'
              : 'subscription.steps.stripe_checkout'
          )
        }}</BaseHeading>
        <BaseSwitch
          v-if="!isMarketLaunchCountry"
          v-model="selectedPeriod"
          :options="[
            {
              value: SubscriptionPeriod.Month,
              label: $t('subscription.payment.monthly')
            },
            {
              value: SubscriptionPeriod.Year,
              label: $t('subscription.payment.yearly'),
              info: {
                label: '-10%',
                color: 'green'
              }
            }
          ]"
          v-test="'subscription-frequency'"
        />
      </div>
      <BaseText
        v-if="isTrialExpired"
        iconBefore="logout"
        link
        @click="onLogout"
        v-test="'stripe-subscription-logout'"
      >
        {{ $t('nav.logout') }}
      </BaseText>
    </div>
    <div v-if="loadingPrices">
      <BaseSpinner />
    </div>
    <div v-else>
      <StripeCheckoutPlan
        v-for="plan in plans"
        :key="plan.plan"
        :plan="plan"
        :disabled="
          currentPlan &&
          currentPlan.type === plan.plan.toLocaleLowerCase() &&
          stripeStatus === StripeSubscriptionStatus.Active
        "
        :highlighted="!isSubscribed && plan.plan === SubscriptionPlan.Pro"
        :selected="plan.plan === selectedPlan"
        :showAllFeatures="showAllFeatures"
        @select="handlePlanSelected(plan.plan)"
      />
      <BaseText
        :iconAfter="showAllFeatures ? 'minus' : 'plus'"
        link
        @click="toggleFeatures"
        v-test="'subscription-show-all-features'"
      >
        {{
          $t(
            showAllFeatures
              ? 'subscription.show_main_features'
              : 'subscription.show_all_features'
          )
        }}
      </BaseText>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, inject } from 'vue';
import StripeCheckoutPlan from '@/modules/dashboard/_shared/CheckoutPlan.vue';
import { useSubscriptionStore } from '../store';
import { useCompanyStore } from '@/stores/company';
import { logout } from '@/helpers/session';
import { useStripeStore } from '../stripe/store';
import { storeToRefs } from 'pinia';
// biome-ignore lint/style/useImportType:
import {
  StripeSubscriptionStatus,
  SubscriptionPlan,
  SubscriptionPeriod
} from '@/types';

const showAllFeatures = ref(false);

const { selectedPlan, stripePricesPerPeriod, loadingPrices, selectedPeriod } =
  storeToRefs(useStripeStore());
const subscriptionStore = useSubscriptionStore();
const {
  isSubscribed,
  isTrialExpired,
  stripeStatus,
  canUpgradeSubscription,
  isMarketLaunchCountry
} = useCompanyStore();
const mixpanel = inject<any>('mixpanel');

subscriptionStore.getData();

const plans = computed(() =>
  stripePricesPerPeriod.value?.plans
    ? [...stripePricesPerPeriod.value.plans].sort(
        (a, b) => b.prices.base[0].unitAmount - a.prices.base[0].unitAmount
      )
    : []
);
const currentPlan = computed(() =>
  subscriptionStore.plansData.find((plan) => plan.current)
);
const handlePlanSelected = (plan: SubscriptionPlan) => {
  selectedPlan.value = plan;
  subscriptionStore.formData.plan = plan.toLowerCase();
};

const onLogout = () => {
  mixpanel.track('Subscription flow - logout');
  logout();
};

const toggleFeatures = () => (showAllFeatures.value = !showAllFeatures.value);
</script>

<style lang="scss" module>
.base {
  padding: $spacing * 1.5;

  &.smallScreen {
    padding: $spacing;
  }
}

.header {
  margin-bottom: $spacing * 2;
}

.headerTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: $spacing;
}
</style>
