import { useCompanyStore } from '@/stores/company';
import { useServicesStore } from '@/stores/services';
import type { CompanyPublicLanguage } from '@/types';
import { storeToRefs } from 'pinia';
import { provide, reactive, watch } from 'vue';

export const useBookingSettingsData = () => {
  const { company, companySettings } = useCompanyStore();

  const getDefaultColor = (primaryColor: string) => {
    switch (primaryColor) {
      case 'main':
        return '#FF6575';
      case 'dark':
        return '#404043';
      default:
        return companySettings.bookingWidget.customColor;
    }
  };

  const formData = reactive({
    languagePublic: company.languagePublic as CompanyPublicLanguage,
    settings: {
      agenda: {
        appointmentInterval: companySettings.agenda.appointmentInterval
      },
      bookings: {
        addressFields: companySettings.bookings.addressFields,
        allowMultipleServices: companySettings.bookings.allowMultipleServices,
        allowNewCustomers: companySettings.bookings.allowNewCustomers,
        onlyExistingCustomersResourceIds:
          companySettings.bookings.onlyExistingCustomersResourceIds,
        appointmentRequiresConfirmation:
          !!companySettings.bookings.appointmentRequiresConfirmation,
        appointmentRequestNewCustomers:
          !!companySettings.bookings.appointmentRequestNewCustomers,
        appointmentRequestDurationMinimum:
          !!companySettings.bookings.appointmentRequestDurationMinimum,
        appointmentRequestDurationMinimumValue:
          companySettings.bookings.appointmentRequestDurationMinimumValue,
        appointmentRequestPriceMinimum:
          companySettings.bookings.appointmentRequestPriceMinimum,
        appointmentRequestPriceMinimumValue:
          companySettings.bookings.appointmentRequestPriceMinimumValue,
        customAlert: companySettings.bookings.customAlert,
        customCustomerStopMessage:
          companySettings.bookings.customCustomerStopMessage,
        dateOfBirth: companySettings.bookings.dateOfBirth,
        employeeSelectionFirst: companySettings.bookings.employeeSelectionFirst,
        leadTime: companySettings.bookings.leadTime,
        maxTimeInAdvance: companySettings.bookings.maxTimeInAdvance,
        giftcardPaymentsAllowed:
          companySettings.bookings.giftcardPaymentsAllowed,
        onlinePaymentAll: companySettings.bookings.onlinePaymentAll,
        onlinePayment: companySettings.bookings.onlinePayment,
        onlinePaymentCharge: companySettings.bookings.onlinePaymentCharge || 0,
        onlinePaymentMinimumDuration:
          companySettings.bookings.onlinePaymentMinimumDuration,
        onlinePaymentMinimumDurationValue:
          companySettings.bookings.onlinePaymentMinimumDurationValue || 0,
        onlinePaymentMinimumPrice:
          companySettings.bookings.onlinePaymentMinimumPrice,
        onlinePaymentMinimumPriceValue:
          companySettings.bookings.onlinePaymentMinimumPriceValue || 0,
        onlinePaymentPercentage:
          companySettings.bookings.onlinePaymentPercentage || 0,
        prepaymentsForNewCustomersOnly:
          companySettings.bookings.prepaymentsForNewCustomersOnly,
        preventGaps: companySettings.bookings.preventGaps,
        resourceSelection: companySettings.bookings.resourceSelection,
        showCustomAlert: companySettings.bookings.showCustomAlert,
        showNewsletterOptin: companySettings.bookings.showNewsletterOptin,
        showServicePrices: companySettings.bookings.showServicePrices,
        showServiceDuration: companySettings.bookings.showServiceDuration,
        smsNotification: companySettings.bookings.smsNotification,
        privacyStatementUrl: companySettings.bookings.privacyStatementUrl || '',
        termsConditionsUrl: companySettings.bookings.termsConditionsUrl || '',
        topServicesIds: companySettings.bookings.topServicesIds || [],
        topServicesEnabled:
          companySettings.bookings.topServicesEnabled || false,
        waitingListEnabled: companySettings.bookings.waitingListEnabled
      },
      bookingWidget: {
        allowMonthView: companySettings.bookingWidget.allowMonthView,
        appointmentOverviewRequest:
          companySettings.bookingWidget.appointmentOverviewRequest,
        autoWidth: companySettings.bookingWidget.autoWidth,
        buttonSize: companySettings.bookingWidget.buttonSize,
        customColor: getDefaultColor(
          companySettings.bookingWidget.primaryColor
        ),
        height: companySettings.bookingWidget.height,
        inline: companySettings.bookingWidget.inline,
        outline: companySettings.bookingWidget.outline,
        position: companySettings.bookingWidget.position,
        primaryColor: 'custom', // Once saved we overwrite this and set custom color to whatever default they had
        width: companySettings.bookingWidget.width
      }
    }
  });

  // Filter out unbookable or deleted services if the backend returns them
  // Add watcher to services so it triggers once all services are loaded
  const { services } = storeToRefs(useServicesStore());
  watch(
    services,
    () => {
      if (!services.value.length) {
        return;
      }

      formData.settings.bookings.topServicesIds =
        formData.settings.bookings.topServicesIds.filter((serviceId) => {
          const { serviceById } = useServicesStore();
          const service = serviceById(serviceId);
          return service ? service.bookable && !service.deleted : false;
        });
    },
    {
      immediate: true
    }
  );

  provide('bookingSettingsData', formData);

  return {
    formData
  };
};
