import { datadogRum } from '@datadog/browser-rum';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { RouteLocationNormalized } from 'vue-router';

import { useCompanyStore } from '@/stores/company';
import { useLocationsStore } from '@/stores/locations';
import { useResourcesStore } from '@/stores/resources';
import { useServicesStore } from '@/stores/services';
import { useTreatwellStore } from '@/stores/treatwell';
import { useUserStore } from '@/stores/user';
import { useVatRatesStore, vatRatesFetched } from '@/stores/vat-rates';

import unleash from '@/unleash';
import { useServiceGroupsStore } from './service-groups';

export const useSessionStore = defineStore('session', () => {
  const initialRoute = ref<RouteLocationNormalized | null>(null);
  const requiredDataLoaded = ref(false);
  const allDataLoaded = ref(false);

  const fetchAllData = async () =>
    new Promise<void>((resolve) => {
      const { getUser } = useUserStore();
      getUser().then(() => {
        const { user } = useUserStore();

        if (!user || !user.verified) {
          requiredDataLoaded.value = true;
          resolve();
          return;
        }

        const { getResources } = useResourcesStore();
        const { getLocations } = useLocationsStore();
        const { getTreatwellStatus } = useTreatwellStore();
        const { getServices, getServiceCategories } = useServicesStore();
        const { getServiceGroups } = useServiceGroupsStore();

        Promise.all([
          getResources(),
          getLocations(),
          getTreatwellStatus(),
          startUnleash()
        ]).then(() => {
          resolve();
          requiredDataLoaded.value = true;

          // These requests can be done after resolving the promise
          // They will run in the background when the app is already rendered
          Promise.all([
            getServices(),
            getServiceCategories(),
            getServiceGroups()
          ]).then(() => {
            allDataLoaded.value = true;
          });
        });
      });
    });

  const startUnleash = () =>
    new Promise<void>((resolve) => {
      const { user } = useUserStore();
      const { company } = useCompanyStore();

      // Set Unleash context data
      unleash.updateContext({
        userId: String(user?.id),
        properties: company.unleashContextProperties
      });

      const timeout = setTimeout(() => {
        // Make sure that the promise resolves if Unleash doesn't load for whatever reason
        // So that it doesn't block loading the entire application
        resolve();
      }, 2000);

      // The ready event triggers when the first Unleash request finishes
      unleash.on('ready', () => {
        clearTimeout(timeout);
        resolve();
      });

      // When Unleash errors, just continue
      unleash.on('error', () => {
        clearTimeout(timeout);
        resolve();
      });

      // Start Unleash, which will trigger an initial request
      // Make sure this gets done after registering the event listeners
      unleash.start();
    });

  const resetData = () => {
    const stores = [
      useUserStore(),
      useCompanyStore(),
      useLocationsStore(),
      useResourcesStore(),
      useServicesStore()
    ];

    stores.forEach((store) => {
      store.$reset();
    });

    if (vatRatesFetched.value) {
      const { reset } = useVatRatesStore();
      reset();
    }

    datadogRum.removeUser();

    allDataLoaded.value = false;
  };

  return {
    initialRoute,
    requiredDataLoaded,
    allDataLoaded,
    fetchAllData,
    startUnleash,
    resetData
  };
});
