import config from '@/config';
import { devLog } from '@/helpers/dev';
import mixpanel from 'mixpanel-browser';

// Declare it in the window for the e2e tests
declare global {
  interface Window {
    mixpanel: any;
  }
}

export default {
  install: (app: any, { token }: { token: string }) => {
    if (token) {
      mixpanel.init(token);
    }

    // Toggling this variable to prevent additional events being sent when the user double clicks
    let lastTrackedEvent = null;

    const mixpanelTracker = {
      track(event: string, variables: Record<string, unknown>) {
        devLog(`Track event: ${event}`);

        if (!token || lastTrackedEvent === event || !event) {
          return;
        }

        mixpanel.track(event, variables);

        lastTrackedEvent = event;
        setTimeout(() => {
          lastTrackedEvent = null;
        }, 500);
      },
      register(data: any) {
        if (!token) {
          return;
        }

        mixpanel.register(data);
      },
      identify(id: string) {
        devLog(`Identifying mixpanel user: ${id}`);

        if (!token) {
          return;
        }

        mixpanel.identify(id);
      }
    };

    app.provide('mixpanel', mixpanelTracker);

    if (config.isTest) {
      window.mixpanel = mixpanelTracker;
    }
  }
};
