<template>
  <BaseModal
    :heading="$t('global.actions.delete_supplier')"
    small
    parentRoute="products-suppliers"
  >
    <div>
      <BaseText mb>
        {{ $t('products.delete_title') }}
      </BaseText>

      <BaseRadio
        v-model="selectedValue"
        :options="options"
        mb
        v-test="'delete-supplier-options'"
      />
      <div v-if="allSelected">
        <BaseAlert
          v-if="allSelected"
          mb
          color="warning"
          :text="
            $t('products.supplier_warning', { amount: $route.query.products })
          "
        />
        <BaseCheckbox
          v-model="accepted"
          :label="$t('products.aware_supplier')"
          v-test="'acceptDelete'"
        />
      </div>
    </div>
    <template #footer>
      <BaseButton
        :disabled="allSelected && !accepted"
        :loading="isLoading"
        @click="onDelete"
        v-test="'deleteSupplierOnDelete'"
      >
        {{ $t('global.actions.delete') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import gql from 'graphql-tag';

import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      isLoading: false,
      accepted: false,
      selectedValue: 'supplier',
      options: [
        {
          label: this.$t('products.only_supplier'),
          value: 'supplier'
        },
        {
          label: this.$t('products.supplier_and'),
          value: 'supplier_and'
        }
      ]
    };
  },
  computed: {
    allSelected() {
      return this.selectedValue === 'supplier_and';
    },
    supplierId() {
      return Number.parseInt(this.$route.params.id);
    }
  },
  methods: {
    onDelete() {
      this.isLoading = true;
      const id = this.supplierId;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteSupplier($input: DeleteSupplierInput!) {
              deleteSupplier(input: $input) {
                supplier {
                  id
                }
              }
            }
          `,
          variables: {
            input: {
              id,
              deleteProducts: this.allSelected
            }
          },
          update(cache) {
            const cacheId = `Supplier:${id}`;
            cache.evict({ id: cacheId, broadcast: false });
            cache.gc();
          }
        })
        .then(() => {
          this.$router.push({ name: 'products-suppliers' });
          flash(this.$t('global.flash.supplier_deleted'));
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
});
</script>
