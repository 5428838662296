<template>
  <BaseModalLarge
    :routes="routes"
    :details="!processRoute ? details : null"
    :label="stockOrder ? stockOrder.status : ''"
    :heading="processRoute ? heading : null"
    :actions="actions"
    parentRoute="products-stock-orders"
    @action="onActionClick"
    v-test="'stockZoom'"
  >
    <CreateNoteModal
      v-if="showNoteModal"
      :currentNote="stockOrder.notes"
      @submit="updateNote($event, 'create')"
      @close="showNoteModal = false"
    />
    <div :class="$style.inner">
      <Details
        v-if="$route.name === 'stock-details'"
        :stockOrder="stockOrder"
        :totalAmount="totalAmount"
      />
      <History v-if="$route.name === 'stock-history'" />
      <Process
        v-if="processRoute"
        :status="stockOrder ? stockOrder.status : ''"
        :processItems="processItems"
        @updateQuantityReceived="updateQuantityReceived"
      />
    </div>
    <template v-if="$route.name === 'stock-process'" #footer>
      <ProcessActions :processItems="processItems" />
    </template>
  </BaseModalLarge>
</template>

<script lang="ts">
import filters from '@/filters';
import Details from './Details.vue';
import History from './History.vue';
import Process from './Process.vue';
import ProcessActions from './ProcessActions.vue';
import gql from 'graphql-tag';
import UpdateNoteMixin from './UpdateNoteMixin';
import CreateNoteModal from '@/modals/CreateNote.vue';
import { mapState } from 'pinia';
import { useCompanyStore } from '@/stores/company';
import config from '@/config';
import { defineComponent } from 'vue';
import type { StockOrderItem } from '@/types';

export default defineComponent({
  components: {
    Details,
    History,
    Process,
    ProcessActions,
    CreateNoteModal
  },
  mixins: [UpdateNoteMixin],
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      stockOrder: null,
      status: '',
      heading: '',
      showNoteModal: false,
      processItems: [] as StockOrderItem[]
    };
  },
  watch: {
    stockOrder: {
      handler() {
        if (this.stockOrder?.status) {
          this.status = this.stockOrder.status;
        }

        if (
          this.stockOrder?.cid &&
          this.stockOrder.supplier &&
          this.stockOrder.supplier.name
        ) {
          this.heading = this.$t('products.order_name', {
            cid: this.stockOrder.cid,
            supplier: this.stockOrder.supplier.name
          });
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState(useCompanyStore, ['company']),
    routes() {
      return [
        {
          name: 'stock-details',
          label: this.$t('global.order'),
          heading: this.heading
        }
      ];
    },
    actions() {
      return !this.processRoute && this.stockOrder
        ? [
            {
              name: 'edit',
              icon: 'edit',
              label: this.$t('global.actions.edit'),
              hide: this.isPaidCompleteOrBackOrder
            },
            {
              name: 'add-note',
              icon: 'attach',
              hide: !!this.stockOrder.notes,
              label: this.filters.capitalize(this.$t('global.actions.add_note'))
            },
            {
              name: 'export-csv',
              icon: 'file',
              label: 'CSV',
              href: `${config.backendUrl}/api/stock_orders/download.csv?id=${this.stockOrder.id}`
            },
            {
              name: 'export-pdf',
              icon: 'pdf',
              label: this.$t('global.pdf'),
              href: `${config.backendUrl}/stock_orders/${this.stockOrder.id}`
            }
          ].filter((item) => !item.hide)
        : [];
    },
    details() {
      let companyDetails = this.company.name;

      if (this.company.address && this.company.city) {
        companyDetails += `\n${this.company.address}, ${this.company.city}`;
      }

      const details = [
        {
          label: this.$t('products.ordered_for'),
          value: companyDetails,
          type: 'html'
        }
      ];

      if (this.stockOrder) {
        details.push(
          {
            label: this.$t('global.total'),
            value: this.filters.currency(this.totalAmount)
          },
          {
            label: this.$t('global.created'),
            value: this.filters.dateTime(this.stockOrder.createdAt)
          },
          {
            label: this.$t('global.ordered'),
            value: this.filters.dateTime(this.stockOrder.orderedAt)
          }
        );
      }
      return details;
    },
    totalAmount() {
      if (this.stockOrder?.orderItems?.length) {
        const totalAmount = this.stockOrder.orderItems
          .map((item) => item.total)
          .reduce((a, b) => a + b);
        return totalAmount;
      } else {
        return 0;
      }
    },
    processRoute() {
      return this.$route.name === 'stock-process';
    },
    isPaidCompleteOrBackOrder() {
      if (this.stockOrder) {
        return (
          this.stockOrder.status === 'paid' ||
          this.stockOrder.status === 'complete' ||
          this.stockOrder.status === 'backorder'
        );
      }

      return null;
    }
  },
  methods: {
    onActionClick(action: string) {
      if (action === 'edit') {
        this.$router.push({ name: 'products-order-build' });
      } else if (action === 'add-note') {
        this.showNoteModal = true;
      }
    },
    updateQuantityReceived({
      id,
      quantityReceived
    }: {
      id: number;
      quantityReceived: number;
    }) {
      const item = this.processItems.find((item) => item.id === id);
      if (item) {
        item.quantityReceived = quantityReceived;
      }
    }
  },
  apollo: {
    stockOrder: {
      query: gql`
        query stockOrder($id: Int!) {
          stockOrder(id: $id) {
            id
            cid
            status
            notes
            createdAt
            orderedAt
            supplier {
              id
              name
              email
            }
            orderItems {
              id
              costPrice
              quantity
              quantityReceived
              total
              product {
                id
                partNumber
                name
              }
            }
          }
        }
      `,
      skip() {
        return !this.$route.params.stockOrderId;
      },
      variables() {
        return {
          id: Number.parseInt(this.$route.params.stockOrderId)
        };
      },
      result(response) {
        response.data.stockOrder.orderItems.forEach((item: StockOrderItem) => {
          const existingItem = this.processItems.find(
            (i) => i.product?.id === item.product?.id
          );
          if (!existingItem) {
            this.processItems.push({
              id: item.id,
              costPrice: item.costPrice,
              quantity: item.quantity,
              quantityReceived: item.quantityReceived,
              total: item.total,
              product: item.product
            });
          }
        });
      },
      fetchPolicy: 'cache-and-network'
    }
  }
});
</script>

<style lang="scss" module>
.inner {
  position: relative;
  min-height: 200px;
  height: 100%;
}
</style>
