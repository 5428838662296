<template>
  <BaseModalSmall testId="error" @close="onClose">
    <img
      v-if="content.visual"
      :src="`/img/${content.visual}.svg`"
      class="mb-1"
    />
    <BaseText>
      {{ content.message }}
    </BaseText>
    <template #footer>
      <BaseButton @click="reload" v-test="'btn-refresh'">
        {{ $t('global.refresh_button') }}
      </BaseButton>
    </template>
  </BaseModalSmall>
</template>

<script lang="ts">
import { datadogRum } from '@datadog/browser-rum';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  emits: ['close'],
  computed: {
    content() {
      let message;
      let visual;

      switch (this.data.statusCode) {
        case '401':
          message = this.$t('global.error_unauthorized');
          break;
        case '404':
          message = this.$t('global.error_notfound');
          break;
        default:
          message = this.$t('global.error_general');
          visual = 'visual-error-500';
      }

      return { message, visual };
    }
  },
  methods: {
    reload() {
      datadogRum.addAction('error-modal-refreshed');
      location.reload();
    },
    onClose() {
      datadogRum.addAction('error-modal-dismissed');
      this.$emit('close');
    }
  },
  created() {
    datadogRum.addAction('error-modal-shown', {
      operationName: this.data.operationName,
      statusCode: this.data.statusCode,
      extensionStatusCode: this.data.extensionStatusCode,
      errors: this.data.modalErrors,
      routeName: this.$route.name
    });
  }
});
</script>
