import dayjs from '@/dayjs';
import { modal } from '@/helpers/ui';
import { computed, ref, watch } from 'vue';
import type { Ref } from 'vue';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';

export const useRouteDate = (
  options: {
    updateRouteQuery?: boolean;
    routeName?: string | null;
    hideRouteQueryToday?: boolean;
  } | null = {}
): {
  date: Ref<string>;
} => {
  // This method will check for a "date" query in the route, and return its value as a reactive variable.
  // If the route does not have a "date" query, it will return the current date instead.
  // It will also check if the "date" query in the route is a valid date. If not, it will return the current date instead.

  // Passing "updateRouteQuery" will automatically add a date query to the route if it doesn't have one.

  const route = useRoute();
  const router = useRouter();

  const {
    updateRouteQuery = false,
    routeName = route.name,
    hideRouteQueryToday = false
  } = options || {};

  const routeDate = computed<string>(() =>
    typeof route?.query?.date === 'string' ? route.query.date : ''
  );
  const dayjsDate = routeDate.value ? dayjs(routeDate.value) : dayjs();
  const date = ref(dayjsDate.format('YYYY-MM-DD'));

  watch(
    () => routeDate.value,
    (newRouteDate) => {
      // When the date query in the route changes, update the date value
      if (newRouteDate && newRouteDate !== date.value) {
        date.value = newRouteDate;
      }
    }
  );

  const updateRoute = (newRouteDate: string) => {
    // Update the data in the route
    // Don't do anything when the updateRouteQuery setting is disabled, or if the current route date is the same as the date,
    // or if the current route name is different from the initial route name
    if (
      !updateRouteQuery ||
      routeDate.value === newRouteDate ||
      !route ||
      route.name !== routeName
    ) {
      return;
    }

    // When hideRouteQueryToday is passed, remove the date query from the route if it's the same day as today
    if (hideRouteQueryToday && dayjs().tz().isSame(newRouteDate, 'day')) {
      newRouteDate = '';
    }

    router.replace({
      path: route.path,
      query: newRouteDate
        ? {
            date: newRouteDate
          }
        : {}
    });
  };

  watch(
    () => date.value,
    (newDate) => {
      // Update the route query whenever the date value changes
      updateRoute(newDate);
    }
  );

  if (routeName && route) {
    watch(
      () => route.name,
      (newRouteName) => {
        // When navigating back to the initial route, update the route query
        if (newRouteName === routeName) {
          updateRoute(date.value);
        }
      }
    );
  }

  return {
    date
  };
};

export const useLeaveConfirmation = (
  formData: Record<string, unknown>,
  disabled = ref(false)
) => {
  // This method will prompt the confirmation modal when the user navigates away from the page
  // It will only do so if the initial state of the form has changed
  // The type on the param is to enforce sending an object, which needs to be reactive in order for this to work

  // After saving the form on the page, the resetConfirmation method needs to be called
  // This will disable prompting the confirmation modal, until the form data gets changed again

  const confirmLeave = ref(false);

  watch(
    () => formData,
    () => {
      if (disabled.value) {
        return;
      }

      confirmLeave.value = true;
    },
    {
      deep: true
    }
  );

  onBeforeRouteLeave(async () => {
    if (confirmLeave.value) {
      const confirmed = await modal('confirmation', {
        type: 'leave'
      });

      if (!confirmed) {
        return false;
      }
    }
  });

  const resetConfirmation = () => {
    confirmLeave.value = false;
  };

  return {
    confirmLeave,
    resetConfirmation
  };
};
