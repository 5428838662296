<template>
  <div>
    <div :class="$style.heading">
      <BaseHeading size="s">
        {{ $t('email_templates.preview.heading') }}
      </BaseHeading>
    </div>
    <div :class="$style.template">
      <div v-html="formattedContent" v-test="'email-template-preview'" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCompanyStore } from '@/stores/company';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  content: string;
  footerContent: string;
}>();

const { company } = useCompanyStore();

type ContentMap = {
  variable: string;
  content: string;
  inFooter: boolean;
};

const { t } = useI18n();

const contentMapping = computed<ContentMap[]>(() => {
  const logo = company.logo?.small || null;

  return [
    {
      variable: '{{cancel_link}}',
      content: `<a href="#">${t('email_templates.preview.cancel_appointment')}</a>`,
      inFooter: false
    },
    {
      variable: '{{reschedule_link}}',
      content: `<a href="#">${t('email_templates.preview.reschedule_appointment')}</a>`,
      inFooter: false
    },
    {
      variable: '{{company_logo}}',
      content: logo
        ? `<img src="${logo}" height="100" style="display: block;">`
        : '',
      inFooter: true
    },
    {
      variable: '{{company_name}}',
      content: company.name,
      inFooter: true
    },
    {
      variable: '{{company_address}}',
      content:
        `<span style="white-space: pre-wrap">${company.fullAddress}</span>` ||
        '',
      inFooter: true
    },
    {
      variable: '{{company_email}}',
      content: company.contactEmail || '',
      inFooter: true
    },
    {
      variable: '{{company_phone}}',
      content: company.phone || '',
      inFooter: true
    },
    {
      variable: '{{company_social}}',
      content: `
        ${company.instagramId ? '<a href="#"><img src="/img/social/instagram.png" width="32" height="32"></a>' : ''}
        ${company.facebookId ? '<a href="#"><img src="/img/social/facebook.png" width="32" height="32"></a>' : ''}
        ${company.twitterId ? '<a href="#"><img src="/img/social/twitter.png" width="32" height="32"></a>' : ''}
      `,
      inFooter: true
    }
  ];
});

const formattedContent = computed(() => {
  let content = props.content;

  // Replace variables in main content
  contentMapping.value.forEach((c: ContentMap) => {
    if (content.includes(c.variable)) {
      content = content.replace(c.variable, c.content);
    }
  });

  // Add the footer
  if (props.footerContent) {
    content = `${content}<br>${props.footerContent}`;
  }

  // These should apply to both the content and the footer
  contentMapping.value
    .filter((c: ContentMap) => c.inFooter)
    .forEach((c: ContentMap) => {
      if (content.includes(c.variable)) {
        content = content.replace(c.variable, c.content);
      }
    });

  // replace any links with fake links
  content = content.replace(/href="[^"]*"/g, 'href="#"');

  return content;
});
</script>

<style lang="scss" module>
.heading {
  display: flex;
  justify-content: center;
  margin-bottom: $spacing;
}

.template {
  background-color: white;
  padding: $spacing;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  font:
    small/1.5 Arial,
    Helvetica,
    sans-serif;

  img {
    border: 0;
  }

  a {
    color: $color-primary;
  }

  p {
    margin: 0;
  }
}
</style>
