import { useVersionMismatchStore } from '@/stores/version-mismatch';
/* eslint-disable no-console */
import { UserNotificationType } from '@/types';
import { useSubscription } from '@vue/apollo-composable';
import gql from 'graphql-tag';

export const useGlobalSubscription = () => {
  const startSubscription = () => {
    const { onResult } = useSubscription(gql`
      subscription notifications {
        userNotification {
          scope
          type
        }
      }
    `);

    onResult(({ data }) => {
      // When getting a "reload" notification from the subscription, enable the nav reload and message
      if (data?.userNotification?.type === UserNotificationType.Reload) {
        const { triggerReload } = useVersionMismatchStore();
        triggerReload(data?.userNotification?.scope);
      }

      // When getting a "log" notification from the subscription, log a message in the console. Used for testing purposes.
      if (data?.userNotification?.type === UserNotificationType.Log) {
        console.log('User notification log triggered.');
      }
    });
  };

  return {
    startSubscription
  };
};
