import dayjs from '@/dayjs';
import i18n from '@/i18n';
import { useCreateAppointmentStore } from '@/stores/calendar-create-appointment';
import { useCreateEventStore } from '@/stores/calendar-create-event';
import { useCompanyStore } from '@/stores/company';
import { useResourcesStore } from '@/stores/resources';
import { useServicesStore } from '@/stores/services';
import type {
  CalendarAbsence,
  CalendarAppointment,
  CalendarNote,
  RruleAttributes
} from '@/types';

const formatRrule = (rrule: RruleAttributes | null, startAt: string) => {
  // The rrule data that we use in the form data and send to the backend is different from what Fullcalendar expects
  // The backend does this same conversion when creating / updating appointments
  // But for the preview we do it ourselves. This is not ideal because we now have this logic in two places (frontend and backend)

  if (!rrule) {
    return null;
  }

  const { freq, until, count, byday, interval, bymonthday, monthlyByday } =
    rrule;

  let byweekday: any = byday?.length ? [byday[0] - 1] : [];
  if (freq === 'monthly' && monthlyByday?.length) {
    byweekday = [
      {
        m: monthlyByday[0][0],
        weekday: monthlyByday[0][1] - 1
      }
    ];
  }

  return {
    byweekday,
    bymonthday: bymonthday || [],
    count,
    dtstart: dayjs(startAt).tz().format('YYYYMMDDTHHmmss'),
    freq,
    interval,
    until: until ? dayjs(until).format('YYYY-MM-DD') : null
  };
};

const getDuration = (start: string, end: string) => {
  // When creating a recurring event, Fullcalendar needs a duration object
  // For non recurring events, this gets ignored and the end property gets used instead

  const diff = dayjs(end).diff(start, 'minute');
  const days = Math.floor(diff / (60 * 24));
  const hours = Math.floor((diff - days * 60 * 24) / 60);
  const minutes = diff - hours * 60;

  return {
    days,
    hours,
    minutes
  };
};

export const generateAppointmentEvents = (): CalendarAppointment[] => {
  const { formData, customerData, customerMember } =
    useCreateAppointmentStore();
  const { customerId, partsAttributes, rrule, startAt, notes, locationId } =
    formData;

  const { serviceById } = useServicesStore();
  const { resourceById } = useResourcesStore();
  const {
    companySettings: {
      agenda: { colour, appointmentEndBuffer, appointmentEndBufferTime }
    }
  } = useCompanyStore();

  const parts = partsAttributes.filter((part) => !!part.allocationsAttributes);

  return parts.flatMap((part, index) => {
    // The service which is attached to the part
    const service = part.serviceId ? serviceById(part.serviceId) : null;

    // All the resources added to the part
    const resourceIds =
      (part.allocationsAttributes
        ?.map((allocation) => allocation.resourceId)
        .filter((resourceId) => !!resourceId) as number[]) || [];

    // Wether or not this is the last part, used for adding the appointment end buffer
    const isLastPart = index === parts.length - 1;

    let start = startAt;
    if (index > 0) {
      // When the event is not the first appointment part, we need to calculate the total duration of the previous parts, and add that to the start time
      // This way the event will be placed after the previous events

      const prevParts = parts.slice(0, index);
      let duration = 0;

      prevParts.forEach((prevPart) => {
        const prevService = prevPart.serviceId
          ? serviceById(prevPart.serviceId)
          : null;
        if (prevService?.requiresProcessingTime) {
          duration +=
            (prevPart.durationSetup || 0) +
            (prevPart.durationProcessing || 0) +
            (prevPart.durationFinish || 0);
        } else {
          duration += prevPart.duration || 0;
        }
      });

      start = dayjs(startAt)
        .add(duration || 0, 'minute')
        .format();
    }

    const getColor = () => {
      let eventColor = '#FFFFFF';
      if (colour === 'RESOURCE' && resourceIds.length) {
        const firstResource = resourceById(resourceIds[0]);
        if (firstResource?.color) {
          eventColor = firstResource.color;
        }
      } else if (colour === 'CATEGORY' && service) {
        eventColor = service.category.color;
      }

      return eventColor;
    };

    let eventDuration = 0;
    if (service?.requiresProcessingTime) {
      eventDuration = part.durationSetup || 0;
    } else {
      eventDuration = part.duration || 0;

      if (isLastPart && appointmentEndBuffer && appointmentEndBufferTime) {
        eventDuration += appointmentEndBufferTime;
      }
    }

    const end = dayjs(start).add(eventDuration, 'minute').format();

    const editable = parts.length === 1 && !service?.requiresProcessingTime;

    const getCustomerName = () => {
      if (customerMember) {
        return `${customerMember.firstName} ${customerMember.lastName}`;
      }
      return `${customerData.firstName || ''}${customerData.lastName ? ` ${customerData.lastName}` : ''}`;
    };

    const event = {
      duration: getDuration(start, end),
      durationEditable: editable,
      end,
      extendedProps: {
        activated: false,
        color: getColor(),
        customerId,
        customerName: getCustomerName(),
        entryId: 0,
        eventDuration,
        features: {
          notes,
          recurs: !!rrule
        },
        id: 0,
        locationId: locationId || 0,
        partId: 0,
        pending: false,
        preview: true,
        totalDuration: dayjs(end).diff(start, 'minute'),
        type: 'appointment'
      },
      groupId: 0,
      id: `appointment-preview-${index + 1}`,
      partId: 0,
      resourceEditable: editable,
      resourceIds,
      rrule: formatRrule(rrule, start),
      start,
      startEditable: editable,
      title: service?.name || ''
    };

    if (service?.requiresProcessingTime) {
      const secondEventStart = dayjs(start)
        .add(
          (part.durationSetup || 0) + (part.durationProcessing || 0),
          'minute'
        )
        .format();

      const secondEvent = {
        ...event,
        end: dayjs(secondEventStart)
          .add(
            (part.durationFinish || 0) +
              (isLastPart && appointmentEndBuffer
                ? appointmentEndBufferTime || 0
                : 0),
            'minute'
          )
          .format(),
        id: `appointment-preview-${index + 1}-2`,
        rrule: formatRrule(rrule, secondEventStart),
        start: secondEventStart
      };
      return [event, secondEvent];
    } else {
      return event;
    }
  });
};

type PreviewAbsence = CalendarAbsence & {
  extendedProps: {
    preview: boolean;
  };
};

export const generateAbsenceEvent = (): PreviewAbsence => {
  const { absenceData } = useCreateEventStore();

  const { allDay, endAt, startAt, resourceId, rrule, name, chore, locationId } =
    absenceData;
  const { resourceById } = useResourcesStore();
  const resource = resourceId ? resourceById(resourceId) : null;
  const durationMinutes = dayjs(endAt).diff(startAt, 'minute');

  return {
    allDay: !!allDay,
    duration: getDuration(startAt, endAt),
    durationEditable: true,
    end: endAt,
    extendedProps: {
      chore: !!chore,
      color: chore ? '#d0e8d0' : '#d0d0d0',
      company: !resourceId,
      duration: durationMinutes,
      entryId: 0,
      eventDuration: durationMinutes,
      imported: false,
      locationId,
      name: name || '',
      preview: true,
      recurs: !!rrule,
      type: 'absence'
    },
    id: 'absence-preview',
    resourceEditable: true,
    resourceId: resourceId || null,
    rrule: formatRrule(rrule || null, startAt),
    start: startAt,
    startEditable: true,
    title: resource
      ? i18n.t('calendar.preview.absence.resource_absent', {
          name: resource.name
        })
      : i18n.t('calendar.preview.absence.company_absent')
  };
};

type PreviewNote = CalendarNote & {
  extendedProps: {
    preview: boolean;
  };
};

export const generateNoteEvent = (): PreviewNote => {
  const { noteData } = useCreateEventStore();
  const { date, resourceId, text } = noteData;

  return {
    allDay: true,
    durationEditable: false,
    extendedProps: {
      color: '#ffff4d',
      company: !resourceId,
      done: false,
      entryId: 0,
      preview: true,
      type: 'note'
    },
    id: 'note-preview',
    resourceEditable: false,
    resourceId: resourceId || null,
    start: date,
    startEditable: true,
    title: text
  };
};
