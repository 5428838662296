import dayjs from '@/dayjs';
import { useRouteDate } from '@/helpers/routing';
import calendarInterface from '@/modules/calendar/calendar-interface';
import { useCompanyStore } from '@/stores/company';
import { useLocationsStore } from '@/stores/locations';
import { useResourcesStore } from '@/stores/resources';
import { useStorage } from '@vueuse/core';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, watch, watchEffect } from 'vue';
import { usePageLayoutStore } from './page-layout';

export enum ViewType {
  Day = 'DAY',
  Week = 'WEEK'
}

export const useCalendarFiltersStore = defineStore('calendar/filters', () => {
  // Adding a custom serializer so the value will be converted to a number, since localStorage can only store strings
  // useStorage does this by default when passing a number as a default value, but we want the default to be null, and not 0
  const resourceId = useStorage('calendar_resource', null, undefined, {
    serializer: {
      read: (id: any) => (id ? Number.parseInt(id) : null),
      write: (id: any) => (id ? id.toString() : '')
    }
  });

  // The resourceType can be changed from the calendar filter, so it needs to be a seperate ref
  const resourceType = useStorage('calendar_type', 'employee');

  // We need to define the locationId as a computed property, so we can watch when it changes
  const locationId = computed(() => {
    const { locationId } = useLocationsStore();
    return locationId;
  });
  const dataScope = computed(() => {
    const { dataScope } = useLocationsStore();
    return dataScope;
  });

  // The entire resource object. We need it to compare the object values with our local state
  const resource = computed(() => {
    const { resourceById } = useResourcesStore();
    return resourceById(resourceId.value);
  });

  watch(
    resource,
    (newResource) => {
      // The selected resource doesn't exist anymore, which can occur when switching accounts or deleting the resource
      if (!newResource) {
        resourceId.value = null;
      }
    },
    { immediate: true }
  );

  watch(
    resourceType,
    (newResourceType) => {
      // When the user selects another type, the resourceId should reset if the resource is not of that type
      if (resource.value?.type && resource.value.type !== newResourceType) {
        resourceId.value = null;
      }
    },
    { immediate: true }
  );

  watch(
    [locationId, dataScope],
    ([newLocationId, newDataScope]) => {
      // If the location changes, reset the resourceId if the resource doesn't work at that location
      // Only if the dataScope is set to local
      if (
        newLocationId &&
        newDataScope === 'LOCAL' &&
        resource.value?.locationIds &&
        !resource.value.locationIds.includes(newLocationId)
      ) {
        resourceId.value = null;
      }
    },
    { immediate: true }
  );

  const dynamicPricingMode = useStorage('calendar_dynamic_pricing', false);
  watchEffect(() => {
    const { companySettings } = useCompanyStore();

    if (!companySettings.bookings?.dynamicPricingEnabled) {
      dynamicPricingMode.value = false;
    }
  });

  const { date } = useRouteDate({
    updateRouteQuery: true,
    routeName: 'calendar',
    hideRouteQueryToday: true
  });

  const viewType = useStorage('calendar_view', ViewType.Week, undefined, {
    serializer: {
      read: (type: ViewType) => type.toUpperCase(),
      write: (type: ViewType) => type.toUpperCase()
    }
  });

  // When the date changes, change the Fullcalendar date
  watch(date, (newDate) => calendarInterface.api?.gotoDate(newDate));

  const mobileFullView = useStorage('calendar_mobile_full_view', false);

  watchEffect(() => {
    // When enabling dynamic pricing, automatically switch to week view
    // Switching back to day view while in dynamic pricing should be disabled in the UI
    if (dynamicPricingMode.value === true && viewType.value === ViewType.Day) {
      viewType.value = ViewType.Week;
    }

    // When using a bigger screen, disable mobileFullView
    const { screenSize } = usePageLayoutStore();
    if (screenSize !== 's') {
      mobileFullView.value = false;
    }
  });

  const isTodayVisible = computed(() => {
    const dayjsDate = dayjs.tz(date.value);
    return dayjs()
      .tz()
      .isSame(dayjsDate, viewType.value === ViewType.Week ? 'isoWeek' : 'day');
  });

  return {
    date,
    isTodayVisible,
    resourceId,
    resourceType,
    viewType,
    mobileFullView,
    dynamicPricingMode
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useCalendarFiltersStore, import.meta.hot)
  );
}
