import { useStorage, useWindowSize } from '@vueuse/core';
import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { computed, nextTick, watch } from 'vue';
import { useRoute } from 'vue-router';

import dayjs from '@/dayjs';
import { animateTo } from '@/modules/calendar/actions/calendar';
import calendarInterface from '@/modules/calendar/calendar-interface';
import { useCreateAppointmentStore } from '@/stores/calendar-create-appointment';
import { useCreateEventStore } from '@/stores/calendar-create-event';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import { useResourcesStore } from '@/stores/resources';

import {
  generateAbsenceEvent,
  generateAppointmentEvents,
  generateNoteEvent
} from './generate-events';

enum PreviewContext {
  Absence = 'ABSENCE',
  Appointment = 'APPOINTMENT',
  Note = 'NOTE'
}

export const useCalendarPreviewStore = defineStore('calendar/preview', () => {
  // Wether or not the user has enabled the preview mode.
  const previewModeEnabled = useStorage('calendar_preview_mode', false);

  // Wether or not the preview mode can be enabled. Use this to hide/show the toggle.
  const canEnablePreviewMode = computed(() => {
    const { width } = useWindowSize();
    return width.value >= 1000;
  });

  const route = useRoute();
  const routeName = computed(() => (route?.name ? String(route?.name) : ''));

  // Wether or not the preview mode is currently active.
  // This setting changes the behaviour of the calendar.
  const previewModeActive = computed(() => {
    const routes = [
      'create-appointment',
      'edit-appointment',
      'create-absence',
      'create-chore',
      'create-note'
    ];

    return (
      previewModeEnabled.value &&
      canEnablePreviewMode.value &&
      (!routeName.value || routes.includes(routeName.value))
    );
  });

  // This variable is used to change the layout of the calendar and modal, and perhaps other elements.
  // Does not change the behaviour of the calendar.
  const previewLayoutActive = computed(
    () =>
      previewModeActive.value ||
      (previewModeEnabled.value &&
        canEnablePreviewMode.value &&
        (!routeName.value || routeName.value === 'appointment'))
  );

  // This variable indicates if the user is currently creating an appointment, absence or note.
  const previewContext = computed<PreviewContext | null>(() => {
    if (!previewModeActive.value) {
      return null;
    }

    if (!routeName.value) {
      return PreviewContext.Appointment;
    }

    switch (routeName.value) {
      case 'create-appointment':
      case 'edit-appointment':
        return PreviewContext.Appointment;
      case 'create-absence':
      case 'create-chore':
        return PreviewContext.Absence;
      case 'create-note':
        return PreviewContext.Note;
      default:
        return null;
    }
  });

  const { formData: appointmentData } = useCreateAppointmentStore();
  const { absenceData, noteData } = storeToRefs(useCreateEventStore());

  // The events used for Fullcalendar, returns either appointment, absence or note events, based on the context.
  // Appointment events can be in multiple, absences and notes can only return one event.
  const previewEvents = computed<any[]>(() => {
    if (!previewModeActive.value) {
      return [];
    }

    switch (previewContext.value) {
      case PreviewContext.Appointment:
        return generateAppointmentEvents();
      case PreviewContext.Absence:
        return [generateAbsenceEvent()];
      case PreviewContext.Note:
        return [generateNoteEvent()];
      default:
        return [];
    }
  });

  // Based on the filters that the user sets in the calendar, some of the events might get hidden.
  // This is similar to non-preview events.
  // The filtered events is what we send to Fullcalendar.
  const filteredEvents = computed(() => {
    const { resourceId, resourceType } = useCalendarFiltersStore();

    return previewEvents.value.filter((event) => {
      const { resourceById } = useResourcesStore();
      const eventResourceIds =
        event.resourceIds || (event.resourceId ? [event.resourceId] : []);
      const eventResources = eventResourceIds.map((id: number) =>
        resourceById(id)
      );

      return (
        // Filter by resource id
        (!resourceId || eventResourceIds.includes(resourceId)) &&
        // Filter by resource type
        (!eventResources.length ||
          // When the calendar is filtered on an employee, always show the event, because every serice needs an employee
          // Otherwise only show it if the required resource type is selected
          resourceType === 'employee' ||
          !!eventResources.find(
            (resource: any) => resource.type === resourceType
          ))
      );
    });
  });

  // When there are less filtered events than the total events, that means that some events are hidden.
  // We use this to provide feedback to the user.
  const hasHiddenEvents = computed(
    () => previewEvents.value.length > filteredEvents.value.length
  );

  // The start date/time of the event.
  const eventStartAt = computed<string>(() => {
    switch (previewContext.value) {
      case PreviewContext.Appointment:
        return appointmentData.startAt;
      case PreviewContext.Absence:
        return absenceData.value.startAt;
      case PreviewContext.Note:
        return noteData.value.date;
      default:
        return '';
    }
  });

  // When changing the start date/time, change the calendar date so the preview will remain visible.
  // Only do this when switching to another week in week view, or to another day in day view (to prevent unwanted side effects, such as refetching of events)
  watch(
    eventStartAt,
    (currentDate) => {
      if (!previewModeActive.value || !currentDate) {
        return;
      }

      const store = useCalendarFiltersStore();
      store.date = dayjs(currentDate).format('YYYY-MM-DD');
    },
    {
      immediate: true
    }
  );

  // The main resource id of the event.
  // In the case of an appointment, we use the first resource on the first appointment part.
  const eventResourceId = computed<number | null>(() => {
    switch (previewContext.value) {
      case PreviewContext.Absence:
        return absenceData.value.resourceId || null;
      case PreviewContext.Note:
        return noteData.value.resourceId || null;
      case PreviewContext.Appointment:
        return (
          appointmentData.partsAttributes?.[0]?.allocationsAttributes?.[0]
            ?.resourceId || null
        );
      default:
        return null;
    }
  });

  const allDay = computed<boolean>(() => {
    switch (previewContext.value) {
      case PreviewContext.Absence:
        return absenceData.value.allDay || false;
      case PreviewContext.Note:
        return true;
      default:
        return false;
    }
  });

  // When the event start or resource changes, based on the calendar view, animate the calendar to the correct spot.
  const animateCalendar = () => {
    const { isFetchingAppointment } = useCreateAppointmentStore();
    if (isFetchingAppointment) {
      return;
    }

    if (previewModeActive.value && eventStartAt.value) {
      const { viewType } = useCalendarFiltersStore();

      nextTick(() => {
        if (eventResourceId.value && viewType === 'DAY') {
          animateTo({
            date: eventStartAt.value,
            resourceId: eventResourceId.value,
            vertical: !allDay.value
          });
        } else if (viewType === 'WEEK') {
          animateTo({
            date: eventStartAt.value,
            vertical: !allDay.value,
            horizontal: false
          });
        }
      });
    }
  };

  watch(eventStartAt, animateCalendar);
  watch(eventResourceId, animateCalendar);

  // When the preview data changes, trigger Fullcalendar to refetch the events from the "preview" source.
  // The source receives the filtered events from this store.
  watch(
    filteredEvents,
    () => {
      calendarInterface.api?.getEventSourceById('preview')?.refetch();
    },
    {
      deep: true,
      immediate: true
    }
  );

  return {
    previewModeEnabled,
    previewModeActive,
    previewLayoutActive,
    canEnablePreviewMode,
    previewEvents: filteredEvents,
    hasHiddenEvents,
    absenceData,
    previewContext,
    eventStartAt,
    routeName
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useCalendarPreviewStore, import.meta.hot)
  );
}
