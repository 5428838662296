import apolloClient from '@/apollo/client';
import dayjs from '@/dayjs';
import {
  calendarAbsenceFragment,
  calendarAppointmentFragment,
  calendarNoteFragment,
  calendarWaitingListFragment
} from '@/graphql-fragments';
import { flash } from '@/helpers/ui';
import i18n from '@/i18n';
import calendarInterface from '@/modules/calendar/calendar-interface';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import { useUserStore } from '@/stores/user';
import gql from 'graphql-tag';
import {
  createCalendarEvent,
  deleteCalendarEvent,
  removeDeletedAppointmentEvents,
  updateCalendarEvents
} from '../actions/calendar-events';

const handleEvents = (calendarEvents: any) => {
  const action = calendarEvents.action;
  const calendarAppointments = calendarEvents.calendarAppointments;
  const calendarAbsences = calendarEvents.calendarAbsences;
  const events =
    calendarAppointments ||
    calendarEvents.calendarNotes ||
    calendarAbsences ||
    calendarEvents.calendarWaitingLists;

  const { date } = useCalendarFiltersStore();
  if (
    calendarAppointments?.length &&
    action !== 'deleted' &&
    calendarAppointments[0].extendedProps.features.online &&
    dayjs(calendarAppointments[0].start).isSame(date, 'week')
  ) {
    flash(i18n.t(`global.flash.appointment_${action}_online`));
  }

  if (action === 'waiting_list_refetch') {
    calendarInterface.api?.refetchEvents();
  } else if (action === 'updated') {
    updateCalendarEvents(events);
    if (calendarAppointments) {
      removeDeletedAppointmentEvents(events);
    }
  } else if (events) {
    events.forEach((event: any) => {
      if (action === 'created') {
        createCalendarEvent(event);
      } else {
        deleteCalendarEvent(event.id);
      }
    });
  }
};

export const useSubscription = () => {
  const { user } = useUserStore();
  if (user?.impersonated) {
    return;
  }
  apolloClient
    .subscribe({
      query: gql`
        subscription events {
          calendarEvents {
            action
            calendarAppointments {
              ...calendarAppointment
            }
            calendarNotes {
              ...calendarNote
            }
            calendarAbsences {
              ...calendarAbsence
            }
            calendarWaitingLists {
              ...calendarWaitingList
            }
          }
        }
        ${calendarAppointmentFragment}
        ${calendarNoteFragment}
        ${calendarAbsenceFragment}
        ${calendarWaitingListFragment}
      `
    })
    .subscribe({
      next: (data) => {
        handleEvents(data.data.calendarEvents);
      }
    });
};
