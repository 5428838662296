<template>
  <div :class="$style.base">
    <router-view />
    <div
      v-if="!showEmptyPageContent && firstFetchFinished"
      :class="$style.header"
    >
      <BaseButton @click="onFillInForm" v-test="'customersFillInForm'">
        {{ $t('global.actions.fill_in_form') }}
      </BaseButton>
    </div>
    <div v-show="forms.length" :class="$style.wrapper">
      <div :class="$style.table">
        <BaseTableRow head>
          <BaseTableCell :width="5" />
          <BaseTableCell>{{ $t('global.date') }}</BaseTableCell>
          <BaseTableCell>{{ $t('global.items.form', 1) }}</BaseTableCell>
          <BaseTableCell>{{ $t('global.type') }}</BaseTableCell>
          <BaseTableCell>{{ $t('global.items.appointment', 1) }}</BaseTableCell>
          <div :class="$style.showMore">
            <BaseTableCell />
          </div>
        </BaseTableRow>

        <BaseTableRow
          v-for="(item, index) in forms"
          :key="`row-${index}`"
          link
          :options="
            item.template.formType !== 'contract' ? ['delete', 'edit'] : ['pdf']
          "
          zebra
          @optionClick="onOptionClick(item, $event)"
          v-test="'baseTableRow'"
        >
          <router-link
            :class="$style.link"
            :to="{ name: 'customer-form-preview', params: { id: item.id } }"
          >
            <BaseTableCell :width="5">
              <div :class="$style.icon">
                <BaseIcon v-if="item.online" name="globe" color="success" />
              </div>
            </BaseTableCell>
            <BaseTableCell>{{
              filters.dateTime(item.createdAt)
            }}</BaseTableCell>
            <BaseTableCell>{{ item.template.name }}</BaseTableCell>
            <BaseTableCell>{{
              $t(`forms.${item.template.formType}`)
            }}</BaseTableCell>
            <BaseTableCell v-if="item.appointment">{{
              filters.dateTime(item.appointment.startAt)
            }}</BaseTableCell>
            <BaseTableCell v-else>-</BaseTableCell>
          </router-link>
        </BaseTableRow>
      </div>
    </div>
    <EmptyPageContent
      v-if="showEmptyPageContent"
      imageName="forms"
      :text="{
        title: $t('customers.empty_state.forms.title'),
        description: $t('customers.empty_state.forms.description'),
        btnPrimary: $t('global.actions.fill_in_form'),
        btnSecondary: $t('customers.empty_state.forms.button_secondary')
      }"
      :moreLink="{
        text: $t('customers.empty_state.forms.more_text'),
        url: $t('customers.empty_state.forms.more_url')
      }"
      @click="onEmptyPageClick"
    />
    <LoadMore v-if="!allDataLoaded" @visibilityChanged="visibilityChanged" />
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { modal, flash } from '@/helpers/ui';
import apolloClient from '@/apollo/client';
import gql from 'graphql-tag';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import LoadMore from '@/components/LoadMore.vue';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { defineComponent, provide, ref } from 'vue';
import config from '@/config';

export default defineComponent({
  components: {
    EmptyPageContent,
    LoadMore,
    BaseTableRow,
    BaseTableCell
  },
  setup() {
    const forms = ref([]);
    provide('forms', forms);

    return {
      filters,
      forms
    };
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      allDataLoaded: false,
      isLoading: true,
      firstFetchFinished: false
    };
  },
  computed: {
    customerId() {
      return this.$route.params.customerId;
    },
    showEmptyPageContent() {
      return !this.isLoading && !this.forms.length && this.firstFetchFinished;
    }
  },
  methods: {
    onEmptyPageClick(button) {
      if (button === 'primary') {
        this.onFillInForm();
      } else if (button === 'secondary') {
        this.$router.push({ name: 'admin-forms' });
      }
    },
    query() {
      this.isLoading = true;
      apolloClient
        .query({
          query: gql`
            query getFormSubmissions(
              $customerId: Int
              $currentPage: Int!
              $perPage: Int!
            ) {
              formSubmissions(
                customerId: $customerId
                currentPage: $currentPage
                perPage: $perPage
              ) {
                id
                createdAt
                appointment {
                  startAt
                }
                template {
                  name
                  formType
                  id
                }
                online
              }
            }
          `,
          variables: {
            customerId: Number.parseInt(this.customerId),
            currentPage: this.currentPage,
            perPage: this.perPage
          }
        })
        .then((response) => {
          const newEntries = response.data.formSubmissions;
          this.forms = [...this.forms, ...newEntries];
          this.allDataLoaded = newEntries.length < this.perPage;
          this.isLoading = false;
          this.firstFetchFinished = true;
        });
    },
    getNewItems() {
      this.currentPage = 1;
      this.allDataLoaded = false;
      this.waitingListEntries = [];
      this.query();
    },
    onFillInForm() {
      this.$router.push({ name: 'customer-forms-select' });
    },
    deleteForm(id) {
      apolloClient
        .mutate({
          mutation: gql`
            mutation deleteFormSubmission($input: DeleteFormSubmissionInput!) {
              deleteFormSubmission(input: $input) {
                formSubmission {
                  id
                }
              }
            }
          `,
          variables: {
            input: {
              id
            }
          },
          operationName: 'deleteFormSubmission'
        })
        .then(() => {
          flash(this.$t('global.flash.form_deleted'));
          this.forms = this.forms.filter((item) => item.id !== id);
        });
    },
    onOptionClick(item, action) {
      if (action === 'edit') {
        this.$router.push({
          name: 'customer-form-edit',
          params: { templateId: item.template.id, subId: item.id }
        });
      }

      if (action === 'delete') {
        modal('confirmation', {
          type: 'delete',
          item: this.$t('global.items.form')
        }).then(() => {
          this.deleteForm(item.id);
        });
      }

      if (action === 'pdf') {
        window.open(`${config.backendUrl}/form_submissions/${item.id}.pdf`);
      }
    },
    visibilityChanged(isVisible) {
      if (this.forms.length && isVisible && !this.allDataLoaded) {
        this.currentPage++;
        this.query();
      }
    }
  },
  created() {
    this.getNewItems();
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
}

.wrapper {
  overflow-x: auto;
  min-height: 70vh;
}

.table {
  min-width: 900px;
}

.link {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.icon {
  display: flex;
  justify-content: center;
  padding: 0;
}

.header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: $spacing;
}

.showMore {
  width: 52px !important;
  flex: none !important;
}
</style>
