<template>
  <BasePopover
    :class="[
      $style.base,
      {
        [$style.arrowTopRight]: arrow === 'topRight',
        [$style.arrowTopLeft]: arrow === 'topLeft',
        [$style.arrowBottomRight]: arrow === 'bottomRight',
        [$style.arrowBottomLeft]: arrow === 'bottomLeft',
        [$style.autoPositioning]: autoPositioning
      }
    ]"
    :keepVisibleOn="keepVisibleOn"
    :style="style"
    @close="$emit('close', $event)"
  >
    <div :class="$style.inner" v-test="'_base-popover-nav'">
      <div v-if="$slots.default" :class="$style.top">
        <slot />
      </div>
      <div v-if="items && items.length" :class="$style.bottom">
        <a
          v-for="(item, index) in items"
          :key="index"
          :href="item.href || '#'"
          :class="$style.link"
          @click="onItemClick(item, $event)"
          v-test="'_base-popover-nav-item'"
        >
          <BaseIcon :name="item.icon" :mr="0.5" :class="$style.icon" />
          {{ item.label }}
          <BaseLabel
            v-if="user?.updates && item.name === 'new-features'"
            color="success"
            :ml="0.5"
          >
            {{ $t('global.actions.new') }}
          </BaseLabel>
        </a>
      </div>
    </div>
  </BasePopover>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { usePageLayoutStore } from '@/stores/page-layout';
import { useUserStore } from '@/stores/user';

let observer;

import { defineComponent } from 'vue';

export default defineComponent({
  //inheritAttrs: false,
  props: {
    items: {
      type: Array
    },
    arrow: {
      type: String,
      required: false,
      default: 'topRight',
      validator: (value) =>
        ['topRight', 'topLeft', 'bottomRight', 'bottomLeft'].indexOf(value) !==
        -1
    },
    keepVisibleOn: {
      type: Element
    },
    autoPositioning: {
      type: Boolean,
      default: false
    },
    positionOn: {
      type: Element
    }
  },
  emits: ['close', 'select'],
  data() {
    return {
      style: null
    };
  },
  computed: {
    ...mapState(useUserStore, ['user']),
    ...mapState(usePageLayoutStore, ['navbarWidth'])
  },
  methods: {
    onItemClick(item, e) {
      if (!item.href) {
        e.preventDefault();
        this.$emit('select', item);
      }
    },
    onShow() {
      if (this.positionOn) {
        const rect = this.positionOn.getBoundingClientRect();
        if (this.arrow === 'topLeft') {
          const left = rect.left - this.navbarWidth;
          this.style = {
            left: `${Math.round(left)}px`
          };
        } else if (this.arrow === 'topRight') {
          const right = window.innerWidth - rect.left - rect.width;
          this.style = {
            right: `${Math.round(right)}px`
          };
        }
      }
    },
    onHide() {
      this.style = null;
    }
  },
  mounted() {
    if (this.autoPositioning) {
      if (this.$el.style.display !== 'none') {
        this.onShow();
      }

      observer = new MutationObserver(() => {
        this.$el.style.display !== 'none' ? this.onShow() : this.onHide();
      });

      observer.observe(this.$el, {
        attributes: true,
        attributeFilter: ['style']
      });
    }
  },
  beforeUnmount() {
    if (this.positionOn) {
      observer.disconnect();
    }
  }
});
</script>

<style lang="scss" module>
.base {
  position: absolute;
  padding: 0 !important;
  width: auto !important;

  &:before,
  &:after {
    content: '';
    position: absolute;
  }

  &:before {
    z-index: 2;
  }

  &:after {
    filter: blur(2px);
  }

  $arrowSize: 6px;

  &.arrowTopLeft,
  &.arrowTopRight {
    &:before {
      top: $arrowSize * -1;
      border-left: $arrowSize solid transparent;
      border-right: $arrowSize solid transparent;
      border-bottom: $arrowSize solid white;
    }

    &:after {
      top: ($arrowSize + 1px) * -1;
      border-left: $arrowSize + 1px solid transparent;
      border-right: $arrowSize + 1px solid transparent;
      border-bottom: $arrowSize + 1px solid rgba(0, 0, 0, 0.15);
    }
  }

  &.arrowTopLeft {
    &:before {
      left: $spacing;
    }
    &:after {
      left: $spacing - 1px;
    }

    &.autoPositioning {
      margin-left: ($spacing * -0.5) + ($arrowSize * 0.5);
    }
  }

  &.arrowTopRight {
    &:before {
      right: $spacing;
    }
    &:after {
      right: $spacing - 1px;
    }

    &.autoPositioning {
      margin-right: ($spacing * -0.5) + ($arrowSize * 0.5);
    }
  }

  &.arrowBottomLeft {
    &:before {
      bottom: $spacing;
      left: -6px;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 6px solid white;
    }

    &:after {
      bottom: $spacing - 1px;
      left: -7px;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-right: 7px solid rgba(0, 0, 0, 0.15);
    }
  }
}

.inner {
  position: relative;
  z-index: 1;
  margin: $spacing * -1;
  border-radius: $radius;
  background: $white;
}

.top {
  padding: $spacing;
  border-bottom: 1px solid $color-border;
}

.bottom {
  padding: $spacing * 0.5 0;
}

.link {
  display: flex;
  align-items: center;
  padding: 12px $spacing;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  margin: -8px 0;

  @include hover {
    background-color: $color-highlight;
  }

  &:first-child {
    .bottom:first-child & {
      border-radius: $radius $radius 0 0;
    }
  }

  &:last-child {
    .bottom:last-child & {
      border-radius: 0 0 $radius $radius;
    }
  }
}

.icon {
  margin-left: -4px;
}
</style>
