<template>
  <BaseCard gray mb>
    <div
      v-if="multiLocation"
      :class="[$style.inner, { [$style.isSmallScreen]: $screen === 's' }]"
    >
      <BaseRadio
        v-model="selectedRosterOption"
        :label="$t('global.roster')"
        :options="options"
        v-test="'resource-customRoster'"
      />
      <div :class="$style.innerMore">
        <div v-if="selectedRosterOption" :class="$style.rosterInfo">
          <BaseAlert
            color="warning"
            :text="$t('admin.resources.roster_setup')"
          />
        </div>
        <div v-else :class="$style.rosterLocation">
          <BaseDropdown
            v-model="selectedLocation"
            :options="locationOptions"
            :label="$t('global.items.location', 1)"
            v-test="'resource-rosterLocation'"
          />
        </div>
      </div>
    </div>
    <BaseCheckbox
      v-else
      v-model="selectedRosterOption"
      :label="$t(`${resourceType}.manual_roster`)"
      v-test="'resource-customRoster'"
    />
  </BaseCard>
</template>

<script lang="ts">
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    customRoster: {
      type: Boolean
    },
    resourceType: {
      type: String
    },
    multiLocation: {
      type: Boolean
    },
    rosterLocation: {
      type: Number
    }
  },
  emits: ['update:customRoster', 'update:rosterLocation'],
  data() {
    return {
      options: [
        { value: true, label: this.$t(`${this.resourceType}.custom_roster`) },
        {
          value: false,
          label: this.$t(`${this.resourceType}.location_roster`)
        }
      ]
    };
  },
  computed: {
    ...mapState(useLocationsStore, ['locationId', 'locations']),
    selectedRosterOption: {
      get() {
        return this.customRoster;
      },
      set(value) {
        this.$emit('update:customRoster', value);
      }
    },
    selectedLocation: {
      get() {
        return this.rosterLocation;
      },
      set(value) {
        this.$emit('update:rosterLocation', value);
      }
    },
    locationOptions() {
      return this.locations.map((location) => ({
        value: location.id,
        label: location.internalName
      }));
    }
  },
  created() {
    if ((this.multiLocation || !this.customRoster) && !this.rosterLocation) {
      this.selectedLocation = this.locationId;
    }
  }
});
</script>

<style lang="scss" module>
.inner {
  display: flex;

  &.isSmallScreen {
    flex-direction: column;
  }
}

.innerMore {
  padding-left: $spacing;
  display: flex;
  width: 50%;

  .inner.isSmallScreen & {
    margin-top: $spacing;
    padding-left: 0;
    width: 100%;
  }
}

.rosterInfo {
  align-self: center;
  width: 100%;
}

.rosterLocation {
  width: 100%;
}
</style>
