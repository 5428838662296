import config from '@/config';
import { createStore } from 'vuex';

import marketing from './modules/marketing/store';

export default createStore({
  strict: !config.isProduction,
  modules: {
    marketing
  }
});
