import { useCompanyStore } from '@/stores/company';
import type {
  Mutation,
  Query,
  Quote,
  Error as SalonizedError,
  SubscriptionPricing
} from '@/types';
import { SubscriptionPeriod, SubscriptionPlan } from '@/types';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { GET_STRIPE_PRICES_QUERY, STRIPE_QUOTE_MUTATION } from './graphql';

export const useStripeStore = defineStore('stripeSubscription', () => {
  const _stripePrices = ref<SubscriptionPricing[]>([]);
  const loadingPrices = ref(true);

  const getStripePricesData = () => {
    loadingPrices.value = true;
    const { onResult } = useQuery<Query>(GET_STRIPE_PRICES_QUERY);

    onResult(({ data: { stripePrices } }) => {
      loadingPrices.value = false;
      if (stripePrices) {
        _stripePrices.value = stripePrices;
      }
    });
  };

  const { activeSubscription } = useCompanyStore();
  const selectedPeriod = ref(
    activeSubscription && activeSubscription.billingPeriod === 'annually'
      ? SubscriptionPeriod.Year
      : SubscriptionPeriod.Month
  );
  const selectedPlan = ref(SubscriptionPlan.Pro);
  const stripePricesPerPeriod = computed(() =>
    _stripePrices.value.find(
      (stripePrice) => stripePrice.period === selectedPeriod.value
    )
  );
  const selectedStripePrice = computed(() =>
    stripePricesPerPeriod.value?.plans.find(
      ({ plan }) => plan === selectedPlan.value
    )
  );

  const stripeQuotes = ref<Quote[]>([]);
  const monthlyQuote = ref(
    stripeQuotes.value.find(
      (quote) => quote.period === SubscriptionPeriod.Month
    )
  );
  const yearlyQuote = ref(
    stripeQuotes.value.find((quote) => quote.period === SubscriptionPeriod.Year)
  );
  const activeQuote = computed(() =>
    selectedPeriod.value === SubscriptionPeriod.Month
      ? monthlyQuote.value
      : yearlyQuote.value
  );

  const stripeErrors = ref<SalonizedError[]>([]);

  const {
    mutate,
    loading: loadingQuote,
    error: stripeQuoteError
  } = useMutation<Mutation>(STRIPE_QUOTE_MUTATION);

  watch(
    selectedPlan,
    () => {
      mutate({
        input: {
          plan: selectedPlan.value
        }
      }).then((response) => {
        const quotes = response?.data?.createStripeQuote?.quotes;
        if (quotes) {
          const monthly = quotes.find(
            (quote) => quote.period === SubscriptionPeriod.Month
          );

          if (monthly?.plan === selectedPlan.value) {
            monthlyQuote.value = monthly;
            yearlyQuote.value = quotes.find(
              (quote) => quote.period === SubscriptionPeriod.Year
            );
            stripeQuotes.value = quotes;
          }
        }
      });
    },
    { immediate: true }
  );

  return {
    selectedPlan,
    selectedPeriod,
    loadingPrices,
    selectedStripePrice,
    stripePricesPerPeriod,
    yearlyQuote,
    activeQuote,
    loadingQuote,
    stripeQuoteError,
    stripeErrors,
    getStripePricesData
  };
});
