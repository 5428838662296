import dayjs from '@/dayjs';
import { combineDateTime, formatDate } from '@/helpers/dates';

import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import { useCompanyStore } from '@/stores/company';
import { useResourcesStore } from '@/stores/resources';

import createItem from './CreateCalendarItem';
import createDayHeaderContent from './CreateDayHeaderContent';
import createResourceLabel from './CreateResourceLabel';

export const calendarOptions = {
  eventContent: (data: any) => {
    // The content for any calendar event

    const { companySettings } = useCompanyStore();
    const { event } = data;

    const isRecurringAbsence =
      event.extendedProps.type === 'absence' && !!event.extendedProps.rrule;
    const isRecurringCopy =
      event.extendedProps.rrule && !isRecurringAbsence
        ? !dayjs(event.start).isSame(event.extendedProps.originalStartAt, 'day')
        : false;

    const slotInterval = companySettings.agenda?.slotInterval;

    const slotMinTime = dayjs
      .max(
        dayjs('2020-01-01T00:00'),
        dayjs(
          `2020-01-01T${companySettings.agenda?.startTime || '00:00'}`
        ).subtract(2, 'hour')
      )
      .format('HH:mm');
    const minEndTime = dayjs('2020-01-01T21:00');
    const maxEndTime = dayjs('2020-01-01T23:59');
    const minDate = dayjs.min(
      maxEndTime,
      dayjs(`2020-01-01T${companySettings.agenda?.endTime || '00:00'}`).add(
        3,
        'hour'
      )
    );
    const slotMaxTime = dayjs.max(minEndTime, minDate).format('HH:mm');

    // Needed for multi day absences, which need to get the start or end time of the calendar depending on if the start or end date of the event is different from the absence
    const startStr = data.isStart
      ? event.startStr
      : combineDateTime(dayjs().tz().format(), slotMinTime);
    const endStr = data.isEnd
      ? event.endStr
      : combineDateTime(dayjs().tz().format(), slotMaxTime);

    const eventContent = {
      id: event.id,
      allDay: event.allDay,
      title: event.title,
      extendedProps: event.extendedProps,
      startStr,
      endStr,
      darkerColors: companySettings.agenda?.darkerColors,
      isSmall: event.extendedProps.eventDuration <= slotInterval * 1.35,
      isMedium: event.extendedProps.eventDuration <= slotInterval * 2,
      features:
        isRecurringCopy || isRecurringAbsence
          ? {
              recurs: true
            }
          : event.extendedProps.features
    };

    return {
      html: createItem({ event: eventContent })
    };
  },
  moreLinkContent({ num }: { num: string }) {
    return { html: `+${num} more` };
  },
  nowIndicatorContent: () => ({
    html: `<div class="now-indicator-label">${formatDate(dayjs(), { format: 'time' })}</div>`
  }),
  dayHeaderContent: (data: any) => {
    // The content for a column header in week view, showing the day of the week
    const { viewType, resourceId } = useCalendarFiltersStore();
    const { resourceById } = useResourcesStore();
    const resource = resourceId ? resourceById(resourceId) : null;

    return {
      html: createDayHeaderContent({
        data,
        view: viewType,
        resource
      })
    };
  },
  resourceLabelContent: (data: any) => {
    // The content for a column header in day view, showing the resource

    const { resourceById } = useResourcesStore();
    const resource = resourceById(Number.parseInt(data.resource.id));
    if (resource) {
      return {
        html: createResourceLabel(resource)
      };
    }
  },
  slotLabelContent: ({ date }: { date: string }) =>
    formatDate(date, { format: 'time' })
};
