<template>
  <BaseModal
    :heading="heading"
    :loading="!prepaidCard"
    :customer="customer"
    :details="details"
    :label="prepaidCard ? prepaidCard.status.toLowerCase() : null"
    :parentRoute="{
      name: parentRouteName
    }"
  >
    <div v-if="prepaidCard">
      <BaseHeading :mb="0.5">
        {{ filters.capitalize($t('global.items.payment', 2)) }}
      </BaseHeading>
      <BaseTable
        v-if="prepaidCard.usedInOrders && prepaidCard.usedInOrders.length"
        :headers="[$t('global.number'), $t('global.date'), $t('global.used')]"
        :rows="
          prepaidCard.usedInOrders.map((p) => ({
            routerLink: { name: 'invoice', params: { id: p.id } },
            cells: [
              p.number,
              filters.date(p.invoicedAt),
              p?.transactions.filter(
                (transaction) => transaction.type === 'PREPAID_CARD'
              ).length
            ]
          }))
        "
        v-test="'prepaid-payments'"
      />
      <BaseText v-else v-test="'prepaid-payments-not-used'">
        {{ $t('prepaid_card.not_used') }}
      </BaseText>
      <BaseHeading :mt="2" :mb="0.5">
        {{ filters.capitalize($t('global.history')) }}
      </BaseHeading>
      <BaseTable
        v-if="prepaidCard.soldInOrder"
        :headers="[$t('global.number'), $t('global.date'), $t('global.state')]"
        :rows="[
          {
            routerLink: {
              name: 'invoice',
              params: { id: prepaidCard.soldInOrder.id }
            },
            cells: [
              prepaidCard.soldInOrder.number,
              filters.date(prepaidCard.soldInOrder.invoicedAt),
              {
                label: prepaidCard.soldInOrder.state.toLowerCase()
              }
            ]
          }
        ]"
        v-test="'prepaid-history'"
      />
      <BaseText v-else v-test="'prepaid-history-not-used'">
        {{ $t('prepaid_card.no_history') }}
      </BaseText>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import filters from '@/filters';
import gql from 'graphql-tag';
import { defineComponent } from 'vue';
import type { PrepaidCard, Customer } from '@/types';
import { useVatRatesStore } from '@/stores/vat-rates';
import { mapState } from 'pinia';

interface Detail {
  label: string;
  value: number | string;
}

export default defineComponent({
  props: {
    parentRouteName: {
      type: String,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      prepaidCard: null as PrepaidCard,
      payments: null,
      history: null
    };
  },
  computed: {
    ...mapState(useVatRatesStore, ['vatRates']),
    customer(): Customer {
      return this.prepaidCard?.customer;
    },
    historyRoute(): string {
      return this.$route.name === 'customer-prepaid-card'
        ? 'customer-invoice'
        : 'invoice';
    },
    heading(): string {
      return this.prepaidCard
        ? `${this.filters.capitalize(this.$t('global.items.prepaid_card', 1))}: ${this.prepaidCard.description}`
        : '';
    },
    details(): Detail[] | null {
      if (this.prepaidCard) {
        const details = [
          {
            label: this.$t('global.remaining'),
            value: `${this.prepaidCard.remaining} / ${this.prepaidCard.quantity}`
          },
          {
            label: this.$t('global.price'),
            value: this.prepaidCard.price,
            type: 'currency'
          },
          {
            label: this.$t('prepaid_card.price_per_unit'),
            value: this.prepaidCard.pricePerUse,
            type: 'currency'
          }
        ];

        const vatRate = this.vatRates.find(
          (rate) => rate.id === this.prepaidCard?.vatRate?.id
        );
        if (vatRate) {
          details.splice(3, 0, {
            label: this.$t('global.items.vat_rate', 1),
            value: vatRate.label
          });
        }
        return details;
      }
      return null;
    }
  },
  apollo: {
    prepaidCard(): any {
      return {
        query: gql`
          query getPrepaidCard($id: ID!) {
            prepaidCard(id: $id) {
              id
              price
              pricePerUse
              remaining
              description
              status
              quantity
              customer {
                email
                fullName
                id
                mobilePhone
                phone
                picture
              }
              vatRate {
                id
              }
              soldInOrder {
                id
                number
                invoicedAt
                state
              }
              usedInOrders {
                id
                number
                invoicedAt
                transactions {
                  type
                }
              }
            }
          }
        `,
        skip() {
          return !this.$route.params.id;
        },
        variables() {
          return {
            id: Number.parseInt(this.$route.params.id)
          };
        }
      };
    }
  }
});
</script>
