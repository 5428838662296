import { flash } from '@/helpers/ui';
import gql from 'graphql-tag';

export default {
  methods: {
    updateNote(notes, action) {
      const { id, itemAttributes, status } = this.stockOrder;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateStockOrder($input: UpdateStockOrderInput!) {
              updateStockOrder(input: $input) {
                stockOrder {
                  id
                }
              }
            }
          `,
          variables: {
            input: {
              id,
              itemAttributes,
              status,
              notes
            }
          },
          update(cache) {
            const cacheId = `StockOrder:${id}`;
            cache.evict({ id: cacheId, broadcast: false });
            cache.gc();
          }
        })
        .then(() => {
          let noteAction;
          switch (action) {
            case 'edit':
              noteAction = 'note_updated';
              break;
            case 'create':
              noteAction = 'note_created';
              break;
            default:
              noteAction = 'note_deleted';
          }
          flash(this.$t(`global.flash.${noteAction}`));
        });
    }
  }
};
