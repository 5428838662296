import config from '@/config';
import { handledException } from '@/helpers/datadog';
import { modal } from '@/helpers/ui';
import { useVersionMismatchStore } from '@/stores/version-mismatch';
import axios, { CancelToken, isCancel } from 'axios';

const delay = 0; // To simulate slow connections (to test spinners etc.), set a delay in ms.

const $axios = axios.create({
  baseURL: `${config.backendUrl}/api`,
  withCredentials: true,
  headers: {
    'Request-Origin': 'vue'
  }
});

$axios.CancelToken = CancelToken;
$axios.isCancel = isCancel;

$axios.interceptors.request.use((config) => {
  if (delay) {
    return new Promise((resolve) => setTimeout(() => resolve(config), delay));
  }
  return config;
});

$axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const response = error?.response;
    const status = response?.status;
    const errorMessage = response?.request?.responseText;

    const modalErrorCodes = [500, 401, 404];

    if (!config.isTest && modalErrorCodes.includes(status)) {
      modal('error', {
        data: {
          statusCode: status
        }
      });
    }

    if (!config.isTest && status === 422) {
      handledException(new Error('Validation error'), {
        errorMessage
      });
    }

    let parsedErrorMessage;
    try {
      parsedErrorMessage = JSON.parse(errorMessage);
      // eslint-disable-next-line no-empty
    } catch {}

    return Promise.reject({ status, errors: parsedErrorMessage });
  }
);

$axios.interceptors.response.use((config) => {
  const { checkVersionMismatch } = useVersionMismatchStore();
  checkVersionMismatch(config.headers.version || config.headers.Version);
  return config;
});

export default $axios;
