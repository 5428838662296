import config from '@/config';
import { logValidationError } from '@/helpers/datadog';
import { devLog } from '@/helpers/dev';
import { flash } from '@/helpers/ui';
import { useUserStore } from '@/stores/user';
import type {
  Error as SalonizedError,
  SubscriptionPeriod,
  SubscriptionPlan
} from '@/types';
import { Status } from '@/types';
import { useMutation } from '@vue/apollo-composable';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import {
  CHANGE_STRIPE_SUBSCRIPTION_MUTATION,
  CREATE_STRIPE_CHECKOUT_SESSION_MUTATION
} from './graphql';
import { useStripeStore } from './store';

const stripeSubscriptionUpgradeErrorTypes: SalonizedError['type'][] = [
  'subscription_change_failed',
  'subscription_no_found',
  'upgrade_not_permitted'
];
const unknownSubscriptionUpgradeError = {
  type: 'unknown_subscription_upgrade_error',
  message: 'Unknown subscription upgrade error'
};

export const useStripeSubscription = () => {
  const { stripeKey } = config;
  const router = useRouter();
  const { t } = useI18n();
  const isLoading = ref(false);
  const stripeSubscriptionErrors = ref<SalonizedError[]>([]);
  const { stripeErrors } = storeToRefs(useStripeStore());

  const createStripeSubscription = (
    plan: SubscriptionPlan,
    period: SubscriptionPeriod,
    hasTreatwellIntention = false
  ) => {
    stripeSubscriptionErrors.value = [];
    if (!stripeKey) {
      devLog('Stripe key is missing');
      return;
    }

    const variables = {
      input: {
        plan,
        period,
        hasTreatwellIntention
      }
    };

    const { mutate } = useMutation(
      CREATE_STRIPE_CHECKOUT_SESSION_MUTATION,
      () => ({
        variables
      })
    );

    isLoading.value = true;
    return mutate()
      .then(
        ({
          data: {
            createStripeCheckoutSession: {
              url,
              errors: createStripeCheckoutSessionErrors
            }
          }
        }) => {
          if (url) {
            window.location.href = url;
            return;
          }

          if (createStripeCheckoutSessionErrors?.length > 0) {
            logValidationError(
              'createStripeCheckoutSession',
              createStripeCheckoutSessionErrors,
              variables
            );
          }

          if (
            createStripeCheckoutSessionErrors?.find(
              (error: { type: string }) =>
                error.type === 'company_already_subscribed'
            )
          ) {
            const { getUser } = useUserStore();
            getUser().then(() => {
              router.push({
                name: 'admin-billing',
                query: { info: 'subscribed-user' }
              });
            });
            return;
          }

          devLog('Stripe url missing for createStripeCheckoutSession');
        }
      )
      .finally(() => {
        isLoading.value = false;
      });
  };

  const upgradeStripeSubscription = (
    plan: SubscriptionPlan,
    period: SubscriptionPeriod
  ) => {
    const variables = {
      input: {
        plan,
        period
      }
    };

    const { mutate } = useMutation(CHANGE_STRIPE_SUBSCRIPTION_MUTATION, () => ({
      variables
    }));
    isLoading.value = true;

    return mutate()
      .then((response) => {
        stripeSubscriptionErrors.value = [];
        if (response === null) {
          stripeSubscriptionErrors.value = [unknownSubscriptionUpgradeError];
          return;
        }

        const { data, errors } = response;

        if (errors?.length && errors.length > 0) {
          logValidationError('changeStripeSubscription', errors, variables);
          return;
        }

        const { changeStripeSubscription } = data || {};

        if (changeStripeSubscription.errors) {
          stripeSubscriptionErrors.value = changeStripeSubscription.errors.map(
            (error: SalonizedError) =>
              stripeSubscriptionUpgradeErrorTypes.includes(error.type)
                ? error
                : unknownSubscriptionUpgradeError
          );
        }

        if (changeStripeSubscription.status === Status.Changed) {
          const { getUser } = useUserStore();

          // TODO: This is a workaround for the subscription status not being updated in the backend
          setTimeout(() => {
            isLoading.value = false;
            getUser().finally(() => {
              flash(t('global.flash.subscription_updated'));
              router.push({ name: 'dashboard' });
            });
          }, 2000);
        } else {
          devLog('Stripe subscription status is not changed');
        }
      })
      .catch(() => {
        isLoading.value = false;
      })
      .finally(() => {
        stripeErrors.value = stripeSubscriptionErrors.value;
      });
  };

  return {
    isLoading,
    createStripeSubscription,
    upgradeStripeSubscription
  };
};
