<template>
  <BaseModalLarge
    :routes="[]"
    :details="details"
    :heading="heading"
    :label="discountCode ? discountCode.state.toLowerCase() : ''"
    :actions="[
      {
        name: 'edit',
        icon: 'edit',
        label: $t('global.actions.edit_discount')
      },
      {
        name: 'delete',
        icon: 'delete',
        label: $t('global.actions.delete_discount')
      }
    ]"
    parentRoute="marketing-discounts"
    @action="onActionClick"
  >
    <CreateDiscountCode
      v-if="showCreateModal"
      @close="showCreateModal = false"
    />
    <DeleteDiscountCodeConfirmation
      v-if="showDeleteModal"
      @close="showDeleteModal = false"
    />
    <div :class="[$style.inner, { [$style.isSmall]: $screen === 's' }]">
      <div :class="$style.minHeight">
        <BaseCard>
          <DiscountDetailsList
            v-if="hasDiscountCodeUsage"
            :items="discountCodeUsage"
          />
          <Pagination
            :items="discountCodeUsage"
            :loading="$apollo.loading"
            fieldName="discountCodeUsage"
            :apolloQuery="$apollo.queries.discountCodeUsage"
            :showNoResults="false"
          />
          <CodeNotUsed v-if="!$apollo.loading && !hasDiscountCodeUsage" />
        </BaseCard>
      </div>
    </div>
  </BaseModalLarge>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import DiscountDetailsList from './DiscountDetailsList.vue';
import CodeNotUsed from './CodeNotUsed.vue';
import Pagination from '@/components/_deprecated/Pagination.vue';
import CreateDiscountCode from '../modals/CreateDiscountCode.vue';
import DeleteDiscountCodeConfirmation from '../modals/DeleteDiscountCodeConfirmation.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    DiscountDetailsList,
    CodeNotUsed,
    Pagination,
    CreateDiscountCode,
    DeleteDiscountCodeConfirmation
  },
  data() {
    return {
      discountCodeUsage: [],
      showCreateModal: false,
      showDeleteModal: false
    };
  },
  computed: {
    heading() {
      return this.discountCode ? this.discountCode.code : '';
    },
    hasDiscountCodeUsage() {
      return this.discountCodeUsage?.length;
    },
    oncePerCustomer() {
      return this.discountCode.oncePerCustomer
        ? this.$t('global.yes')
        : this.$t('global.no');
    },
    details() {
      const details = [];
      if (this.discountCode) {
        const limit = {
          label: this.$t('marketing.discounts.details.stop_when_used'),
          value: this.$t('marketing.discounts.details.amount_of_times', {
            amount: this.discountCode.usageLimit
          })
        };

        details.push(
          {
            label: this.$t('global.category'),
            value: this.$t(
              `marketing.discounts.category.${this.discountCode.category.toLowerCase()}`
            )
          },
          {
            label: this.$t('global.percentage'),
            value: `${this.discountCode.discountPercentage}%`
          },
          {
            label: this.$t('global.expiration'),
            value: this.$t(
              `marketing.discounts.discount-code-modal.expiration.${this.discountCode.expiresIn.toLowerCase()}`
            )
          },
          {
            label: this.$t('marketing.discounts.details.once_per_customer'),
            value: this.oncePerCustomer
          }
        );

        if (this.discountCode.hasLimit) {
          details.push(limit);
        }
      }
      return details;
    }
  },
  methods: {
    onActionClick(action) {
      if (action === 'edit') {
        this.showCreateModal = true;
      } else if (action === 'delete') {
        this.showDeleteModal = true;
      }
    }
  },
  apollo: {
    discountCode: {
      query: gql`
        query getDiscountCode($id: Int!) {
          discountCode(id: $id) {
            id
            code
            category
            state
            discountPercentage
            hasLimit
            usageLimit
            oncePerCustomer
            expiresIn
          }
        }
      `,
      skip() {
        return !this.$route.params.discountId;
      },
      variables() {
        return {
          id: Number.parseInt(this.$route.params.discountId)
        };
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    },
    discountCodeUsage: {
      query: gql`
        query getDiscountCodeUsage(
          $id: Int!
          $pagination: PaginationAttributes
        ) {
          discountCodeUsage(id: $id, pagination: $pagination) {
            usedAt
            appointmentDate
            customer {
              fullName
            }
          }
        }
      `,
      skip() {
        return !this.$route.params.discountId;
      },
      variables() {
        return {
          id: Number.parseInt(this.$route.params.discountId),
          pagination: {
            currentPage: 1,
            perPage: 25
          }
        };
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  }
});
</script>

<style lang="scss" module>
.inner {
  position: relative;
  min-height: 200px;
  height: 100%;
}

.minHeight {
  position: relative;
  min-height: 200px;
  height: 100%;
}
</style>
