import eventBus from '@/event-bus';
import { usePageLayoutStore } from '@/stores/page-layout';
import { useSessionStorage } from '@vueuse/core';
import { nextTick, onActivated, onMounted, onUnmounted } from 'vue';
import type { Ref } from 'vue';

type scrollPosition = number | 'top' | 'bottom';
interface scrollParams {
  position?: scrollPosition;
  element?: HTMLElement;
  instant?: boolean;
}

export const scrollPage = (params: scrollParams) => {
  eventBus.$emit('scroll', params);
};

interface ScrollOptions {
  disabled?: Ref<boolean>;
  instant?: boolean;
}

export const usePageScroll = (
  ref?: Ref<HTMLElement>,
  options: ScrollOptions = {}
) => {
  onMounted(() => {
    eventBus.$on('scroll', scroll);
  });

  onUnmounted(() => {
    eventBus.$off('scroll', scroll);
  });

  const scroll = ({ position, element, instant }: scrollParams) => {
    if (options.disabled?.value) {
      return;
    }

    if (element) {
      scrollToElement({ element, scrollContainer: ref?.value });
    } else if (ref?.value) {
      let top;

      if (position === 'top') {
        top = 0;
      } else if (position === 'bottom') {
        top = ref.value.scrollHeight - ref.value.clientHeight;
      } else if (typeof position === 'number') {
        top = position;
      } else {
        return;
      }

      ref.value.scrollTo({
        top,
        behavior: options.instant || instant ? 'auto' : 'smooth'
      });
    }
  };
};

export const scrollToElement = ({
  element,
  scrollContainer
}: {
  element: HTMLElement;
  scrollContainer?: HTMLElement;
}) => {
  if (!element) {
    return;
  }

  const paddingTop = 16; // Leave some extra space at the top of the page
  const elementTop = element.getBoundingClientRect().top;

  if (!scrollContainer) {
    const { headerHeight } = usePageLayoutStore();

    window.scrollTo({
      top: elementTop + window.scrollY - headerHeight - paddingTop,
      behavior: 'smooth'
    });
    return;
  }

  const containerTop = scrollContainer.getBoundingClientRect().top;
  const containerScroll = scrollContainer.scrollTop;

  scrollContainer.scrollTo({
    top: elementTop - containerTop + containerScroll - paddingTop,
    behavior: 'smooth'
  });
};

export const setScrollOnElement = (element: HTMLElement) => {
  // When using an accordion style layout, collapsing an item might trigger an unwanted scroll position, based on the scroll before collapsing the item
  // This will change the scroll position of the container, so that the item that's being interacted with stays on the same place
  // We use nextTick to ensure the DOM is updated first

  const { headerHeight } = usePageLayoutStore();

  nextTick(() => {
    const documentOffset = Math.round(element.getBoundingClientRect().top);
    const containerOffset = element.offsetTop;

    if (documentOffset < headerHeight) {
      scrollPage({
        position: containerOffset
      });
    }
  });
};

export const getScrollPosition = (): number => {
  // Get the current scroll position of the app scroll container
  const scrollContainer = document.getElementById('app-scrollcontainer');
  return scrollContainer?.scrollTop || 0;
};

export const useScrollCaching = () => {
  // This will save the scroll position when clicking on an element, and then set it when the component gets activated again
  // Only works when using KeepAlive

  const scrollPosition = useSessionStorage(
    'customersOverviewScrollPosition',
    0
  );

  const setScrollPosition = () => {
    scrollPosition.value = getScrollPosition();
  };

  onActivated(() => {
    if (scrollPosition.value) {
      scrollPage({
        position: scrollPosition.value,
        instant: true
      });
    }
  });

  onUnmounted(() => {
    scrollPosition.value = 0;
  });

  return {
    setScrollPosition
  };
};
