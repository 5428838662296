<template>
  <BaseModal
    :heading="
      $route.params.id
        ? $t('global.actions.edit_category')
        : $t('global.actions.create_category')
    "
    small
    parentRoute="products-categories"
  >
    <BaseForm @submit="submit">
      <BaseInput
        v-model="name"
        :label="$t('global.name')"
        required
        focus
        mb
        v-test="'createCategoryName'"
      />
      <BaseHeading
        size="s"
        :mb="0.5"
        :info="$t('product_categories.color_input_alert')"
      >
        {{ $t('global.color') }}
      </BaseHeading>
      <BaseColorLabel v-model="color" :mr="0.5" colorPicker isSelected />
    </BaseForm>
    <template #footer>
      <BaseButton
        :loading="isLoading"
        @click="submit"
        v-test="'createCategorySave'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import gql from 'graphql-tag';
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      name: '',
      color: '#f7d265',
      isLoading: false
    };
  },
  apollo: {
    productCategory: {
      query: gql`
        query getProductCategory($id: Int!) {
          productCategory(id: $id) {
            id
            name
            color
          }
        }
      `,
      skip() {
        return !this.$route.params.id;
      },
      variables() {
        return {
          id: Number.parseInt(this.$route.params.id)
        };
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      result() {
        this.name = this.productCategory.name;
        this.color = this.productCategory.color;
      }
    }
  },
  methods: {
    onColorSelect(color) {
      return (this.color = color);
    },
    callback() {
      this.$router.push({
        name: 'products-categories'
      });
      flash(
        this.$t(
          `global.flash.${this.$route.params.id ? 'category_updated' : 'category_created'}`
        )
      );
    },
    submit() {
      this.isLoading = true;

      const input = {
        name: this.name,
        color: this.color
      };

      if (this.$route.params.id) {
        input.id = Number.parseInt(this.$route.params.id);
      }

      if (this.$route.params.id) {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateProductCategory(
                $input: UpdateProductCategoryInput!
              ) {
                updateProductCategory(input: $input) {
                  productCategory {
                    id
                    name
                    color
                  }
                }
              }
            `,
            variables: {
              input
            }
          })
          .then(() => {
            this.callback();
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createProductCategory(
                $input: CreateProductCategoryInput!
              ) {
                createProductCategory(input: $input) {
                  errors
                }
              }
            `,
            variables: {
              input
            },
            update(cache) {
              cache.evict({ id: 'ROOT_QUERY', fieldName: 'productCategories' });
              cache.gc();
            }
          })
          .then(() => {
            this.callback();
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    }
  }
});
</script>
